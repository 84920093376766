import { useTranslation } from 'react-i18next';
import { ModalReject } from '../../../../../common';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useTreatmentSuggestedChanges } from '../../../../../hooks/orthodontics/useTreatmentSuggestedChanges';
import { useStepsManager } from '../../../../../hooks';

export function ModalClientValidationsCancelChanges() {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const { cancelTreatmentChanges } = useTreatmentSuggestedChanges();
  const { goToStep } = useStepsManager();

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const onContinueButtonClick = () => {
    cancelTreatmentChanges();
    toggleModalIsOpened('');
    goToStep(0, false);
  };

  return (
    <ModalReject
      title={t('modal.clientValidation.cancel.title')}
      message={t('modal.clientValidation.cancel.description')}
      onClickClose={onCloseButtonClick}
      onClickContinue={onContinueButtonClick}
      yesText={t('common.yes')}
      noText={t('common.no')}
    />
  );
}
