import { useCallback, useEffect } from 'react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useDentalMovementsStepsManager } from '../useDentalMovementsStepsManager';

export function useEvolutionPanelNavigator() {
  const currentSliceRange = useOrthBoundStore((state) => state.currentSliceRange);
  const setCurrentSliceRange = useOrthBoundStore((state) => state.setCurrentSliceRange);
  const setCurrentSliceSteps = useOrthBoundStore((state) => state.setCurrentSliceSteps);
  const fitColumnsInPanel = useOrthBoundStore((state) => state.fitColumnsInPanel);
  const activeStep = useCommonBoundStore((state) => state.activeStep);
  const setActiveStep = useCommonBoundStore((state) => state.setActiveStep);
  const setSelectedStepIndexes = useCommonBoundStore((state) => state.setSelectedStepIndexes);
  const { dentalMovementsSteps } = useDentalMovementsStepsManager();

  const markAsCurrentStep = useCallback(
    (stepIndex: number) => {
      setActiveStep(stepIndex);
      setSelectedStepIndexes([stepIndex]);
    },
    [setActiveStep, setSelectedStepIndexes]
  );

  const isFirstStep = useCallback(() => {
    return activeStep === 0;
  }, [activeStep]);

  const isLastStep = useCallback(() => {
    return activeStep === dentalMovementsSteps.length - 1;
  }, [activeStep]);

  const isFirstSlice = useCallback(() => currentSliceRange?.from === 0, [currentSliceRange]);

  const isLastSlice = useCallback(() => {
    if (!currentSliceRange) {
      return true;
    }

    return currentSliceRange.to >= dentalMovementsSteps.length;
  }, [currentSliceRange, dentalMovementsSteps]);

  const goToNextStep = useCallback(() => {
    if (isLastStep()) {
      return;
    }

    if (!isLastSlice()) {
      const newCurrentSliceStep = {
        from: currentSliceRange?.from + 1,
        to: currentSliceRange?.to + 1
      };

      setCurrentSliceRange(newCurrentSliceStep);
      setCurrentSliceSteps(dentalMovementsSteps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
    }

    markAsCurrentStep(activeStep + 1);
  }, [
    currentSliceRange,
    isLastSlice,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    dentalMovementsSteps,
    activeStep,
    isLastStep
  ]);

  const goToPreviousStep = useCallback(() => {
    if (isFirstStep()) {
      return;
    }

    if (currentSliceRange && currentSliceRange.from > 0) {
      const newCurrentSliceStep = {
        from: currentSliceRange?.from - 1,
        to: currentSliceRange?.to - 1
      };
      setCurrentSliceRange(newCurrentSliceStep);
      setCurrentSliceSteps(dentalMovementsSteps.slice(newCurrentSliceStep.from, newCurrentSliceStep.to));
    }

    markAsCurrentStep(activeStep - 1);
  }, [
    currentSliceRange,
    isFirstSlice,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    dentalMovementsSteps,
    activeStep,
    isFirstStep
  ]);

  const goToFirstStep = useCallback(() => {
    markAsCurrentStep(0);
  }, [
    fitColumnsInPanel,
    setCurrentSliceRange,
    setCurrentSliceSteps,
    markAsCurrentStep,
    dentalMovementsSteps,
    isFirstSlice
  ]);

  const goToLastStep = useCallback(() => {
    markAsCurrentStep(dentalMovementsSteps.length - 1);
  }, [fitColumnsInPanel, setCurrentSliceRange, setCurrentSliceSteps, setActiveStep, dentalMovementsSteps, isLastSlice]);

  const handleChangeInActiveStep = useCallback(() => {
    const halfFitRows = Math.floor(fitColumnsInPanel / 2);

    let from = Math.max(0, activeStep - halfFitRows);
    let to = from + fitColumnsInPanel;

    if (to > dentalMovementsSteps.length) {
      to = dentalMovementsSteps.length;
      from = Math.max(0, to - fitColumnsInPanel);
    }

    setCurrentSliceRange({ from, to });
    setCurrentSliceSteps(dentalMovementsSteps.slice(from, to));
  }, [dentalMovementsSteps, fitColumnsInPanel, activeStep]);

  useEffect(() => {
    if (!dentalMovementsSteps || dentalMovementsSteps.length === 0) {
      return;
    }
    handleChangeInActiveStep();
  }, [activeStep, dentalMovementsSteps]);

  return {
    activeStep,
    isFirstStep,
    isLastStep,
    goToPreviousStep,
    goToNextStep,
    goToFirstStep,
    goToLastStep,
    handleChangeInActiveStep
  };
}
