import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as EvolutionIcon } from '../../../assets/icons/evolution.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand.svg';
import { ReactComponent as CollapseIcon } from '../../../assets/icons/collapse.svg';

import { useTranslation } from 'react-i18next';
import { AttachmentState } from '../../../common';
import { ApplyIprState, IprLabel } from '../../../common/evergine';
import { Shift } from '../dentalMovements';
import { EvolutionPanelInfo } from './EvolutionPanelInfo';
import './evolutionPanel.scss';
import EvolutionTable from './evolutionTable/EvolutionTable';
import { useOrthBoundStore } from '../../stores/useStore';
import EvolutionPanelNavigator from './EvolutionPanelNavigator';
import { useIntermediateSteps } from '../../../hooks';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { AbilityAction, AbilityContext, DentalMovementDTO, OrthoAbilitySubject } from '../../../shared';
import { Ability } from '@casl/ability';

interface EvolutionPanelProps {
  opened: boolean;
  shift: Shift;
  steps: EvolutionStep[];
  applyIPRList: IprLabel[];
  updateIPRList: (list: IprLabel[]) => void;
  onClickClose: () => void;
  getWidthFunction: (getWidth: () => number) => void;
}

export type EvolutionTooth = {
  fdi: number;
  isExtracted: boolean;
  isMoved: boolean;
  isPassiveAligner: boolean;
};

export type EvolutionInterdentalDistance = {
  leftToothFdi: number;
  rightToothFdi: number;
  distance: number;
  applyIprState: ApplyIprState;
  isSpaceVisible: boolean;
};

export type EvolutionAttachment = {
  toothFdi: number;
  state: AttachmentState;
};

export type EvolutionStep = {
  index: number;
  teeth: EvolutionTooth[];
  attachments: EvolutionAttachment[];
  interdentalDistances: EvolutionInterdentalDistance[];
  relativeDentalMovement: DentalMovementDTO[];
};

export function EvolutionPanel({
  opened,
  shift,
  steps,
  applyIPRList,
  onClickClose,
  getWidthFunction,
  updateIPRList
}: EvolutionPanelProps) {
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);
  const evolutionPanelRef = useRef(null);
  const expandEvolutionPanel = useOrthBoundStore((state) => state.expandEvolutionPanel);
  const setExpandEvolutionPanel = useOrthBoundStore((state) => state.setExpandEvolutionPanel);
  const isEvolutionPanelSliced = useOrthBoundStore((state) => state.isEvolutionPanelSliced);
  const fitColumnsInPanel = useOrthBoundStore((state) => state.fitColumnsInPanel);
  const selectedStepIndexes = useCommonBoundStore((state) => state.selectedStepsIndexes);
  const [panelWidth, setPanelWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (evolutionPanelRef.current) {
      const width = evolutionPanelRef.current.getBoundingClientRect().width;
      setPanelWidth(width);
    }
  }, [steps, expandEvolutionPanel, isEvolutionPanelSliced]);

  const { addStepAction, removeStepsAction } = useIntermediateSteps(true);

  useEffect(() => {
    if (isEvolutionPanelSliced) {
      const observer = new ResizeObserver(updateWidth);
      if (evolutionPanelRef.current) {
        observer.observe(evolutionPanelRef.current);
      }

      return () => {
        if (evolutionPanelRef.current) {
          observer.unobserve(evolutionPanelRef.current);
        }
      };
    }
  }, [isEvolutionPanelSliced, updateWidth]);

  useEffect(() => {
    if (evolutionPanelRef.current) {
      getWidthFunction(() => panelWidth);
    }
  }, [getWidthFunction, panelWidth, evolutionPanelRef?.current]);

  useEffect(() => {
    updateWidth();
  }, [opened]);

  const classes = classNames('evolutionpanel', {
    opened,
    ['shift-1']: shift === Shift.InfoPanelOpened
  });

  useEffect(() => {
    if (!evolutionPanelRef?.current) {
      return;
    }

    getWidthFunction(() => panelWidth);
  }, [evolutionPanelRef?.current]);

  const handleExpandToggle = () => {
    setExpandEvolutionPanel(!expandEvolutionPanel);
  };

  const removeStep = () => {
    if (selectedStepIndexes.length === 0) return;
    removeStepsAction();
  };

  const addStep = () => {
    if (selectedStepIndexes.length === 0) return;
    addStepAction();
  };

  const showAddStepButtonIfAllowed = () => {
    if (!ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.AddStepsManually)) {
      return null;
    }
    return (
      <div
        className={`evolutionpanel-navigator-add ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
        onClick={addStep}
      >
        {t('evolutionPanel.addStep')}
      </div>
    );
  };

  const showRemoveStepButtonIfAllowed = () => {
    if (!ability || ability.cannot(AbilityAction.Manage, OrthoAbilitySubject.RemoveStepsManually)) {
      return null;
    }
    return (
      <CloseIcon
        className={`clickable ${selectedStepIndexes.length === 0 ? 'disabled' : 'enabled'}`}
        onClick={removeStep}
      />
    );
  };

  const areFitStepsInPanel = useMemo(() => fitColumnsInPanel > steps.length, [steps, fitColumnsInPanel]);

  return (
    <div className={`${classes} no-select`} ref={evolutionPanelRef}>
      <div className={`evolutionpanel-container ${expandEvolutionPanel ? 'expanded' : ''}`}>
        <div className="evolutionpanel-header">
          <div className="evolutionpanel-tools">
            <div className="evolutionpanel-top">
              <div>
                <EvolutionIcon /> <span className="evolutionpanel-top-title">{t('evolutionPanel.title')}</span>
              </div>
              <div>
                <EvolutionPanelInfo />
                {(!areFitStepsInPanel || expandEvolutionPanel) && (
                  <div className="clickable" onClick={handleExpandToggle}>
                    {expandEvolutionPanel ? <CollapseIcon /> : <ExpandIcon />}
                  </div>
                )}
                <CloseIcon className="clickable" onClick={onClickClose} />
              </div>
            </div>
            <div className="evolutionpanel-paginator">
              <div className="evolutionpanel-navigator">
                {!areFitStepsInPanel && <EvolutionPanelNavigator lastStepIndex={steps[steps.length - 1].index} />}
              </div>
              <div className="evolutionpanel-buttons">
                {showRemoveStepButtonIfAllowed()}
                {showAddStepButtonIfAllowed()}
              </div>
            </div>
          </div>
        </div>
        <EvolutionTable steps={steps} updateIPRList={updateIPRList} applyIPRList={applyIPRList} opened={opened} />
      </div>
    </div>
  );
}
