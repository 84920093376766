import { injectable } from 'inversify';
import { INJECTED_TYPES, container } from '../../common';
import { IHttpService } from '../http';
import { CasePhase, CasePhaseDto, CaseStatus } from './caseStatus';
import { ICaseStatusService } from './icaseStatus.service';
import { isSaasApiEnabled } from '../settings';

@injectable()
export class CaseStatusService implements ICaseStatusService {
  private readonly apiEndpoint = '/api/product-cases';
  private readonly saasApiEndpoint = '/saas-api/tools/treatments';
  private _httpService = container.get<IHttpService>(INJECTED_TYPES.IHttpService);
  private buildBaseURL = (caseId: string, versionId: string) =>
    `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}/phases`;
  private saasBuildBaseURL = (caseId: string, versionId: string) =>
    `${this.saasApiEndpoint}/${caseId}/versions/${versionId}/phases`;

  public async getCaseStatusPhases(caseId: string, versionId: string): Promise<CaseStatus> {
    return !isSaasApiEnabled()
      ? await this.getCaseStatusPhasesLegacy(caseId, versionId)
      : await this.getCaseStatusPhasesSaaS(caseId, versionId);
  }

  private async getCaseStatusPhasesLegacy(caseId: string, versionId: string): Promise<CaseStatus> {
    const casePhases: CasePhaseDto = (await this._httpService.get<CasePhaseDto>(this.buildBaseURL(caseId, versionId)))
      .data;

    const caseStatus: CaseStatus = {
      id: '',
      phases: casePhases.data
    };

    return caseStatus as CaseStatus;
  }

  private async getCaseStatusPhasesSaaS(caseId: string, versionId: string): Promise<CaseStatus> {
    const casePhases = (await this._httpService.get<CasePhase[]>(this.saasBuildBaseURL(caseId, versionId))).data;

    const caseStatus: CaseStatus = {
      id: '',
      phases: casePhases
    };

    return caseStatus as CaseStatus;
  }

  public async editCaseStatusPhase(caseId: string, versionId: string, casePhase: CasePhase): Promise<void> {
    return !isSaasApiEnabled()
      ? await this.editCaseStatusPhaseLegacy(caseId, versionId, casePhase)
      : await this.editCaseStatusPhaseSaaS(caseId, versionId, casePhase);
  }

  private async editCaseStatusPhaseLegacy(caseId: string, versionId: string, casePhase: CasePhase): Promise<void> {
    await this._httpService.patch<boolean>(`${this.buildBaseURL(caseId, versionId)}/${casePhase.name}`, casePhase);
  }

  private async editCaseStatusPhaseSaaS(caseId: string, versionId: string, casePhase: CasePhase): Promise<void> {
    await this._httpService.patch<boolean>(`${this.saasBuildBaseURL(caseId, versionId)}/${casePhase.name}`, casePhase);
  }
}
