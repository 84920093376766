import { useEffect } from 'react';
import { useCaseData, useCaseId, useCaseStatus } from '../../../hooks';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../stores/useStore';
import { useTranslation } from 'react-i18next';

export function DesignerEntryPoint() {
  const { t } = useTranslation();
  const setProccessUrlByEntryPoint = useOrthBoundStore((state) => state.setProccessUrlByEntryPoint);
  const [caseId] = useCaseId();
  const { fetchCaseStatus } = useCaseStatus(caseId);
  const { fetchPatientCaseVersions } = useCaseData(caseId);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);

  useEffect(() => {
    setWebBusy(true);
    setWebBusyMessage(t('common.loaderActions.getTreatment'));
    const fetchAndNavigate = async () => {
      setProccessUrlByEntryPoint(true);
      await fetchPatientCaseVersions();
      await fetchCaseStatus();
      setWebBusyMessage(t('common.loaderActions.loadingPhase'));
    };

    fetchAndNavigate();
  }, []);

  return <></>;
}
