import { CSSProperties } from 'react';
import './occlusogramLegend.scss';

type Legend = { color: string; label: string };

type OcclusogramLegendProps = {
  comparerActivated?: boolean;
  isVerticalComparer?: boolean;
};

export function OcclusogramLegend({ comparerActivated, isVerticalComparer }: OcclusogramLegendProps) {
  const legend: Legend[] = [
    { color: '#E6170D', label: '-0.2' },
    { color: '#E58D0F', label: '0.2' },
    { color: '#F3C915', label: '0.4' },
    { color: '#B2ED21', label: '0.6' },
    { color: '#1DE5C8', label: '0.8' },
    { color: '#227DE2', label: '1' },
    { color: '#1B14F8', label: '1.2' }
  ];

  return (
    <div
      className={`occlusogram-legend ${
        isVerticalComparer ? 'is-vertical-comparing' : comparerActivated ? 'is-comparing' : ''
      }`}
    >
      {legend.map((e, i) => {
        const id = `occlusogram-legend-${i}`;
        const styles = { backgroundColor: e.color } as CSSProperties;

        return (
          <div className="occlusogram-legend__container" key={id}>
            <div style={styles} className="occlusogram-legend__bar"></div>
            <div className="occlusogram-legend__separator" />
            <span className="occlusogram-legend__label">{e.label}</span>
          </div>
        );
      })}
    </div>
  );
}
