import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useOrthBoundStore } from '../../stores/useStore';
import { PointerPosition } from '../../stores/slices/orthContextMenuSlice';
import { EmptySceneContextMenu } from './EmptySceneContextMenu';
import { TeethContextMenu, useTeethContextMenu } from './teethContextMenu';
import { AttachesContextMenu, useAttachesContextMenu } from './attachesContextMenu';
import { PublishContextMenu } from './publishContextMenu';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { PROFILES_TYPE } from '../../../shared';

export enum PagesWithSpecificOptions {
  Treatment,
  Publish
}

type OrthContextualMenuProps = {
  preventContextMenu?: boolean;
  pageWithSpecificOptions?: PagesWithSpecificOptions;
};

export function OrthContextualMenu({ preventContextMenu = false, pageWithSpecificOptions }: OrthContextualMenuProps) {
  const { isOrthContextMenuVisible, pointerPosition, hoveredAttach, setIsOrthContextMenuVisible } = useOrthBoundStore(
    (state) => ({
      isOrthContextMenuVisible: state.isOrthContextMenuVisible,
      pointerPosition: state.pointerPosition,
      hoveredAttach: state.hoveredAttach,
      setIsOrthContextMenuVisible: state.setIsOrthContextMenuVisible
    })
  );
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [selectedTeethPosition, setSelectedTeethPosition] = useState<PointerPosition>();

  const closeContextMenu = useCallback(() => {
    setIsOrthContextMenuVisible(false);
    setShowContextMenu(false);
  }, []);

  const { canTeethContextMenuBeShown } = useTeethContextMenu(closeContextMenu, selectedTeethPosition);
  const { canAttachesMenuBeShown, hideAttachesMenu } = useAttachesContextMenu();

  useEffect(() => {
    if (preventContextMenu === true) {
      setShowContextMenu(false);
    }
  }, [preventContextMenu]);

  useEffect(() => {
    if (selectedTeethPosition !== pointerPosition) {
      setSelectedTeethPosition(pointerPosition);
    }
    setShowContextMenu(isOrthContextMenuVisible);
  }, [isOrthContextMenuVisible, pointerPosition]);

  const isTeethContextMenuShowable = useCallback(() => {
    return (
      canTeethContextMenuBeShown &&
      pageWithSpecificOptions === PagesWithSpecificOptions.Treatment &&
      hoveredAttach === null
    );
  }, [canTeethContextMenuBeShown, pageWithSpecificOptions, hoveredAttach]);

  const isAttacheContextMenuShowable = useCallback(() => {
    return canAttachesMenuBeShown && pageWithSpecificOptions === PagesWithSpecificOptions.Treatment;
  }, [canAttachesMenuBeShown, pageWithSpecificOptions]);

  const isPublishContextMenuShowable = useCallback(() => {
    const isClient = useBoundStore.getState().userProfile?.type === PROFILES_TYPE.client;
    return !isClient && pageWithSpecificOptions === PagesWithSpecificOptions.Publish;
  }, [pageWithSpecificOptions]);

  const positionData: CSSProperties = useMemo(() => {
    return { left: selectedTeethPosition?.clientX, top: selectedTeethPosition?.clientY };
  }, [selectedTeethPosition]);

  if (isTeethContextMenuShowable()) {
    return (
      <TeethContextMenu
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={showContextMenu}
        teethPosition={selectedTeethPosition}
      />
    );
  }

  if (isAttacheContextMenuShowable()) {
    return (
      <AttachesContextMenu
        onClickOutside={hideAttachesMenu}
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={showContextMenu}
      />
    );
  }

  if (isPublishContextMenuShowable()) {
    return (
      <PublishContextMenu
        onClickOutside={closeContextMenu}
        onToggleAction={closeContextMenu}
        positionData={positionData}
        show={showContextMenu}
      />
    );
  }

  return <EmptySceneContextMenu onToggleAction={closeContextMenu} positionData={positionData} show={showContextMenu} />;
}
