import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { DentalMovementDTO, MovementsTableDto } from '../../shared';

export function useMovementTable() {
  const { movementsTable, setMovementsTable } = useOrthBoundStore((state) => ({
    movementsTable: state.movementsTable,
    setMovementsTable: state.setMovementsTable
  }));

  const getMovementsTable = async (): Promise<MovementsTableDto> => {
    const response = await window.App.webEventsProxy.movements.getMovementsTable();
    setMovementsTable(response);

    return response;
  };

  const getRelativeDentalMovementsByStep = (stepIndex: number, movements: MovementsTableDto): DentalMovementDTO[] => {
    return movements?.movementsRelative[stepIndex] || [];
  };

  const isOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    if (!relativeDentalMovement) return false;

    if (
      isExtrussionOverMovement(relativeDentalMovement) ||
      isTranslationOverMovement(relativeDentalMovement) ||
      isRotationOverMovement(relativeDentalMovement) ||
      isTorqueOverMovement(relativeDentalMovement) ||
      isTIPOverMovement(relativeDentalMovement)
    ) {
      return true;
    }

    return false;
  };

  const isExtrussionOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    return relativeDentalMovement.extrusionIntrusion >= 0.12 || relativeDentalMovement.extrusionIntrusion <= -0.12;
  };

  const isTranslationOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    return (
      relativeDentalMovement.translationVL > 0.25 ||
      relativeDentalMovement.translationVL < -0.25 ||
      relativeDentalMovement.translationMD > 0.25 ||
      relativeDentalMovement.translationMD < -0.25
    );
  };

  const isRotationOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    return relativeDentalMovement.pureRotation > 3 || relativeDentalMovement.pureRotation < -3;
  };

  const isTorqueOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    return relativeDentalMovement.torque > 2 || relativeDentalMovement.torque < -2;
  };

  const isTIPOverMovement = (relativeDentalMovement: DentalMovementDTO) => {
    return relativeDentalMovement.tip > 2 || relativeDentalMovement.tip < -2;
  };

  return {
    movementsTable,
    getMovementsTable,
    getRelativeDentalMovementsByStep,
    isOverMovement
  };
}
