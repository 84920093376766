import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { ElementRemoveMode } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export enum ModalDeleteAttachmentType {
  Attache = 'attache',
  TAD = 'TAD'
}

interface ModalDeleteAttachProps {
  type?: ModalDeleteAttachmentType;
}

export function ModalDeleteAttach({ type = ModalDeleteAttachmentType.Attache }: ModalDeleteAttachProps) {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const selectedTeethId = useOrthBoundStore((state) => state.selectedTeethId);
  const selectedAttache = useOrthBoundStore((state) => state.selectedAttache);

  const { updateMovements } = useUtils();
  const [selectedOption, setSelectedOption] = useState<number>(ElementRemoveMode.RemoveInAllSteps);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const handleContinue = () => {
    if (selectedTeethId.length > 0) {
      selectedTeethId.forEach((id) => {
        window.App.webEventsProxy.attachments.removeAttachment(id, selectedOption);
      });
    } else if (selectedAttache && selectedAttache.toothFdi) {
      window.App.webEventsProxy.attachments.removeAttachment(selectedAttache.toothFdi, selectedOption);
    }

    updateMovements();
    toggleModalIsOpened('');
  };

  const handleSelected = (selectedOption: number) => {
    setSelectedOption(selectedOption);
  };

  const isActive = (option: number) => {
    return selectedOption === option;
  };

  return (
    <div className="modal-content" data-testid={'modal-deleteAttach'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">
          {t(`modal.deleteAttachment.${type === ModalDeleteAttachmentType.Attache ? 'titleAttache' : 'titleTAD'}`)}
        </h3>
        <p className="modal-options modal-text text-left mb-0">
          <Radio
            label={t(`modal.delete.options.deleteInAllSteps`)}
            active={isActive(ElementRemoveMode.RemoveInAllSteps)}
            callBack={() => handleSelected(ElementRemoveMode.RemoveInAllSteps)}
            expanded={false}
          />
          <Radio
            label={t(`modal.delete.options.deleteFromActualStepToLast`)}
            active={isActive(ElementRemoveMode.RemoveFromCurrentStepToEnd)}
            callBack={() => handleSelected(ElementRemoveMode.RemoveFromCurrentStepToEnd)}
            expanded={false}
          />
          <Radio
            label={t(`modal.delete.options.deleteFromActualStepToInitial`)}
            active={isActive(ElementRemoveMode.RemoveFromCurrentStepToStart)}
            callBack={() => handleSelected(ElementRemoveMode.RemoveFromCurrentStepToStart)}
            expanded={false}
          />
        </p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button
          onClick={handleContinue}
          disabled={selectedOption === null}
          type="button"
          className="btn btn-primary flex-fill modal-button"
        >
          {t(`modal.actions.delete`)}
        </button>
      </div>
    </div>
  );
}
