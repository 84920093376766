import { useEffect, useState } from 'react';
import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { IUserProfileService, SaaSUserProfileDTO, UserProfile } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { PathLevels } from '../../models';

export function useUserProfileData() {
  const userProfileFromStore = useBoundStore((state) => state.userProfile);
  const [userProfile, setUserProfile] = useState<UserProfile | SaaSUserProfileDTO>(userProfileFromStore);

  useEffect(() => {
    const getUserProfile = async () => {
      if (!userProfile) {
        try {
          const userProfileService = container.get<IUserProfileService>(INJECTED_TYPES.IUserProfileService);

          const pathname = window.location.pathname;
          const caseIdFromRoute = pathname.split('/')[PathLevels.CaseId];
          const fetchedUserProfile = await userProfileService.getProfile(caseIdFromRoute);
          setUserProfile(fetchedUserProfile);
        } catch (error) {
          console.error(`Error fetching profile -> ${error}`);
          return error;
        }
      }
    };

    getUserProfile();
  }, [userProfile]);

  return [userProfile] as const;
}
