import { useEvergineStore } from 'evergine-react';
import JSZip from 'jszip';
import { useCallback, useEffect } from 'react';
import {
  CapturePosition,
  DentalMovementGroup,
  INJECTED_TYPES,
  Model,
  Model3dType,
  Stage,
  container
} from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { ArchsToTreat, CaseFile, FileCaptureType, ICommandInvoker, PatientCase } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useFiles } from '../useFiles';
import { useGetDentalMovements } from './useGetDentalMovements';
import { useGetFiles } from './useGetFiles';
import { useLoadFilesIntoFS } from './useLoadFiles';
import { useLocation } from 'react-router-dom';
import { OrthodonticsPagesUrl } from '../../orthodontics/OrthodonticsRoutes';
import { useFetchModelFiles } from './useFetchModelFiles';
import { useGetRoots } from './useGetRoots';
import { useSaveRealRoots } from '../orthodontics/useSaveRealRoots';
import { ModalTypes } from '../../surgeries/components/layout';
import { useTranslation } from 'react-i18next';

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;

const stagesWithArchModels = [Stage.LoadSTL, Stage.STLEdition, Stage.TeethSegmentation];
const stagesWithDentalMovements = [Stage.AxisAndRoots, Stage.Treatment, Stage.Publish];
const stagesWithOriginalModelsInFS = [Stage.STLEdition];

const pagesWithMovementTools = [
  { page: OrthodonticsPagesUrl.DentalMovements, checkByClient: true },
  { page: OrthodonticsPagesUrl.Treatment }
];

export function useRenderModels(caseId: string, stage: Stage) {
  const { fetchNonCachedModelFile } = useFetchModelFiles();
  const lowerModel3DId = useCommonBoundStore((state) => state.lowerModel3DId);
  const upperModel3DId = useCommonBoundStore((state) => state.upperModel3DId);
  const hasArchModelsLoaded = useCommonBoundStore((state) => state.hasArchModelsLoaded);
  const setHasArchModelsLoaded = useCommonBoundStore((state) => state.setHasArchModelsLoaded);
  const setLowerModel3DId = useCommonBoundStore((state) => state.setLowerModel3DId);
  const setUpperModel3DId = useCommonBoundStore((state) => state.setUpperModel3DId);
  const isNewStepClientModification = useCommonBoundStore((state) => state.isNewStepClientModification);
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const setArchsToBeTreated = useCommonBoundStore((state) => state.setArchsToBeTreated);

  const { evergineReady } = useEvergineStore();

  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setMessageInfo = useBoundStore((state) => state.setMessageInfo);
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);
  const patientCase = useBoundStore((state) => state.patientCase);

  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const upperDentalMovements = useOrthBoundStore((state) => state.upperDentalMovements);
  const lowerDentalMovements = useOrthBoundStore((state) => state.lowerDentalMovements);
  const areSegmentationModelsInFS = useOrthBoundStore((state) => state.areSegmentationModelsInFS);
  const areTeethModelsLoaded = useOrthBoundStore((state) => state.areTeethModelsLoaded);
  const previousStage = useOrthBoundStore((state) => state.previousStage);
  const areRootModelsLoaded = useOrthBoundStore((state) => state.areRootModelsLoaded);
  const upperZipFileRoute = useOrthBoundStore((state) => state.upperZipFileRoute);
  const lowerZipFileRoute = useOrthBoundStore((state) => state.lowerZipFileRoute);

  const setLowerDentalMovements = useOrthBoundStore((state) => state.setLowerDentalMovements);
  const setUpperDentalMovements = useOrthBoundStore((state) => state.setUpperDentalMovements);
  const setUpperAndLowerDentalMovements = useOrthBoundStore((state) => state.setUpperAndLowerDentalMovements);
  const setAreMovementsLoaded = useOrthBoundStore((state) => state.setAreMovementsLoaded);
  const setStageIsLoaded = useOrthBoundStore((state) => state.setStageIsLoaded);
  const setIsInFinalPositionStage = useOrthBoundStore((state) => state.setIsInFinalPositionStage);
  const setAreTeethModelsLoaded = useOrthBoundStore((state) => state.setAreTeethModelsLoaded);
  const setAreMadeChangesOnTeeth = useOrthBoundStore((state) => state.setAreMadeChangesOnTeeth);
  const setPreviousStage = useOrthBoundStore((state) => state.setPreviousStage);
  const setShowMovements = useOrthBoundStore((state) => state.setShowMovements);
  const setShowAttachesPanel = useOrthBoundStore((state) => state.setShowAttachesPanel);
  const setAxixRootsEditMode = useOrthBoundStore((state) => state.setAxixRootsEditMode);
  const setShowMiniOcclusogram = useOrthBoundStore((state) => state.setShowMiniOcclusogram);
  const setAreRootModelsLoaded = useOrthBoundStore((state) => state.setAreRootModelsLoaded);
  const setShowTadsPanel = useOrthBoundStore((state) => state.setShowTadsPanel);
  const setHasLoadedOrientedStls = useOrthBoundStore((state) => state.setHasLoadedOrientedStls);
  const setHasLoadedExtrudedGums = useOrthBoundStore((state) => state.setHasLoadedExtrudedGums);
  const setShowGumExtrusionPanel = useOrthBoundStore((state) => state.setShowGumExtrusionPanel);
  const setShowStlOrientationPanel = useOrthBoundStore((state) => state.setShowStlOrientationPanel);

  const { pathname } = useLocation();
  const {
    loadUpperModel3DInFS,
    loadLowerModel3DInFS,
    loadRootModel3DInFS,
    loadOriginalModel3DInFS,
    loadRootsModelsFromZipInFs,
    loadOrientationTransformationMatrices
  } = useLoadFilesIntoFS();
  const { isFile } = useFiles();
  const { getFileInfo } = useGetFiles(caseId);
  const { isDir } = useFiles();
  const { getDentalMovementsFromEvergine, getDentalMovementsFromBackend } = useGetDentalMovements();
  const { loadRoots } = useGetRoots(caseId);
  const { saveAxisAndRootsZip } = useSaveRealRoots(caseId);
  const { t } = useTranslation();
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);

  const isStageWithArchModels = (stage: Stage) => stagesWithArchModels.includes(stage);
  const isStageWithDentalMovements = (stage: Stage) => stagesWithDentalMovements.includes(stage);
  const isStageWithOriginalModelsInFS = (stage: Stage) => stagesWithOriginalModelsInFS.includes(stage);

  useEffect(() => {
    commandInvokerService.clear();
  }, [stage]);

  useEffect(() => {
    if (upperZipFileRoute) saveAxisAndRootsZip(upperZipFileRoute, CapturePosition.UPPER);
  }, [upperZipFileRoute]);

  useEffect(() => {
    if (lowerZipFileRoute) saveAxisAndRootsZip(lowerZipFileRoute, CapturePosition.LOWER);
  }, [lowerZipFileRoute]);

  useEffect(() => {
    const handleLoadStage = async (): Promise<void> => {
      setIsInFinalPositionStage(false);
      if (!evergineReady || !patientCase || currentVersion === null) {
        return;
      }
      setWebBusy(true);
      setWebBusyMessage(t('common.loaderActions.loadingModels'));

      setStageIsLoaded(false);
      setAreMovementsLoaded(false);
      let areDentalMovementsLoadedInEvergine = false;

      let lowerMovements = lowerDentalMovements;
      let upperMovements = upperDentalMovements;
      if (isStageWithDentalMovements(stage)) {
        // TODO: revisar para casos en los que haya tratamiento de una sóla arcada
        if (!lowerMovements || !upperMovements) {
          let movements = getDentalMovementsFromEvergine();

          if (movements.lowerMovements || movements.upperMovements) {
            areDentalMovementsLoadedInEvergine = true;
            setAreMovementsLoaded(true);
          }

          if (!movements.upperMovements && !movements.lowerMovements) {
            movements = await getDentalMovementsFromBackend(currentVersion);
          }

          if (movements.upperMovements && movements.lowerMovements) {
            upperMovements = movements.upperMovements;
            lowerMovements = movements.lowerMovements;
            setUpperAndLowerDentalMovements(upperMovements, lowerMovements);
          } else if (movements.upperMovements) {
            upperMovements = movements.upperMovements;
            setUpperDentalMovements(upperMovements);
          } else if (movements.lowerMovements) {
            lowerMovements = movements.lowerMovements;
            setLowerDentalMovements(lowerMovements);
          }
        } else {
          if (lowerMovements || upperMovements) {
            // ToDo: optimize to avoid load models and set dental movements when already in Evergine
            areDentalMovementsLoadedInEvergine = true;
            setAreMovementsLoaded(true);
          }
        }
      }

      const forceSetMovements =
        (previousStage === Stage.TeethSegmentation || previousStage === undefined) && stage === Stage.AxisAndRoots;

      // 1 loadModels
      // 1a Models as one wepmd per jaw (stl-captures and teeth-segmentation screens)
      if (isStageWithArchModels(stage) && !hasArchModelsLoaded) {
        await renderBothModels3D(patientCase);
        setHasArchModelsLoaded(true);
      }

      // 1b load original no edited STLs in FS
      if (isStageWithOriginalModelsInFS(stage)) {
        await loadOriginalModels3DInFS();
      }

      // 1c Set roots
      if (!areRootModelsLoaded) {
        await renderBothRoots3D(patientCase);
        setAreRootModelsLoaded(true);
      }

      // 1d Models as one wepmd per teeth/gum
      if (isStageWithDentalMovements(stage) && !areTeethModelsLoaded) {
        await loadModels(upperMovements, lowerMovements);
        setAreTeethModelsLoaded(true);
      }

      // 2 setMovements
      if ((isStageWithDentalMovements(stage) && !areDentalMovementsLoadedInEvergine) || forceSetMovements) {
        await loadDentalMovements(upperMovements, lowerMovements);
        setAreMovementsLoaded(true);
      }

      // 3 setstage
      window.App.webEventsProxy.common.setStage(stage);

      // 4 Reset states and communicate
      resetStates();

      setStageIsLoaded(true);

      setPreviousStage(stage);
    };

    handleLoadStage()
      .catch(console.error)
      .finally(() => {
        if (!evergineReady || !patientCase || currentVersion === null) {
          return;
        }
        setWebBusy(false);
        setWebBusyMessage('');
      });
  }, [evergineReady, patientCase, stage, currentVersion]);

  const resetStates = useCallback(() => {
    const shouldHaveOpenPanel = pagesWithMovementTools.some((p) => {
      let hasRoute = pathname.includes(p.page);
      if (p.checkByClient && hasRoute) {
        hasRoute = isNewStepClientModification;
      }
      return hasRoute;
    });
    setAxixRootsEditMode(false);
    setAreMadeChangesOnTeeth(false);
    setShowMovements(shouldHaveOpenPanel);
    setShowMiniOcclusogram(false);
    setMessageInfo('');
    setShowAttachesPanel(false);
    setShowTadsPanel(false);
    setShowGumExtrusionPanel(false);
    setShowStlOrientationPanel(false);
  }, [isNewStepClientModification]);

  const getOriginalModelInfo = useCallback(
    async (teethArch: CapturePosition): Promise<CaseFile | null> => {
      const modelInfo = await getFileInfo(
        teethArch == CapturePosition.UPPER ? FileCaptureType.UPPER_STL : FileCaptureType.LOWER_STL,
        currentVersion.id
      );

      return modelInfo;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const getModelInfo = useCallback(
    async (teethArch: CapturePosition): Promise<CaseFile | null> => {
      const modelId = teethArch === CapturePosition.UPPER ? upperModel3DId : lowerModel3DId;

      if (modelId) {
        return {
          name: modelId
        } as CaseFile;
      }

      const modelInfo = await getFileInfo(
        teethArch == CapturePosition.UPPER
          ? FileCaptureType.UPPER_MODEL3D_EDITED
          : FileCaptureType.LOWER_MODEL3D_EDITED,
        currentVersion.id
      );

      if (modelInfo && modelInfo.url === undefined) {
        return await getFileInfo(
          teethArch == CapturePosition.UPPER ? FileCaptureType.UPPER_MODEL3D : FileCaptureType.LOWER_MODEL3D,
          currentVersion.id
        );
      }

      // don't show warning modal about STL has not been edited
      setHasLoadedOrientedStls(true);

      return modelInfo;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const loadModel3D = useCallback(
    async (patientCase: PatientCase, teethArch: CapturePosition): Promise<Model | undefined> => {
      if (!patientCase) {
        return undefined;
      }

      if (!patientCase.scannings) {
        return undefined;
      }

      const modelInfo = await getModelInfo(teethArch);

      if (modelInfo?.name) {
        const fileName = `${modelInfo.name}`;
        const fileFSFullPath = `${dynamicModelsFullPath}/${fileName}`;
        const isFileInFS = isFile(fileFSFullPath);

        if (!isFileInFS) {
          const loadModelInFS = teethArch === CapturePosition.UPPER ? loadUpperModel3DInFS : loadLowerModel3DInFS;
          await loadModelInFS(patientCase, modelInfo);
        }

        const model = {
          id: fileName.replace('.wepmd', ''),
          uri: `${dynamicModels}/${fileName}`,
          teethArch: teethArch,
          model3dType: Model3dType.Scan
        };

        return model;
      }
    },
    [currentVersion]
  );

  const loadOriginalModel3D = useCallback(
    async (teethArch: CapturePosition): Promise<void> => {
      const originalModelInfo = await getOriginalModelInfo(teethArch);

      if (originalModelInfo?.name) {
        const fileName = `${originalModelInfo.name}`;
        const fileFSFullPath = `${dynamicModelsFullPath}/${fileName}`;
        const isFileInFS = isFile(fileFSFullPath);

        if (!isFileInFS) {
          await loadOriginalModel3DInFS(originalModelInfo);
        }
      }
    },
    [currentVersion]
  );

  const loadRoots3D = useCallback(
    async (patientCase: PatientCase, teethArch: CapturePosition): Promise<Model[] | undefined> => {
      if (!patientCase) {
        return undefined;
      }

      if (!patientCase.scannings) {
        return undefined;
      }

      const roots = await loadRoots(teethArch);
      if (!roots) return undefined;

      const models: Model[] = [];
      if (roots.name.endsWith('.stl')) {
        await loadRootModel3DInFS(roots);

        models.push({
          id: roots.name.replace('.stl', ''),
          uri: `${dynamicModels}/${roots.name}`,
          teethArch: teethArch,
          model3dType: Model3dType.Roots
        });
      } else if (roots.name.endsWith('.zip')) models.push(...(await loadRootsModelsFromZipInFs(roots, teethArch)));
      else return undefined;

      return models;
    },
    [currentVersion]
  );

  const renderBothRoots3D = useCallback(
    async (patientCase: PatientCase): Promise<void> => {
      if (currentVersion.isProccessingRealRoots) {
        toggleModalIsOpened(ModalTypes.ModalProccesingRealRoots);
      }

      const upperModels = await loadRoots3D(patientCase, CapturePosition.UPPER);
      const lowerModels = await loadRoots3D(patientCase, CapturePosition.LOWER);

      if (upperModels === undefined && lowerModels === undefined) {
        return;
      }

      const modelsToLoad: Model[] = [];

      if (upperModels) {
        modelsToLoad.push(...upperModels);
      }

      if (lowerModels) {
        modelsToLoad.push(...lowerModels);
      }

      await window.App.webEventsProxy.common.loadModels(modelsToLoad);
    },
    [loadRoots3D, currentVersion]
  );

  const loadOriginalModels3DInFS = useCallback(async (): Promise<void> => {
    await loadOriginalModel3D(CapturePosition.UPPER);
    await loadOriginalModel3D(CapturePosition.LOWER);
  }, [currentVersion]);

  const renderBothModels3D = useCallback(
    async (patientCase: PatientCase): Promise<void> => {
      const upperModel = await loadModel3D(patientCase, CapturePosition.UPPER);
      const lowerModel = await loadModel3D(patientCase, CapturePosition.LOWER);

      if (upperModel === undefined && lowerModel === undefined) {
        return;
      }

      const modelsToLoad: Model[] = [];
      const modelInstances = [];

      if (upperModel) {
        modelsToLoad.push(upperModel);
        modelInstances.push({ modelId: upperModel.id });
        setUpperModel3DId(upperModel.id);
      }

      if (lowerModel) {
        modelsToLoad.push(lowerModel);
        modelInstances.push({ modelId: lowerModel.id });
        setLowerModel3DId(lowerModel.id);
      }

      if (upperModel && lowerModel) {
        setArchsToBeTreated(ArchsToTreat.Both);
      } else if (upperModel) {
        setArchsToBeTreated(ArchsToTreat.Upper);
      } else if (lowerModel) {
        setArchsToBeTreated(ArchsToTreat.Lower);
      }

      await window.App.webEventsProxy.common.loadModels(modelsToLoad);
      await loadOrientationTransformationMatrices(currentVersion?.id);

      const isExtruded = window.App.webEventsProxy.modelTransformation.isModelExtruded();
      setHasLoadedExtrudedGums(isExtruded);
    },
    [loadModel3D, currentVersion, setLowerModel3DId, setUpperModel3DId]
  );

  const saveTeethModelsFromBackendInFS = useCallback(async (): Promise<void> => {
    if (currentVersion === null) {
      return;
    }

    const fileInfo = await getFileInfo('teeth-zip', currentVersion.id);

    if (!fileInfo) {
      return;
    }

    const resFile = await fetchNonCachedModelFile(fileInfo.url);
    const arrayBufferData = await resFile.arrayBuffer();

    const unzipper = new JSZip();
    const zipData = await unzipper.loadAsync(arrayBufferData);

    const promises: Promise<void>[] = [];

    zipData.forEach(async (_, zipEntry) => {
      if (!zipEntry.dir) {
        promises.push(
          (async (): Promise<void> => {
            const fileData = await zipEntry.async('uint8array');
            const binData = new Int8Array(fileData);
            const fileFullPath = `${dynamicModelsFullPath}/${zipEntry.name}`;
            Module.FS.writeFile(fileFullPath, binData);
          })()
        );
      }
    });

    await Promise.all(promises);
  }, [currentVersion]);

  const getModelFiles = (dentalMovements: DentalMovementGroup): Model[] => {
    if (!dentalMovements) {
      return [];
    }

    const modelFiles: Model[] = [];

    dentalMovements.teeth.forEach((t) => {
      const filePath = `${dynamicModels}/${t.id}.wepmd`;
      modelFiles.push({
        id: t.id.replace('.wepmd', ''),
        uri: filePath,
        teethArch: dentalMovements.teethArch,
        model3dType: Model3dType.Tooth
      });
    });

    const gum = dentalMovements.gum;
    modelFiles.push({
      id: gum.id,
      uri: `${dynamicModels}/${gum.id}.wepmd`,
      teethArch: dentalMovements.teethArch,
      model3dType: Model3dType.Tooth
    });

    return modelFiles;
  };

  const loadDentalMovements = useCallback(
    async (upperMovements: DentalMovementGroup, lowerMovements: DentalMovementGroup): Promise<void> => {
      let movementsAreLoaded = false;
      if (upperMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(upperMovements, false);
        movementsAreLoaded = true;
      }

      if (lowerMovements !== null) {
        await window.App.webEventsProxy.movements.setMovements(lowerMovements, false);
        movementsAreLoaded = true;
      }

      if (movementsAreLoaded) {
        setAreMovementsLoaded(true);
      }
    },
    [setAreMovementsLoaded]
  );

  // const loadOrientationTransformationMatrix = async (key: string) => {
  //   const fileInfo = await getFileInfo(key, currentVersion.id);

  //   if (!fileInfo) {
  //     return;
  //   }

  //   const resFile = await fetchNonCachedModelFile(fileInfo.url);
  // };

  const loadModels = useCallback(
    async (upperDentalMovements: DentalMovementGroup, lowerDentalMovements: DentalMovementGroup): Promise<void> => {
      const dynamicModelsFullPath = '/Content/DynamicModels';

      if (!isDir(dynamicModelsFullPath)) {
        Module.FS.mkdir(dynamicModelsFullPath);
      }

      if (!areSegmentationModelsInFS) {
        await saveTeethModelsFromBackendInFS();
      }

      const modelFiles = [...getModelFiles(upperDentalMovements), ...getModelFiles(lowerDentalMovements)];
      await window.App.webEventsProxy.common.loadModels(modelFiles);
    },
    [areSegmentationModelsInFS, currentVersion]
  );
}
