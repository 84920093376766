import { ReactComponent as TADCutDistal } from '../../../../../assets/icons/evergineTools/tads/TADCutDistal.svg';
import { ReactComponent as TADCutMesial } from '../../../../../assets/icons/evergineTools/tads/TADCutMesial.svg';
import { ReactComponent as TADButton } from '../../../../../assets/icons/evergineTools/tads/TADButton.svg';
import { AttachmentType } from '../../../../../common/evergine/types';

export const tadsTypes = [
  {
    id: 'cutDistal',
    typeAtt: AttachmentType.CutDistal,
    icon: TADCutDistal,
    text: 'tadsPanel.cutDistal'
  },
  {
    id: 'cutMesial',
    typeAtt: AttachmentType.CutMesial,
    icon: TADCutMesial,
    text: 'tadsPanel.cutMesial'
  },
  {
    id: 'button',
    typeAtt: AttachmentType.Button,
    icon: TADButton,
    text: 'tadsPanel.button'
  }
];

export const attachmentsTypeTad = [AttachmentType.CutDistal, AttachmentType.CutMesial, AttachmentType.Button];

export const isTadType = (type: AttachmentType) => attachmentsTypeTad.includes(type);
