import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import { useEvergineStore } from 'evergine-react';
import Axis from '../../../../../assets/icons/axis.svg';
import { NavBarAction } from '../../../../../common';
import { useFocusCanvas } from '../../../../../hooks/shared/useFocusCanvas';
import { OrthodonticsPagesUrl } from '../../../../OrthodonticsRoutes';
import { PathLevels } from '../../../../../models';

export default () => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const [showAxis, setShowAxis] = useState(true);
  const { webAssemblyLoaded, evergineReady } = useEvergineStore();
  const { setFocusOnCanvas } = useFocusCanvas();
  const mustCallShowReference = useMemo(() => {
    return `/${pathname.split('/')[PathLevels.RouteView]}` === OrthodonticsPagesUrl.StlEdition;
  }, [pathname]);

  useEffect(() => {
    if (webAssemblyLoaded && evergineReady) {
      window.App.webEventsProxy.layers && window.App.webEventsProxy.layers.showAxis(showAxis);
      if (mustCallShowReference) {
        window.App.webEventsProxy.layers && window.App.webEventsProxy.layers.showReference(showAxis);
      }
    }
  }, [webAssemblyLoaded, evergineReady]);

  const toggleAxis = useCallback(() => {
    window.App.webEventsProxy.layers.showAxis(!showAxis);
    if (mustCallShowReference) {
      window.App.webEventsProxy.layers && window.App.webEventsProxy.layers.showReference(!showAxis);
    }
    setShowAxis(!showAxis);
    setFocusOnCanvas();
  }, [showAxis]);

  const buttonId = 'toggleAxis';

  return (
    <>
      <NavBarAction icon={Axis} dataFor={buttonId} altText={t('navbar.actions.nextStep')} action={toggleAxis} />
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.information.axis`)}
      </ReactTooltip>
    </>
  );
};
