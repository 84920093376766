import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/esm/Carousel';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left-white.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right-white.svg';
import { ReactComponent as Backward } from '../../../assets/icons/backward.svg';
import { ReactComponent as Forward } from '../../../assets/icons/forward.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/remove-view.svg';
import { IconRoundButton } from '../../../common/components/iconRoundButton';
import { useCarousel } from '../../../hooks/shared/useCarousel';
import { PhotoCaptureType, isStringVoid } from '../../../shared';
import { ImageToShow } from '../../../surgeries/components';
import { FileItem } from './FileItem';
import { NoFiles } from './NoFiles';
import './filesTab.scss';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { isSaasApiEnabled } from '../../../shared/settings';

type FilesTabProps = {
  slideParentAnchor: MutableRefObject<HTMLDivElement>;
};

type PhotoCaptureLocalUrl = {
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: ImageToShow;
  [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: ImageToShow;
  [PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY]: ImageToShow;
  [PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY]: ImageToShow;
  // [PhotoCaptureType.PHOTOGRAPHS_OTHERS]: ImageToShow;
};

export function FilesTab(props: FilesTabProps) {
  const { slideParentAnchor } = props;

  const carouselRef = useRef(null);

  const [t] = useTranslation();
  const patientCase = useBoundStore((state) => state.patientCase);

  const {
    imagesForCarousel,
    indexSelectedSlide,
    onFirstClick,
    onLastClick,
    onNextClick,
    onPrevClick,
    setImagesForCarousel,
    setIndexSelectedSlide
  } = useCarousel(carouselRef);

  const [typeOtherImages, setTypeOtherImages] = useState<ImageToShow[]>([]);
  const [showCarousel, setShowCarousel] = useState<boolean>();
  const [imagesWithError, setImagesWithError] = useState<string[]>([]);

  const [images, setImages] = useState<PhotoCaptureLocalUrl>({
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT,
      title: t('photographs.leftOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT,
      title: t('photographs.rightOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER,
      title: t('photographs.lowerOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: {
      key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER,
      title: t('photographs.upperOcclusion'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: {
      key: PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE,
      title: t('photographs.frontSmile'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY]: {
      key: PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY,
      title: t('photographs.orthopantomography'),
      url: undefined,
      name: undefined,
      size: undefined
    },
    [PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY]: {
      key: PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY,
      title: t('photographs.teleradiography'),
      url: undefined,
      name: undefined,
      size: undefined
    }
    // [PhotoCaptureType.PHOTOGRAPHS_OTHERS]: {
    //   id: PhotoCaptureType.PHOTOGRAPHS_OTHERS,
    //   title: t('photographs.others'),
    //   url: undefined,
    //   name: undefined,
    //   size: undefined
    // }
  });

  useEffect(() => {
    if (!patientCase) {
      return;
    }

    const receivedPhotographs = patientCase.photographs;
    let newImages = { ...images };
    if (!isStringVoid(receivedPhotographs?.leftOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT],
          url: receivedPhotographs?.leftOcclusion.url,
          name: receivedPhotographs?.leftOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.rightOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT],
          url: receivedPhotographs?.rightOcclusion.url,
          name: receivedPhotographs?.rightOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.lowerOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER],
          url: receivedPhotographs?.lowerOcclusion.url,
          name: receivedPhotographs?.lowerOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.upperOcclusion.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER],
          url: receivedPhotographs?.upperOcclusion.url,
          name: receivedPhotographs?.upperOcclusion.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.frontSmile.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE]: {
          ...images[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE],
          url: receivedPhotographs?.frontSmile.url,
          name: receivedPhotographs?.frontSmile.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.orthopantomography.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY]: {
          ...images[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY],
          url: receivedPhotographs?.orthopantomography.url,
          name: receivedPhotographs?.orthopantomography.name
        }
      };
    }
    if (!isStringVoid(receivedPhotographs?.teleradiography.url)) {
      newImages = {
        ...newImages,
        [PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY]: {
          ...images[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY],
          url: receivedPhotographs?.teleradiography.url,
          name: receivedPhotographs?.teleradiography.name
        }
      };
    }

    // TODO: Add others images when endpoint is ready
    /*const otherImages = receivedPhotographs?.others
      .filter((p) => !isStringVoid(p.url))
      .map((p) => ({
        ...p,
        title: t('photographs.others'),
        size: undefined,
        isUploaded: true
      }));
    setTypeOtherImages(otherImages);*/
    const otherImages: any = [];

    setImages(newImages as PhotoCaptureLocalUrl);
    const filteredImagesForCarousel = [...Object.values(newImages), ...otherImages].filter((i) => i.url !== undefined);
    filteredImagesForCarousel.forEach(async (image) => {
      try {
        const url = await getImageUrlWithAuth(image.url);
        image.url = url;
      } catch (error) {
        setImagesWithError([...imagesWithError, image.key]);
      }
    });
    setImagesForCarousel(filteredImagesForCarousel);
  }, [patientCase]);

  const onClickBackdrop = (identifier: string) => {
    const index = imagesForCarousel.filter((i) => i.url !== undefined).findIndex((img) => img.key === identifier);
    setIndexSelectedSlide(index);
    setShowCarousel(true);
  };

  const getImageUrlWithAuth = async (url: string): Promise<string> => {
    let headers: HeadersInit = {};
    if (isSaasApiEnabled()) {
      if (sessionStorage.getItem('authToken')) {
        headers = {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        };
      } else {
        headers = {
          'X-Public-Access-Token': sessionStorage.getItem('publicAccessToken')
        };
      }
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: headers
    });
    if (!response.ok) {
      throw new Error('Error loading image');
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  return (
    <div className="files-tab">
      {showCarousel &&
        createPortal(
          <div className="files-tab-carousel">
            <div className="files-tab-carousel-close">
              <IconRoundButton icon={CrossIcon} onClick={() => setShowCarousel(false)} />
            </div>
            <div className="files-tab-carousel-navbar">
              <IconRoundButton icon={Backward} onClick={onFirstClick} />
              <IconRoundButton icon={ArrowLeft} onClick={onPrevClick} />
              <div className="files-tab-carousel-page">
                <span>
                  {indexSelectedSlide + 1} / {imagesForCarousel.length}
                </span>
              </div>
              <IconRoundButton icon={ArrowRight} onClick={onNextClick} />
              <IconRoundButton icon={Forward} onClick={onLastClick} />
            </div>
            <div className="files-tab-carousel-images">
              <Carousel
                ref={carouselRef}
                activeIndex={indexSelectedSlide}
                controls={false}
                interval={null}
                indicators={false}
              >
                {imagesForCarousel.map((slide) => (
                  <Carousel.Item key={slide.key}>
                    <img src={slide.url} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>,
          slideParentAnchor.current
        )}
      {images && (
        <ul className="files-tab-image-list">
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].title}
                src={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].title}
                src={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].title}
                src={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].title}
                src={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].title}
                src={images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].title}
                src={images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].key)}
                handleImageError={() =>
                  setImagesWithError([
                    ...imagesWithError,
                    images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].key
                  ])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY].title} />
            )}
          </li>
          <li className="files-tab-image">
            {images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].url ? (
              <FileItem
                label={images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].title}
                src={images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].url}
                onClickBackdrop={() => onClickBackdrop(images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].key)}
                handleImageError={() =>
                  setImagesWithError([...imagesWithError, images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].key])
                }
              />
            ) : (
              <NoFiles title={images?.[PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY].title} />
            )}
          </li>
          {/* <li className="files-tab-image">
            <FileItem
              label={images?.[PhotoCaptureType.PHOTOGRAPHS_OTHERS].title}
              src={typeOtherImages[0].url}
              onClickBackdrop={() => onClickBackdrop(typeOtherImages[0].id)}
            />
          </li> */}
        </ul>
      )}
    </div>
  );
}
