import { useCallback } from 'react';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { useUtils } from './useUtils';

export function useIprCalculation() {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { setIprProcessed, iprProcessed } = useOrthBoundStore();
  const { getCurrentPhaseStageByRoute } = useUtils();
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);
  const { movementsTable, caseStatusFromStore, setShowIprPanel, setForceShowIpr } = useOrthBoundStore((state) => ({
    movementsTable: state.movementsTable,
    caseStatusFromStore: state.caseStatus,
    setShowIprPanel: state.setShowIprPanel,
    setForceShowIpr: state.setForceShowIpr
  }));

  const calculateIpr = useCallback(async () => {
    setWebBusy(true);
    await window.App.webEventsProxy.movements.processIntermediateSteps();
    setWebBusy(false);
    setIprProcessed(true);
    setShowIprPanel(false);
    setForceShowIpr(true);
  }, []);

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const hasPhaseCompletionDate = useCallback((): boolean => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (currentPhase === undefined) {
      return false;
    }
    const completionDate = getPhaseCompletionDate(currentPhase);

    return completionDate !== '';
  }, [getPhaseCompletionDate]);

  const checkIfIprsAreCalculated = (): boolean => {
    return (
      movementsTable?.movementsRelative?.length <= 2 ||
      iprProcessed ||
      (iprProcessed == null && hasPhaseCompletionDate() && !isCaseReseting)
    );
  };

  return { calculateIpr, checkIfIprsAreCalculated };
}
