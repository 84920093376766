import { StateCreator } from 'zustand';
import { Axis, CapturePosition } from '../../../common';
import { StlOrientationMovementTypes } from '../../components';

export type StlOrientationValueFrom3d = {
  movementType: StlOrientationMovementTypes;
  arch: CapturePosition;
  axis: Axis;
  value: number;
};
export interface EditStlSlice {
  hasStlBeenOriented: boolean;
  setHasStlBeenOriented: (hasStlBeenOriented: boolean) => void;
  haveGumsBeenExtruded: boolean;
  setHaveGumsBeenExtruded: (haveGumsBeenExtruded: boolean) => void;
  hasLoadedOrientedStls: boolean;
  setHasLoadedOrientedStls: (hasLoadedOrientedStls: boolean) => void;
  hasLoadedExtrudedGums: boolean;
  setHasLoadedExtrudedGums: (hasLoadedExtrudedGums: boolean) => void;
  orientationValueFrom3d?: StlOrientationValueFrom3d;
  setOrientationValueFrom3d: (orientationValueFrom3d: StlOrientationValueFrom3d) => void;
}

export const createEditStlSlice: StateCreator<EditStlSlice, [], [], EditStlSlice> = (set) => ({
  hasStlBeenOriented: false,
  setHasStlBeenOriented: (hasStlBeenOriented: boolean) => set((_) => ({ hasStlBeenOriented })),
  haveGumsBeenExtruded: false,
  setHaveGumsBeenExtruded: (haveGumsBeenExtruded: boolean) => set((_) => ({ haveGumsBeenExtruded })),
  hasLoadedOrientedStls: false,
  setHasLoadedOrientedStls: (hasLoadedOrientedStls: boolean) => set((_) => ({ hasLoadedOrientedStls })),
  hasLoadedExtrudedGums: false,
  setHasLoadedExtrudedGums: (hasLoadedExtrudedGums: boolean) => set((_) => ({ hasLoadedExtrudedGums })),
  orientationValueFrom3d: undefined,
  setOrientationValueFrom3d: (orientationValueFrom3d: StlOrientationValueFrom3d) =>
    set((_) => ({ orientationValueFrom3d }))
});
