import { isSaasApiEnabled } from '../../shared/settings';

const NO_CACHEABLE_FILES = ['upper.wepmd', 'upper.stl', 'lower.wepmd', 'lower.stl'];

const getUniqueUrl = (url: string): string => {
  const uniqueParam = `?t=${new Date().getTime()}`;
  return `${url}${uniqueParam}`;
};

export function useFetchModelFiles() {
  const isNoCacheableFile = (url: string) => NO_CACHEABLE_FILES.some((file) => url.includes(file));

  const fetchNonCachedModelFile = async (url: string) => {
    let headers: HeadersInit = {};

    if (isSaasApiEnabled()) {
      if (sessionStorage.getItem('authToken')) {
        headers = {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        };
      } else {
        headers = {
          'X-Public-Access-Token': sessionStorage.getItem('publicAccessToken')
        };
      }
    }

    const response = await fetch(isNoCacheableFile(url) ? getUniqueUrl(url) : url, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch file');
    }
    return response.blob();
  };

  return { fetchNonCachedModelFile };
}
