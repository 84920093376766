import { ModifyAttachesInitialStep, ModifyAttachesThickness } from '../customOptions';
import { ContextMenu } from '../../../../common';
import { CustomContextMenuProps } from '../customContextMenuProps';
import { ModifyAttachesLastStep } from '../customOptions/ModifyAttachesLastStep';
import { useCommonBoundStore } from '../../../../common/stores/useStore';

interface AttachesContextMenuProps extends CustomContextMenuProps {
  onClickOutside: () => void;
}

export function AttachesContextMenu({ onToggleAction, onClickOutside, positionData, show }: AttachesContextMenuProps) {
  const { activeStep } = useCommonBoundStore((state) => ({
    activeStep: state.activeStep
  }));
  const attacheOptions = [
    {
      labelKey: 'contextMenuOptions.attaches.addAtache',
      customJSX: <ModifyAttachesThickness onClickOutside={onClickOutside} />
    },
    {
      labelKey: 'contextMenuOptions.attaches.modifyFirstStep',
      customJSX: <ModifyAttachesInitialStep onClickOutside={onClickOutside} />
    },
    {
      labelKey: 'contextMenuOptions.attaches.modifyLastStep',
      customJSX: <ModifyAttachesLastStep onClickOutside={onClickOutside} />
    }
  ];

  const onToggleAttachesAction = () => {
    window.App.webEventsProxy.movements.clearSelection();
    window.App.webEventsProxy.movements.goToStep(activeStep, false);
    onToggleAction();
  };

  return (
    <ContextMenu
      options={attacheOptions}
      positionData={positionData}
      show={show}
      onToggle={() => onToggleAttachesAction()}
      autoClose={'outside'}
    />
  );
}
