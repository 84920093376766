import { ContextMenu } from '../../../../common';
import { OpenLabelsPanel } from '../../evergineToolbarElements/tools/evergineToolbar.helper';
import { CustomContextMenuProps } from '../customContextMenuProps';

interface PublishContextMenuProps extends CustomContextMenuProps {
  onClickOutside: () => void;
}

export function PublishContextMenu({ onToggleAction, positionData, show }: PublishContextMenuProps) {
  const publishOptions = [
    {
      labelKey: 'contextMenuOptions.addLabel',
      action: () => {
        OpenLabelsPanel();
      }
    }
  ];

  return (
    <ContextMenu
      options={publishOptions}
      positionData={positionData}
      show={show}
      onToggle={() => onToggleAction()}
      autoClose={true}
    />
  );
}
