import classNames from 'classnames';

import { AttachmentState } from '../../../../common';
import { EvolutionTooth } from '../EvolutionPanel';
import { useState } from 'react';
import { CustomTooltip } from '../../../../common/components/customTooltip';
import { setTimeout } from 'timers';
import { useTranslation } from 'react-i18next';

import './toothInfoBrick.scss';

interface ToothInfoBrickProps {
  evolutionTooth: EvolutionTooth | undefined;
  isMissingTooth: boolean;
  attachmentState?: AttachmentState;
  isOverMovement: boolean;
  columnIndex: number;
  canDrag: boolean;
  onDrag?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number, className: string) => void;
  onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number, className: string) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>, rowNumber: number) => void;
}

const tooltipPosition = { y: -40, x: 0 };

export function ToothInfoBrick({
  evolutionTooth,
  isMissingTooth,
  attachmentState,
  isOverMovement,
  columnIndex,
  canDrag,
  onDrag,
  onDragEnd,
  onDragStart,
  onDrop,
  onDragOver
}: ToothInfoBrickProps) {
  const baseClass = 'toothinfobrick';
  const [t] = useTranslation();

  const [isHovered, setIsHovered] = useState(false);

  const classes = classNames(baseClass, {
    ['toothinfobrick--nomovement']: !evolutionTooth?.isMoved && !evolutionTooth?.isPassiveAligner,
    ['toothinfobrick--missingtooth']: isMissingTooth || evolutionTooth?.isExtracted,
    ['toothinfobrick--activealigner']: evolutionTooth?.isMoved,
    ['toothinfobrick--passivealigner']: evolutionTooth?.isPassiveAligner,
    ['toothinfobrick--attachestart']: attachmentState === AttachmentState.Start,
    ['toothinfobrick--attacheend']: attachmentState === AttachmentState.End,
    ['toothinfobrick--bothattache']: attachmentState === AttachmentState.Both,
    ['toothinfobrick--grabable']: evolutionTooth?.isMoved && canDrag,
    ['toothinfobrick--overmovement']: isOverMovement
  });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
    }, 50);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classes}
        data-row={evolutionTooth?.fdi}
        data-column={columnIndex}
        draggable={classes.includes('toothinfobrick--grabable')}
        onDrag={(e) => onDrag && onDrag(e, evolutionTooth?.fdi, classes)}
        onDragEnd={(e) => onDragEnd && onDragEnd(e)}
        onDragStart={(e) => onDragStart && onDragStart(e, evolutionTooth?.fdi, classes)}
        onDrop={(e) => onDrop(e)}
        onDragOver={(e) => onDragOver && onDragOver(e, evolutionTooth?.fdi)}
      ></div>
      {isOverMovement && (
        <CustomTooltip text={t('evolutionPanel.info.overMovement')} position={tooltipPosition} show={isHovered} />
      )}
    </>
  );
}
