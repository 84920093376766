import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
import { injectable } from 'inversify';
import { INJECTED_TYPES, container } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import type { IErrorHandlerService } from '../errorHandling';
import { LoginResult } from '../login';
import { IHttpService } from './itthp.service';
import { isSaasApiEnabled } from '../settings';

const THROTTLE_TIME = 500;

@injectable()
export class HttpService implements IHttpService {
  private errorHandlerService: IErrorHandlerService;
  private client: AxiosInstance;
  private authToken: string;
  private publicAccessToken: string;

  protected createAxiosClient(): AxiosInstance {
    const axiosOptions: AxiosRequestConfig = {
      adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: THROTTLE_TIME })
    };

    return axios.create(axiosOptions);
  }

  constructor() {
    this.client = this.createAxiosClient();
    this.errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);
    this.addErrorHandlerInterceptor();
  }

  public get<TResponse>(path: string, queryParameters?: object): AxiosPromise<TResponse> {
    return this.client.get<TResponse>(path, queryParameters);
  }

  public post<TResponse>(path: string, data?: unknown): AxiosPromise<TResponse> {
    return this.client.post<TResponse>(path, data);
  }

  public delete<TResponse>(path: string): AxiosPromise<TResponse> {
    return this.client.delete<TResponse>(path);
  }

  public patch<TResponse>(path: string, data?: unknown): AxiosPromise<TResponse> {
    return this.client.patch<TResponse>(path, data, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    });
  }

  public put<TResponse>(path: string, data?: unknown): AxiosPromise<TResponse> {
    return this.client.put<TResponse>(path, data);
  }

  public setupAuth(token: string, isPublicAccessToken = false): void {
    if (!isPublicAccessToken) {
      this.authToken = token;
    } else {
      this.publicAccessToken = token;
    }
    this.addAuthInterceptor();
  }

  private addAuthInterceptor() {
    this.client.interceptors.request.use((request) => {
      if (sessionStorage.getItem('authToken')) {
        request.headers.Authorization = `Bearer ${this.authToken}`;
      } else {
        request.headers['X-Public-Access-Token'] = this.publicAccessToken;
      }
      return request;
    });
  }

  private addErrorHandlerInterceptor() {
    const settings = useCommonBoundStore.getState().settings;

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          // Uncomment when fixed authorization problem
          if (
            !isSaasApiEnabled() &&
            (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
          ) {
            const originalRequest = error.config;
            originalRequest._retry = true;
            const loginResult = (await this.client.get('/config/auth/login')).data as LoginResult;
            const authToken = loginResult.token;

            sessionStorage.setItem('authToken', authToken);
            this.authToken = authToken;
            return this.client.request(originalRequest);
          }

          sessionStorage.removeItem('authToken');
          sessionStorage.removeItem('publicAccessToken');
          window.location.replace(settings?.loginURL);
        }

        this.errorHandlerService.showError(error);

        return Promise.reject(error);
      }
    );
  }
}
