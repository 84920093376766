import { ReactComponent as labelsIcon } from '../../../assets/icons/evergineTools/tags.svg';
import { ToolbarConfigProps } from '../../../common';
import { OpenLabelsPanel } from './tools/evergineToolbar.helper';

export const labelsToolbarElement: ToolbarConfigProps = {
  icon: labelsIcon,
  tooltipKey: 'evergineTools.labels',
  customId: 'orthLabelsButton',
  hiddenForClient: true,
  handleClick: () => {
    OpenLabelsPanel();
  }
};
