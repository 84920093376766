import { useTranslation } from 'react-i18next';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { useCallback, useMemo, useState } from 'react';
import { ToolPanelDraggableContainer } from '../../ToolPanelDraggableContainer';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as Plus } from '../../../../../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../../../../../assets/icons/minus.svg';
import { OrthToolButtonSmall } from '../../teethMovements/orthToolButtonSmall';

import { TOOLS_GUM_EXTRUSION_BUTTON_ID } from '../../../stlEditionToolbarElements';
import { useGumExtrusion } from './useGumExtrusion';
import { Slider } from '@mui/material';
import { OperationType, StlEditionTarget } from '../stlEdition.types';
import { getIconForOrientation } from '../stlEdition.helper';
import { useBoundStore } from '../../../../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../../../../../common/stores/useStore';
import { ArchsToTreat } from '../../../../../../shared';

const PANEL_SIZE = 312;
const HANDLER_DRAG_ELEMENT_CLASS = 'handleGumExtrusion';

export type GumExtrusionToolsType = {
  titleKey: string;
};

const gumExtrusionTools: GumExtrusionToolsType[] = [
  {
    titleKey: 'stlEdition.gumExtrusion.minimumHeight'
  }
];

export function GumExtrusionTools() {
  const [t] = useTranslation();
  const setShowGumExtrusionPanel = useOrthBoundStore((state) => state.setShowGumExtrusionPanel);
  const archsToBeTreated = useCommonBoundStore((state) => state.archsToBeTreated);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);

  const {
    selectedOrientationTarget,
    onSelectOrientationTarget,
    getInputValue,
    getInputTextValue,
    onChangeInput,
    onChangeInputSlider,
    onInputKeyPress,
    onAddOrRemoveValueToInput,
    sendExtrusionValue,
    isModifyButtonDisabled
  } = useGumExtrusion();

  const toolPosition = useMemo(() => {
    return document.getElementById(TOOLS_GUM_EXTRUSION_BUTTON_ID)?.getBoundingClientRect();
  }, []);

  const getMovementTab = useCallback(
    (orientationTarget: StlEditionTarget) => {
      return (
        <button
          className={`orth-movements__button ${orientationTarget === selectedOrientationTarget ? 'is-active' : ''}`}
          onClick={() => {
            onSelectOrientationTarget(orientationTarget);
          }}
        >
          {getIconForOrientation(orientationTarget)}
        </button>
      );
    },
    [onSelectOrientationTarget, selectedOrientationTarget]
  );

  const InputNumberToolComponent = useCallback(
    (tool: GumExtrusionToolsType, index: number) => {
      const [t] = useTranslation();

      return (
        <div className="orth-movements__row" key={`s-${index}`}>
          <div className="orth-movements__label">{t(tool.titleKey)}</div>
          <input
            className="orth-movements__input"
            value={getInputTextValue()}
            onChange={(e) => onChangeInput(e.target.value)}
            onKeyPress={(e: any) => onInputKeyPress(e)}
            placeholder="0.0 mm"
            max={100}
            min={0}
            step={1}
          />

          <OrthToolButtonSmall Icon={Plus} onClick={() => onAddOrRemoveValueToInput(OperationType.ADD)} />
          <OrthToolButtonSmall Icon={Minus} onClick={() => onAddOrRemoveValueToInput(OperationType.SUBSTRACT)} />
        </div>
      );
    },
    [getInputTextValue]
  );

  const onClickModifyButton = async () => {
    setWebBusy(true);
    await sendExtrusionValue();
    setWebBusy(false);
  };

  return (
    <>
      {!!toolPosition && (
        <ToolPanelDraggableContainer
          panelSize={PANEL_SIZE}
          toolPosition={toolPosition}
          handlerElementClass={HANDLER_DRAG_ELEMENT_CLASS}
        >
          <div className="orth-movements">
            <div className="orth-movements__header">
              <div className={`orth-movements__title ${HANDLER_DRAG_ELEMENT_CLASS}`}>
                <div>{t('evergineTools.gumExtrusion')}</div>
              </div>

              <button type="button" className="orth-movements__close" onClick={() => setShowGumExtrusionPanel(false)}>
                <CloseIcon className="clickable" />
              </button>
            </div>
            <div className="orth-movements__separator" />
            {archsToBeTreated === ArchsToTreat.Both && (
              <div className="orth-movements__buttons-container">
                {getMovementTab(StlEditionTarget.Both)}
                {getMovementTab(StlEditionTarget.Upper)}
                {getMovementTab(StlEditionTarget.Lower)}
              </div>
            )}
            <div className="orth-movements__teeth-tool">
              <div className="orth-movements__content">
                {gumExtrusionTools.map((tool: GumExtrusionToolsType, index: number) =>
                  InputNumberToolComponent(tool, index)
                )}
                <div className="tools-item_slider horizontalpadding-1">
                  <Slider
                    value={getInputValue()}
                    onChange={(_, value) => onChangeInputSlider(value as number)}
                    min={0}
                    max={50}
                  />
                </div>
                <button
                  onClick={onClickModifyButton}
                  type="button"
                  className="btn btn-outline-primary flex-fill tadstools-content__delete"
                  disabled={isModifyButtonDisabled}
                >
                  {t('stlEdition.gumExtrusion.sendExtrusionValue')}
                </button>
              </div>
            </div>
          </div>
        </ToolPanelDraggableContainer>
      )}
    </>
  );
}
