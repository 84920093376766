import { useCallback } from 'react';
import { AsideConfigItem } from '../../surgeries/components/layout';
import { useSaveAndNavigateStep } from './useSaveAndNavigateStep';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

export function useNavigateToPhase(caseId: string) {
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });
  const { setNavigationRoute, setIsNavigationToNextPhase } = useOrthBoundStore();

  const navigateToPhase = useCallback(
    async (phaseItem: AsideConfigItem) => {
      const route = phaseItem.route + `/${caseId}`;
      setNavigationRoute(route);
      setIsNavigationToNextPhase(false);
      await saveAndNavigateStep(route);
    },
    [caseId, saveAndNavigateStep]
  );

  return { navigateToPhase };
}
