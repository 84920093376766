import { useLocation, useNavigate } from 'react-router-dom';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCalculateRoutes } from './useCalculateRoutes';
import { useCaseChanges } from './useCaseChanges';
import { useModalSaveChanges } from './useModalSaveChanges';
import { useNavigateAction } from './useNavigateAction';
import { useCaseId } from './useCaseId';
import { useCaseType } from './useCaseType';
import { useCustomNavigate } from './useCustomNavigate';
import { useCallback } from 'react';

export function useSaveAndNavigateStep({
  isDirectNavigation,
  checkPhaseChanges
}: {
  isDirectNavigation: boolean;
  checkPhaseChanges: boolean;
}) {
  const { resetOpacityLayers, hidePanelsEvergineTools, hideOcclusogram } = useNavigateAction();
  const { setIsConfirmed } = useBoundStore();
  const {
    isCaseModified,
    onChangeRoute,
    updateRouteToGo,
    onChangeTreatmentRouteHasToRecalculateIpr,
    onChangeTreatmentRouteHasToRecalculateAttaches,
    onChangeSTLCaptureCheckCaptures,
    onChangeSTLEditionCheckOrientationChanges,
    onChangeSTLEditionCheckGumsChanges
  } = useCaseChanges();
  const { pathname } = useLocation();
  const [caseId] = useCaseId();
  const caseType = useCaseType();
  const { getRouteWithVersionId } = useCustomNavigate();
  const [calculateNextRoute, calculatePreviousRoute] = useCalculateRoutes(pathname, caseId, caseType);
  const { getPhaseSaveChange } = useModalSaveChanges();
  const { saveChanges } = getPhaseSaveChange();
  const navigate = useNavigate();
  const { currentVersion } = useOrthBoundStore();
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const { setIprLabelSelected, setStageIsLoaded, setShowUpperArch, setShowLowerArch } = useOrthBoundStore();

  const saveAndNavigateStep = useCallback(
    async (
      nextRoute: string | null = null,
      isNextPhase = false,
      checkIpr = true,
      checkAttaches = true,
      checkSTLs = true,
      checkSTLOrientation = true,
      checkGumsExtrusion = true
    ) => {
      if (checkIpr && onChangeTreatmentRouteHasToRecalculateIpr()) return;
      if (checkAttaches && onChangeTreatmentRouteHasToRecalculateAttaches()) return;
      if (checkSTLs && onChangeSTLCaptureCheckCaptures()) return;
      if (checkSTLOrientation && onChangeSTLEditionCheckOrientationChanges()) return;
      if (checkGumsExtrusion && onChangeSTLEditionCheckGumsChanges()) return;
      setCanUndo(false);
      setIprLabelSelected(undefined);
      setShowUpperArch(true);
      setShowLowerArch(true);
      hideOcclusogram();
      hidePanelsEvergineTools();
      resetOpacityLayers();
      if (currentVersion === null) {
        return;
      }

      if (isDirectNavigation) {
        nextRoute = nextRoute ? nextRoute : isNextPhase ? calculateNextRoute() : calculatePreviousRoute();

        setStageIsLoaded(false);

        if (checkPhaseChanges) {
          if (isCaseModified) {
            updateRouteToGo(nextRoute);
            onChangeRoute();
            return;
          }
          const result = await saveChanges(isNextPhase);
          if (!result) {
            return;
          }
        }

        navigate(getRouteWithVersionId(nextRoute));
        return;
      }
      setIsConfirmed(true);
    },
    [
      calculateNextRoute,
      checkPhaseChanges,
      currentVersion,
      getRouteWithVersionId,
      hideOcclusogram,
      hidePanelsEvergineTools,
      isCaseModified,
      navigate,
      onChangeRoute,
      resetOpacityLayers,
      saveChanges,
      setCanUndo,
      setIprLabelSelected,
      setStageIsLoaded,
      updateRouteToGo,
      onChangeTreatmentRouteHasToRecalculateIpr,
      onChangeTreatmentRouteHasToRecalculateAttaches,
      onChangeSTLCaptureCheckCaptures,
      onChangeSTLEditionCheckOrientationChanges,
      onChangeSTLEditionCheckGumsChanges
    ]
  );

  return { saveAndNavigateStep };
}
