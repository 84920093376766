import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { AttachmentType } from '../../common/evergine/types';

export function useAttaches() {
  const [t] = useTranslation();

  const hoveredAttach = useOrthBoundStore((state) => state.hoveredAttach);
  const selectedTeethId = useOrthBoundStore((state) => state.selectedTeethId);
  const setAttachActive = useOrthBoundStore((state) => state.setAttachActive);
  const isAttacheSelected = useOrthBoundStore((state) => state.isAttacheSelected);

  const selectedToothId = selectedTeethId[0];

  const activeStep = useCommonBoundStore((state) => state.activeStep);
  const maxSteps = useCommonBoundStore((state) => state.maxSteps);

  const typeTooltip = useMemo(() => {
    if (!hoveredAttach) {
      return;
    }
    switch (hoveredAttach.type) {
      case AttachmentType.RotationRight:
        return t('attachesPanel.rotationOpRight');
      case AttachmentType.RotationLeft:
        return t('attachesPanel.rotationOpLeft');
      case AttachmentType.ExtrusionAnteriorConventional:
        return t('attachesPanel.extrusionConAnterior');
      case AttachmentType.ExtrusionAnteriorOptimized:
        return t('attachesPanel.extrusionOpAnterior');
      case AttachmentType.ExtrusionPosteriorOptimized:
        return t('attachesPanel.extrusionOpPosterior');
      case AttachmentType.RectangularHorizontalOptimized:
        return t('attachesPanel.rectangularCon');
      case AttachmentType.RectangularVerticalOptimized:
        return t('attachesPanel.rectangularOp');
      case AttachmentType.RectangularLeftBeveled:
        return t('attachesPanel.rectangularOpBevLeft');
      case AttachmentType.RectangularRightBeveled:
        return t('attachesPanel.rectangularOpBevRight');
      case AttachmentType.RadicularControlDoubleOne:
        return t('attachesPanel.rootControlDoubleLeft');
      case AttachmentType.RadicularControlDoubleTwo:
        return t('attachesPanel.rootControlDoubleRight');
      case AttachmentType.RadicularControlSimple:
        return t('attachesPanel.rootControlSimple');
      case AttachmentType.ControlRetentionOptimized:
        return t('attachesPanel.controlOpRetention');
      case AttachmentType.ControlSupportOptimized:
        return t('attachesPanel.controlOpSupport');
      case AttachmentType.ControlExpansionOptimized:
        return t('attachesPanel.controlOpExpansion');
      case AttachmentType.BiteLiftAnterior:
        return t('attachesPanel.biteRampAnterior');
      case AttachmentType.BiteLiftPosterior:
        return t('attachesPanel.biteRampPosterior');

      // TADS
      case AttachmentType.CutDistal:
        return t('tadsPanel.cutDistal');
      case AttachmentType.CutMesial:
        return t('tadsPanel.cutMesial');
      case AttachmentType.Button:
        return t('tadsPanel.button');

      default:
        return '';
    }
  }, [hoveredAttach]);

  const positionTooltip = useMemo(() => {
    if (!hoveredAttach) {
      return;
    }
    const scale = window.devicePixelRatio || 1;
    const evolutionPanelEl = document.querySelector('.evolutionpanel');
    const evolutionPanelWidth =
      !!evolutionPanelEl && evolutionPanelEl.classList.contains('opened')
        ? evolutionPanelEl.getBoundingClientRect().width +
          parseFloat(getComputedStyle(evolutionPanelEl, '::before').width)
        : 0;
    return {
      y: hoveredAttach.position.y / scale,
      x: hoveredAttach.position.x + evolutionPanelWidth / scale
    };
  }, [hoveredAttach]);

  const noToothSelected = useMemo(() => {
    if (!selectedToothId || selectedToothId === -1) {
      return true;
    }
    return false;
  }, [selectedToothId]);

  const inFirstOrLastStep = useMemo(() => {
    if (activeStep === 0 || activeStep === maxSteps - 1) {
      return true;
    }
    return false;
  }, [activeStep, maxSteps]);

  const noAttacheSelected = useMemo(() => {
    return !isAttacheSelected;
  }, [isAttacheSelected]);

  useEffect(() => {
    if (noToothSelected) {
      setAttachActive(null);
    }
  }, [noToothSelected]);

  return { typeTooltip, positionTooltip, noTeethSelected: noToothSelected, inFirstOrLastStep, noAttacheSelected };
}
