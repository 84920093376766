import { useTranslation } from 'react-i18next';
import { ReactComponent as Locked } from '../../../assets/icons/locked.svg';
import { SimpleMessageScreen } from '../simpleMessageScreen/SimpleMessageScreen';

export function IncompatibilityScreen() {
  const [t] = useTranslation();

  return (
    <SimpleMessageScreen
      message={t('incompatibility.title')}
      subMessage={t('incompatibility.description')}
      icon={Locked}
    />
  );
}
