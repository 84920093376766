import { ReactComponent as LabelStepInfo } from '../../../../../assets/icons/evergineTools/labels/info.svg';
import { AttachmentType } from '../../../../../common/evergine/types';

export const labelsTypes = [
  {
    id: 'stepNumber',
    typeAtt: AttachmentType.StepNumber,
    icon: LabelStepInfo,
    text: 'labelsPanel.step'
  }
];

export const attachmentsTypeLabel = [AttachmentType.StepNumber];

export const isLabelType = (type: AttachmentType) => attachmentsTypeLabel.includes(type);
