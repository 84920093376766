import { StateCreator } from 'zustand';
import { Settings } from '../../../shared/settings';

export interface SettingsSlice {
  settings: Settings;
  setSettings: (settings: Settings) => void;
  version: string;
  setVersion: (version: string) => void;
}
export const createSettingsSlice: StateCreator<SettingsSlice, [], [], SettingsSlice> = (set) => ({
  settings: null,
  setSettings: (settings: Settings) => set(() => ({ settings })),
  version: null,
  setVersion: (version: string) => set(() => ({ version }))
});
