import classNames from 'classnames';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconInfo } from '../../../assets/icons/info.svg';
import { ChatTab } from '../../../orthodontics/components/chatTab';
import { ChangesTab } from '../../../orthodontics/components/evergineToolbarElements/changesTab/ChangesTab';
import { FilesTab } from '../../../orthodontics/components/filesTab';
import { GeneralInfoTab } from '../../../orthodontics/components/generalInfoTab';
import { SpecificInfoTab } from '../../../orthodontics/components/specificInfoTab';
import { AbilityContext } from '../../../shared';
import { CustomTabs as Tabs } from '../tabs';
import { CustomTab } from '../tabs/CustomTab';

import './lateralPanel.scss';
import { isSaasApiEnabled } from '../../../shared/settings';

type PanelTabsType = {
  id: string;
  label: string;
  component: JSX.Element;
  isVisible: boolean;
};

interface LateralPanelProps {
  toggleShow: boolean;
  handleToggle: () => void;
}

export function LateralPanel({ toggleShow, handleToggle }: LateralPanelProps) {
  const [t] = useTranslation();
  const ability = useContext(AbilityContext);

  const lateralPanel = useRef<HTMLDivElement>();

  const classes = classNames('lateral-panel', {
    opened: toggleShow
  });

  const panelTabs = useMemo(
    (): PanelTabsType[] =>
      [
        {
          id: 'chat',
          label: 'Chat',
          component: <ChatTab />,
          isVisible: true
        },
        {
          id: 'info-general',
          label: t('lateralPanel.infoGeneral'),
          component: <GeneralInfoTab />,
          isVisible: true
        },
        {
          id: 'info-specific',
          label: t('lateralPanel.infoSpecific'),
          component: <SpecificInfoTab />,
          isVisible: !isSaasApiEnabled()
        },
        {
          id: 'files',
          label: t('lateralPanel.files'),
          component: <FilesTab slideParentAnchor={lateralPanel} />,
          isVisible: true
        },
        {
          id: 'changes',
          label: t('lateralPanel.changes'),
          component: <ChangesTab />,
          isVisible: true
        }
      ].filter((tab) => tab.isVisible),
    [t]
  );

  return (
    <div ref={lateralPanel} className={`${classes} no-select`}>
      <button type="button" className="lateral-panel-button clickable" onClick={handleToggle}>
        <IconInfo />
      </button>
      <div className="lateral-panel-content">
        <Tabs defaultActiveTabId="info-general">
          {panelTabs.map((tab) => (
            <CustomTab id={tab.id} label={tab.label} key={tab.id}>
              {tab.component}
            </CustomTab>
          ))}
        </Tabs>
      </div>
    </div>
  );
}
