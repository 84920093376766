/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INJECTED_TYPES, ToothTransformInfo, container } from '../../common';
import { HistoryItem, ItemModifications } from '../../orthodontics/models';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import {
  ClientFinalPosition,
  ClientPlanValidationEnum,
  IClientPlanValidationService,
  ToothMovementDTO
} from '../../shared';
import { HistoryTreatmentChange } from '../../shared/historyTreatmentChange/historyTreatmentChange';
import { IHistoryTreatmentChangeService } from '../../shared/historyTreatmentChange/ihistoryTreatmentChange.service';
import { RequestedChangeToShow } from '../../surgeries/components/layout';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useCaseId, useChat, useUserProfileData } from '../shared';
import { useTreatmentSuggestedChanges } from './useTreatmentSuggestedChanges';
import { useToothTransformStore } from '../../orthodontics/stores/useToothTransformStore';

export function useHistoryTreatmentChanges() {
  const [t] = useTranslation();
  const { currentVersion, setHistoryModifications, historyModifications } = useOrthBoundStore((state) => ({
    currentVersion: state.currentVersion,
    setHistoryModifications: state.setHistoryModifications,
    historyModifications: state.historyModifications
  }));
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const teethTransformDataList = useToothTransformStore((state) => state.teethTransformDataList);
  const [historyChanges, setHistoryChanges] = useState<HistoryItem[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [caseId] = useCaseId();
  const [userProfile] = useUserProfileData();
  const { sendChatMessage } = useChat(caseId);
  const { chatTextToSend } = useBoundStore();
  const { getTreatmentChangesToShow } = useTreatmentSuggestedChanges();
  const changesToShow = getTreatmentChangesToShow();

  useEffect(() => {
    setHistoryChanges(historyModifications);
  }, [historyModifications]);

  const getClientFinalPosition = () => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    const clientFinalPosition: ClientFinalPosition = {
      upper: {
        ...upperMovements.steps[upperMovements.steps.length - 1],
        isModifiedStepWithNoAutoRecalc: true
      },
      lower: {
        ...lowerMovements.steps[lowerMovements.steps.length - 1],
        isModifiedStepWithNoAutoRecalc: true
      }
    };
    return clientFinalPosition;
  };

  const sendClientPlanValidation = useCallback(
    async (requestedChangesText?: string) => {
      const clientPlanValidationService = container.get<IClientPlanValidationService>(
        INJECTED_TYPES.IClientPlanValidationService
      );

      if (teethTransformDataList.length > 0) {
        setWebBusy(true);
        const clientFinalPosition = getClientFinalPosition();
        await clientPlanValidationService.clientPlanValidation(caseId, currentVersion?.id, {
          clientId: userProfile?.id.toString(),
          result: ClientPlanValidationEnum.RequestedChanges,
          requestedMovements: parseRequestedMovementsList(),
          requestedChangesText: requestedChangesText ?? '',
          isClientModification: true,
          treatmentChangeItems: changesToShow.map((c: RequestedChangeToShow) => ({
            description: c.request,
            toothId: parseInt(c.pieceIdentifier)
          })),
          clientFinalPositionStep: clientFinalPosition
        });

        const description = chatTextToSend;
        const hasDescription = !!description.trim();
        sendChatMessage(ClientPlanValidationEnum.RequestedChanges, description, hasDescription);
        getHistoryTreatmentChanges();
        setWebBusy(false);
      }
    },
    [teethTransformDataList]
  );

  const parseRequestedMovementsList = useCallback(() => {
    const requestedMovements: ToothMovementDTO[] = teethTransformDataList.map((e: ToothTransformInfo) => ({
      toothId: Number(e.toothFdi),
      crown: {
        fdi: Number(e.toothFdi),
        extrusionIntrusion: e.extrusionIntrusion,
        translationVL: e.translationVL,
        translationMD: e.translationMD,
        pureRotation: e.pureRotation,
        torque: e.torque,
        tip: e.tip
      }
    }));
    return requestedMovements;
  }, [sendClientPlanValidation]);

  const getHistoryTreatmentChanges = useCallback(async () => {
    const historyService = container.get<IHistoryTreatmentChangeService>(INJECTED_TYPES.IHistoryTreatmentChangeService);
    const fetchedChatMessageList: HistoryTreatmentChange[] = await historyService.getTreatmentChanges(
      currentVersion!.caseId
    );
    if (fetchedChatMessageList.length > 0) {
      const historyItems: HistoryItem[] = fetchedChatMessageList.map((treatmentChange) => {
        const modifications: ItemModifications[] = treatmentChange.treatmentChangeItems.map((changeItem) => ({
          description: changeItem.description,
          piece: changeItem.toothId
        }));
        return {
          versionName: treatmentChange.caseVersionTitle || 'Initial',
          date: new Date(treatmentChange.createdAt).toLocaleString('es-es'),
          modifications
        };
      });
      setHistoryModifications(historyItems);
    } else {
      setHistoryModifications([]);
    }
  }, [currentVersion]);

  useEffect(() => {
    if (!isFirstLoad || !currentVersion) {
      return;
    }
    getHistoryTreatmentChanges();
    setIsFirstLoad(false);
  }, [isFirstLoad, getHistoryTreatmentChanges, currentVersion]);

  return { getHistoryTreatmentChanges, historyChanges, sendClientPlanValidation };
}
