import { useCommonBoundStore } from '../../common/stores/useStore';

export function useStepsManager() {
  const { setActiveStep } = useCommonBoundStore();

  const goToStep = (stepIndex: number, isComparer: boolean) => {
    window.App.webEventsProxy.movements.goToStep(stepIndex, isComparer);
    setActiveStep(stepIndex);
  };

  const goToTemplate = (stepIndex: number, isComparer: boolean) => {
    window.App.webEventsProxy.movements.goToTemplate(stepIndex, isComparer);
    setActiveStep(stepIndex);
  };

  return { goToStep, goToTemplate };
}
