import { useTranslation } from 'react-i18next';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { useModifyAttachesStep, OperationType } from './useModifyAttachesStep';
import './modifyAttachesStep.scss';
import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../stores/useStore';

interface ModifyAttachesLastStepProps {
  onClickOutside: () => void;
}

export function ModifyAttachesLastStep({ onClickOutside }: ModifyAttachesLastStepProps) {
  const [t] = useTranslation();
  const { modifyingAttach, setModifyingAttach, dropdownRef, evergineReady, updateMovements } = useModifyAttachesStep({
    onClickOutside
  });

  const { upperDentalMovements, lowerDentalMovements } = useOrthBoundStore((state) => ({
    upperDentalMovements: state.upperDentalMovements,
    lowerDentalMovements: state.lowerDentalMovements
  }));

  const modifyAttachStep = useCallback(
    (operationType: OperationType) => {
      if (!evergineReady || !modifyingAttach) return;
      const maxLength = Math.max(upperDentalMovements?.steps.length || 0, lowerDentalMovements?.steps.length || 0);
      if (modifyingAttach.lastStep === maxLength - 1 && operationType === OperationType.ADD) return;

      let newValue = modifyingAttach.lastStep;
      newValue += operationType === OperationType.ADD ? 1 : -1;
      if (newValue <= modifyingAttach.firstStep) return;

      window.App.webEventsProxy.attachments.updateAttachmentSteps(
        modifyingAttach.toothFdi,
        modifyingAttach.firstStep,
        newValue,
        modifyingAttach.lastStep
      );

      const newAttachData = { ...modifyingAttach, lastStep: newValue };
      setModifyingAttach(newAttachData);
      updateMovements();
    },
    [evergineReady, modifyingAttach]
  );

  return (
    <div className="attachesstep" ref={dropdownRef}>
      <div className="attachesstep-step">{t('contextMenuOptions.lastStep')}</div>
      <div className="attachesstep-operator" onClick={() => modifyAttachStep(OperationType.SUBSTRACT)}>
        <MinusIcon />
      </div>
      <div>Step&nbsp;{modifyingAttach?.lastStep ?? '--'}</div>
      <div className="attachesstep-operator" onClick={() => modifyAttachStep(OperationType.ADD)}>
        <PlusIcon />
      </div>
    </div>
  );
}
