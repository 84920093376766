import { useCallback, useEffect, useRef, useState } from 'react';
import { TooltipAttach } from '../../../../common';
import { useEvergineStore } from 'evergine-react';
import { useOrthBoundStore } from '../../../stores/useStore';
import { useUtils } from '../../../../hooks';

export enum OperationType {
  ADD,
  SUBSTRACT
}

interface UseModifyAttachesStepProps {
  onClickOutside: () => void;
}

export function useModifyAttachesStep({ onClickOutside }: UseModifyAttachesStepProps) {
  const { evergineReady } = useEvergineStore();
  const { selectedAttache } = useOrthBoundStore((state) => ({
    selectedAttache: state.selectedAttache
  }));
  const { updateMovements } = useUtils();
  const [modifyingAttach, setModifyingAttach] = useState<TooltipAttach | null>(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClickOutside();
      }
    },
    [onClickOutside, dropdownRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (selectedAttache !== null) {
      setModifyingAttach(selectedAttache);
    }
  }, [selectedAttache]);

  return {
    modifyingAttach,
    setModifyingAttach,
    dropdownRef,
    evergineReady,
    updateMovements
  };
}
