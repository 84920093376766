export enum StlEditionTarget {
  Upper = 0,
  Lower = 1,
  Both = 2
}

export enum OperationType {
  ADD,
  SUBSTRACT
}
