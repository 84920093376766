import {
  CaseVersion,
  IDentalMovementsService,
  ITeethSegmentationService,
  TreatmentPlanificationMovementsUpdateDTO
} from '../../shared';
import { CasePhase, ICaseStatusService } from '../../shared/caseStatus';
import { Segmentation } from '../evergine/types';
import { INJECTED_TYPES } from '../ioc';
import { container } from '../ioc/ioc.config';

export const updateTreatmentPlanificationMovements = async (
  caseId: string,
  currentVersion: CaseVersion,
  movements: TreatmentPlanificationMovementsUpdateDTO,
  segmentation: Segmentation = null
): Promise<void> => {
  try {
    const updateTreatmentPlanificationMovementsService = container.get<IDentalMovementsService>(
      INJECTED_TYPES.IDentalMovementsService
    );
    await updateTreatmentPlanificationMovementsService.updateTreatmentPlanificationMovements(
      caseId,
      currentVersion,
      movements
    );

    if (segmentation !== null && segmentation !== undefined) {
      const updateSegmentationService = container.get<ITeethSegmentationService>(
        INJECTED_TYPES.ITeethSegmentationService
      );
      await updateSegmentationService.updateSegmentation(caseId, currentVersion.id, segmentation);
    }
  } catch (error) {
    console.error('saveWithPersistence---error', error);
  }
};

export const editCasePhase = async (caseId: string, versionId: string, casePhase: CasePhase): Promise<void> => {
  try {
    const editCasePhaseService = container.get<ICaseStatusService>(INJECTED_TYPES.ICaseStatusService);
    await editCasePhaseService.editCaseStatusPhase(caseId, versionId, casePhase);
  } catch (error) {
    console.error('editCasePhase---error', error);
  }
};
