import './modalDeleteLabel.scss';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { ElementRemoveMode } from '../../../../../common';
import Radio from '../../../../../common/components/radio/Radio';
import { useCommonBoundStore } from '../../../../../common/stores/useStore';
import { useUtils } from '../../../../../hooks/shared/useUtils';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

export function ModalDeleteLabel() {
  const [t] = useTranslation();
  const toggleModalIsOpened = useCommonBoundStore((state) => state.toggleModalIsOpened);
  const { selectedTeethId, selectedLabelId } = useOrthBoundStore((state) => ({
    selectedTeethId: state.selectedTeethId,
    selectedLabelId: state.selectedLabelId
  }));
  const { updateMovements } = useUtils();
  const [selectedOption, setSelectedOption] = useState<number>(ElementRemoveMode.RemoveInAllSteps);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  const handleContinue = () => {
    if (selectedTeethId.length > 0) {
      selectedTeethId.forEach((id) => {
        window.App.webEventsProxy.attachments.removeAttachment(id, selectedOption);
      });
    } else if (selectedLabelId) {
      window.App.webEventsProxy.attachments.removeAttachment(selectedTeethId[0], selectedOption);
    }

    updateMovements();
    toggleModalIsOpened('');
  };

  const handleSelected = (selectedOption: number) => {
    setSelectedOption(selectedOption);
  };

  const isActive = (option: number) => {
    return selectedOption === option;
  };

  return (
    <div className="modal-content modal-delete-label" data-testid={'modal-deleteLabel'}>
      <div className="modal-header modal-delete-label">
        <Info className="modal-icon" />
        <div className="modal-title">{t(`modal.deleteLabel.title`)}</div>
      </div>
      <div className="modal-radios modal-delete-label">
        <Radio
          label={t(`modal.delete.options.deleteInAllSteps`)}
          active={isActive(ElementRemoveMode.RemoveInAllSteps)}
          callBack={() => handleSelected(ElementRemoveMode.RemoveInAllSteps)}
          expanded={false}
        />
        <Radio
          label={t(`modal.delete.options.deleteFromActualStepToLast`)}
          active={isActive(ElementRemoveMode.RemoveFromCurrentStepToEnd)}
          callBack={() => handleSelected(ElementRemoveMode.RemoveFromCurrentStepToEnd)}
          expanded={false}
        />
        <Radio
          label={t(`modal.delete.options.deleteFromActualStepToInitial`)}
          active={isActive(ElementRemoveMode.RemoveFromCurrentStepToStart)}
          callBack={() => handleSelected(ElementRemoveMode.RemoveFromCurrentStepToStart)}
          expanded={false}
        />
      </div>

      <div className="modal-buttons modal-delete-label">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button
          onClick={handleContinue}
          disabled={selectedOption === null}
          type="button"
          className="btn btn-primary flex-fill modal-button"
        >
          {t(`modal.actions.delete`)}
        </button>
      </div>
    </div>
  );
}
