import React from 'react';
import { ReactComponent as BothMaxillaIcon } from '../../../../../assets/icons/jaws.svg';
import { ReactComponent as UpperMaxillaIcon } from '../../../../../assets/icons/jaw-top.svg';
import { ReactComponent as LowerMaxillaIcon } from '../../../../../assets/icons/jaw-bottom.svg';
import { StlEditionTarget } from './stlEdition.types';
import { CapturePosition } from '../../../../../common';

export const getIconForOrientation = (orientationTarget: StlEditionTarget) => {
  if (orientationTarget === StlEditionTarget.Lower) return React.createElement(LowerMaxillaIcon);
  if (orientationTarget === StlEditionTarget.Upper) return React.createElement(UpperMaxillaIcon);
  return React.createElement(BothMaxillaIcon);
};

export const mapCapturePositionFrom = (orientationTarget: StlEditionTarget) => {
  const mapping: Record<StlEditionTarget, CapturePosition> = {
    [StlEditionTarget.Upper]: CapturePosition.UPPER,
    [StlEditionTarget.Lower]: CapturePosition.LOWER,
    [StlEditionTarget.Both]: CapturePosition.BOTH
  };

  return mapping[orientationTarget] ?? CapturePosition.NONE;
};
