import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap/esm/Button';

import { ReactComponent as ConfigurationIcon } from '../../../assets/icons/configuration.svg';
import InformationIcon from '../../../assets/icons/question.svg';

import {
  ConfigLanguageSelector,
  useConfigLanguage,
  ConfigCaseIdentifierSelector,
  useConfigCaseIdentifier
} from './configurationSelectors';

import './navBarConfiguration.scss';
import { useCommonBoundStore } from '../../stores/useStore';

export default () => {
  const [t] = useTranslation();
  const { localCurrentLanguage, changeLocalLanguage, saveLanguageConfiguration } = useConfigLanguage();
  const { localCurrentCaseIdentifier, changeLocalCaseIdentifier, saveCaseIdentifierConfiguration } =
    useConfigCaseIdentifier();

  const [showPanel, setShowPanel] = useState<boolean>(false);

  const { version } = useCommonBoundStore((state) => ({
    version: state.version
  }));

  const saveConfiguration = () => () => {
    setShowPanel(!showPanel);
    saveLanguageConfiguration();
    saveCaseIdentifierConfiguration();
  };

  const toggleShowPanel = () => () => {
    setShowPanel(!showPanel);
  };

  return (
    <div className="configuration">
      <button onClick={toggleShowPanel()} className={`btn btn-action configuration-btn`} data-tip>
        <ConfigurationIcon className="ml-3" />
      </button>
      {showPanel && (
        <div className="configuration-panel no-select">
          <div>
            <div className="configuration-header">
              <div className="configuration-title">
                {t('navbar.actions.configuration.title')}
                <span>
                  <img
                    data-for="configuration"
                    data-tip
                    alt={t('navbar.actions.configuration.title')}
                    src={InformationIcon}
                    className="ms-3"
                  />
                  <ReactTooltip id="configuration" place="right" effect="solid" className="tooltip" type="dark">
                    <span>{t('navbar.actions.configuration.title')}</span>
                  </ReactTooltip>
                </span>
              </div>
              <div className="configuration-header-version">
                <span>v{version}</span>
              </div>
            </div>
            <ConfigLanguageSelector
              localCurrentLanguage={localCurrentLanguage}
              changeLocalLanguage={changeLocalLanguage}
            />
            <ConfigCaseIdentifierSelector
              changeLocalCaseIdentifier={changeLocalCaseIdentifier}
              localCurrentCaseIdentifier={localCurrentCaseIdentifier}
            />
          </div>
          <div className="configuration-btn_container">
            <Button onClick={saveConfiguration()} className={`btn configuration-btn`}>
              {t('navbar.actions.configuration.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
