import { useCallback } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { SaveChangesHook } from '../../shared';
import { useSaveProgress } from '../useSaveProgress';
import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';

export function useSavePublish(): SaveChangesHook {
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const { setUpperAndLowerDentalMovements } = useOrthBoundStore((state) => ({
    setUpperAndLowerDentalMovements: state.setUpperAndLowerDentalMovements
  }));
  const { saveProgressInBackend } = useSaveProgress();

  const saveChanges = useCallback(async (): Promise<boolean> => {
    setWebBusy(true);

    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

    setUpperAndLowerDentalMovements(upperMovements, lowerMovements);

    await saveProgressInBackend(
      { lower: lowerMovements, upper: upperMovements },
      OrthodonticsOrderedPhasesKeys.Treatment
    );

    setWebBusy(false);
    return Promise.resolve(true);
  }, [setUpperAndLowerDentalMovements, saveProgressInBackend]);

  return { saveChanges };
}
