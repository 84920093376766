import { useMemo, useState } from 'react';
import { Key } from 'ts-key-enum';
import { useOrthBoundStore } from '../../../../../stores/useStore';
import { OperationType, StlEditionTarget } from '../stlEdition.types';
import { mapCapturePositionFrom } from '../stlEdition.helper';
import { FileCaptureType } from '../../../../../../shared';
import { useBoundStore } from '../../../../../../surgeries/stores/useStore';
import { debouncedSetState } from '../../../../../../common/utils';

const MOVEMENTS_DECIMALS = 2;
const MM_STEP_NUMBER = 0.1;
const VALID_NUMBER_REGEX = /^[+-]?\d+(\.\d+)?$/;

type GumExtrusionInputValue = {
  [key in StlEditionTarget]: number;
};

type GumExtrusionInputTextValue = {
  [key in StlEditionTarget]: string;
};

const initialGumExtrusionValues: GumExtrusionInputValue = {
  [StlEditionTarget.Both]: 0,
  [StlEditionTarget.Lower]: 0,
  [StlEditionTarget.Upper]: 0
};

const initialGumExtrusionInputTextValues: GumExtrusionInputTextValue = {
  [StlEditionTarget.Both]: '0.00',
  [StlEditionTarget.Lower]: '0.00',
  [StlEditionTarget.Upper]: '0.00'
};

const dynamicModelsFolderPath = `/Content/DynamicModels`;
const upperStlFilePath = `${dynamicModelsFolderPath}/${FileCaptureType.UPPER_STL}.stl`;
const lowerStlFilePath = `${dynamicModelsFolderPath}/${FileCaptureType.LOWER_STL}.stl`;

export function useGumExtrusion() {
  const setHaveGumsBeenExtruded = useOrthBoundStore((state) => state.setHaveGumsBeenExtruded);
  const haveGumsBeenExtruded = useOrthBoundStore((state) => state.haveGumsBeenExtruded);
  const [gumExtrusionValues, setGumExtrusionValues] = useState<GumExtrusionInputValue>(initialGumExtrusionValues);
  const [gumExtrusionInputTextValues, setGumExtrusionInputTextValues] = useState<GumExtrusionInputTextValue>(
    initialGumExtrusionInputTextValues
  );
  const [selectedOrientationTarget, setSelectedOrientationTarget] = useState<StlEditionTarget>(StlEditionTarget.Both);
  const [isEvergineExtruding, setIsEvergineExtruding] = useState(false);

  const isModifyButtonDisabled = useMemo(() => {
    const value = gumExtrusionValues[selectedOrientationTarget];
    return value === 0 || isEvergineExtruding;
  }, [selectedOrientationTarget, gumExtrusionValues, isEvergineExtruding]);

  const getInputValue = (): number => {
    const value = gumExtrusionValues[selectedOrientationTarget];
    return value;
  };

  const getInputTextValue = () => {
    const value = gumExtrusionInputTextValues[selectedOrientationTarget];
    return value;
  };

  const saveInputValue = (value: any) => {
    setGumExtrusionValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: value
    }));

    if (!haveGumsBeenExtruded) {
      setHaveGumsBeenExtruded(true);
    }
  };

  const onChangeInputSlider = (value: number) => {
    saveInputValue(value);
    setGumExtrusionInputTextValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: value.toFixed(MOVEMENTS_DECIMALS)
    }));
  };

  const onChangeInput = (value: string) => {
    const isNegativeSign = value.startsWith('-');
    if ((value.length === 1 && isNegativeSign) || value.length === 0 || !isNaN(Number(value))) {
      const dotPosition = value.indexOf('.');
      const valueToSave =
        value.length > 4 && dotPosition > 0 && VALID_NUMBER_REGEX.test(value)
          ? Number(value).toFixed(MOVEMENTS_DECIMALS)
          : value;
      setGumExtrusionInputTextValues((prevState) => ({
        ...prevState,
        [selectedOrientationTarget]: valueToSave
      }));
      return;
    }

    if (!VALID_NUMBER_REGEX.test(value)) {
      return;
    }

    saveInputValue(value);
    setGumExtrusionInputTextValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: value
    }));
  };

  const onInputKeyPress = (e: any) => {
    if (e.key !== Key.Enter || !VALID_NUMBER_REGEX.test(e.target.value)) {
      return;
    }

    const value = gumExtrusionInputTextValues[selectedOrientationTarget];
    const parsedNumber = Math.round(parseFloat(value) * 1e12) / 1e12;
    saveInputValue(parsedNumber);
    setGumExtrusionInputTextValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: parsedNumber.toFixed(MOVEMENTS_DECIMALS).toString()
    }));
  };

  const onAddOrRemoveValueToInput = (operation: OperationType) => {
    const value = gumExtrusionValues[selectedOrientationTarget];

    let newValue = value || 0;
    if (operation === OperationType.ADD) {
      newValue += MM_STEP_NUMBER;
    } else {
      newValue -= MM_STEP_NUMBER;
    }

    if (newValue < 0) {
      return;
    }

    saveInputValue(newValue);
    setGumExtrusionInputTextValues((prevState) => ({
      ...prevState,
      [selectedOrientationTarget]: newValue.toFixed(MOVEMENTS_DECIMALS).toString()
    }));
  };

  const onSelectOrientationTarget = (orientationTarget: StlEditionTarget) => {
    setSelectedOrientationTarget(orientationTarget);
    window.App.webEventsProxy.modelTransformation.sendSelectedArchToTransform(
      mapCapturePositionFrom(orientationTarget)
    );
  };

  const sendExtrusionValue = async () => {
    setIsEvergineExtruding(true);
    const value = gumExtrusionValues[selectedOrientationTarget];
    await window.App.webEventsProxy.modelTransformation.setGumExtrusion(
      mapCapturePositionFrom(selectedOrientationTarget),
      value,
      upperStlFilePath,
      lowerStlFilePath
    );
    setIsEvergineExtruding(false);
  };

  return {
    selectedOrientationTarget,
    onSelectOrientationTarget,
    getInputValue,
    getInputTextValue,
    onChangeInput,
    onChangeInputSlider,
    onInputKeyPress,
    onAddOrRemoveValueToInput,
    sendExtrusionValue,
    isModifyButtonDisabled
  };
}
