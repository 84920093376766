import classNames from 'classnames';

import { ReactComponent as RadioOffIcon } from '../../../assets/icons/radio-off.svg';
import { ReactComponent as RadioOnIcon } from '../../../assets/icons/radio-on.svg';

import './radio.scss';

export type RadioProps = {
  label?: string;
  active?: boolean;
  readOnly?: boolean;
  expanded?: boolean;
  labelOnRight?: boolean;
  callBack?: () => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
};

export default function Radio({
  label,
  active,
  readOnly,
  expanded = true,
  labelOnRight = true,
  callBack,
  onClick
}: RadioProps) {
  const classes = classNames('radio-button', {
    expanded
  });

  // Handle clicks only if they are not from the input
  const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    if (e.target instanceof HTMLInputElement) {
      e.stopPropagation(); // Prevent the input click from bubbling up to the label
    } else {
      onClick?.(e); // Only call onClick if it is from the label, not the input
    }
  };

  return (
    <label className={classes} onClick={handleLabelClick}>
      {!labelOnRight && label && <span className="radio-button-label">{label}</span>}
      <span className="radio-button-icons">
        <input type="radio" checked={active} onChange={callBack} readOnly={readOnly} />
        <span className="radio-button-icon radio-button-on">
          <RadioOnIcon />
        </span>
        <span className="radio-button-icon radio-button-off">
          <RadioOffIcon />
        </span>
      </span>
      {labelOnRight && label && <span className="radio-button-label">{label}</span>}
    </label>
  );
}
