import { useCallback, useMemo } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useAttaches, useUtils } from '../../shared';
import { useCommonBoundStore } from '../../../common/stores/useStore';

export function useAttachesManager() {
  const hoveredAttach = useOrthBoundStore((state) => state.hoveredAttach);
  const areAttachesSet = useOrthBoundStore((state) => state.areAttachesSet);
  const setAreAttachesSet = useOrthBoundStore((state) => state.setAreAttachesSet);
  const caseStatusFromStore = useOrthBoundStore((state) => state.caseStatus);
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);

  const { fixFloat, getCurrentPhaseStageByRoute } = useUtils();
  const { typeTooltip, positionTooltip } = useAttaches();

  const thicknessTextTooltip = useMemo(() => {
    const thicknessValue =
      hoveredAttach?.thickness.currentThickness !== undefined
        ? `${fixFloat(hoveredAttach?.thickness.currentThickness)}`
        : '--';
    return `${typeTooltip} ${thicknessValue} mm`;
  }, [hoveredAttach]);

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const hasPhaseCompletionDate = useCallback((): boolean => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (currentPhase === undefined) {
      return false;
    }
    const completionDate = getPhaseCompletionDate(currentPhase);

    return completionDate !== '';
  }, [getPhaseCompletionDate]);

  const checkIfAttachesAreSet = (): boolean => {
    return areAttachesSet || (areAttachesSet == null && hasPhaseCompletionDate() && !isCaseReseting);
  };

  return {
    positionTooltip,
    thicknessTextTooltip,
    hoveredAttach,
    areAttachesSet,
    setAreAttachesSet,
    checkIfAttachesAreSet
  };
}
