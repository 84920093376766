import Draggable from 'react-draggable';

interface ToolPanelDraggableContainerProps {
  handlerElementClass: string;
  panelSize?: number;
  toolPosition?: DOMRect;
  children: React.ReactNode;
}

export function ToolPanelDraggableContainer(props: ToolPanelDraggableContainerProps) {
  const { toolPosition, panelSize, handlerElementClass, children } = props;

  const pixelRatio = window.devicePixelRatio || 1;
  // Max scale 0.8 to avoid scaling the toolbar too much
  const scale = Math.max(0.8, 1 / pixelRatio);

  const transformStyle: React.CSSProperties = {
    transform: `scale(${scale})`
  };

  if (toolPosition && panelSize) {
    transformStyle.transform = `translate(${toolPosition.x - panelSize * scale}px, ${
      toolPosition.top * scale
    }px) scale(${scale})`;
    transformStyle.transformOrigin = 'top left';
  }

  return (
    <div style={transformStyle}>
      <Draggable handle={`.${handlerElementClass}`}>{children}</Draggable>
    </div>
  );
}
