export enum PROFILES_TYPE {
  client = 'client',
  employee = 'employee',
  dev = 'dev'
}

export enum SAAS_PROFILES_TYPE {
  client = 1,
  administrator = 2
}

export const CLIENT_ROLE_ON_BACKEND = 'ROLE_USER';

export type UserProfileType = PROFILES_TYPE.client | PROFILES_TYPE.employee | PROFILES_TYPE.dev;
export type SaaSUserProfileType = SAAS_PROFILES_TYPE.client | SAAS_PROFILES_TYPE.administrator;

export type UserProfileData = {
  id: string;
  type: UserProfileType;
  name: string;
  surname: string;
};

export type UserInfo = {
  id: string;
  email: string;
};

export type UserProfile = {
  id: string;
  stringId?: string;
  firstName: string;
  lastNames: string;
  email: string;
  type: UserProfileType;
  canPublish?: boolean;
  canDownloadSTL?: boolean;
};

export type UserProfileDTO = {
  data: UserProfile;
};

export type SaaSUserProfileDTO = {
  id: string;
  stringId: string;
  name: string;
  firstName: string;
  lastNames: string;
  email: string;
  type: SaaSUserProfileType;
  canPublish?: boolean;
  canDownloadSTL?: boolean;
};
