import { Stage } from '../../../../common/evergine/types';
import { AsideConfig, StepState } from '../../../../surgeries/components/layout/aside';

export enum OrthodonticsOrderedRoutes {
  StlLoad = '/orthodontics/stl-captures',
  StlEdition = '/orthodontics/stl-edition',
  TeethSegmentation = '/orthodontics/teeth-segmentation',
  AxisAndRoots = '/orthodontics/axis-roots',
  Treatment = '/orthodontics/treatment',
  Publish = '/orthodontics/publish'
}

export enum OrthodonticsOrderedPhasesKeys {
  StlLoad = 'stl_load',
  StlEdition = 'stl_edition',
  TeethSegmentation = 'teeth_segmentation',
  AxisAndRoots = 'axis_and_roots',
  Treatment = 'treatment',
  Publish = 'publish'
}

export const PhaseByUrl: Record<string, OrthodonticsOrderedPhasesKeys> = {
  'stl-captures': OrthodonticsOrderedPhasesKeys.StlLoad,
  'stl-edition': OrthodonticsOrderedPhasesKeys.StlEdition,
  'teeth-segmentation': OrthodonticsOrderedPhasesKeys.TeethSegmentation,
  'axis-roots': OrthodonticsOrderedPhasesKeys.AxisAndRoots,
  treatment: OrthodonticsOrderedPhasesKeys.Treatment,
  publish: OrthodonticsOrderedPhasesKeys.Publish
};

export const EvergineStageByPhase: Record<string, Stage> = {
  [OrthodonticsOrderedPhasesKeys.StlLoad]: Stage.LoadSTL,
  [OrthodonticsOrderedPhasesKeys.StlEdition]: Stage.TeethSegmentation, // TODO: cambiar por la correspondiente a StlEdition cuando esté disponible
  [OrthodonticsOrderedPhasesKeys.TeethSegmentation]: Stage.TeethSegmentation,
  [OrthodonticsOrderedPhasesKeys.AxisAndRoots]: Stage.AxisAndRoots,
  [OrthodonticsOrderedPhasesKeys.Treatment]: Stage.Treatment,
  [OrthodonticsOrderedPhasesKeys.Publish]: Stage.Publish
};

export const orthodonticsAsideConfig: AsideConfig[] = [
  {
    title: 'aside.orthodontics.images.title',
    items: [
      {
        title: 'aside.orthodontics.images.items.STLLoad',
        route: OrthodonticsOrderedRoutes.StlLoad,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.StlLoad
      },
      {
        title: 'aside.orthodontics.images.items.STLEdition',
        route: OrthodonticsOrderedRoutes.StlEdition,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.StlEdition
      }
    ]
  },
  {
    title: 'aside.orthodontics.preparation.title',
    items: [
      {
        title: 'aside.orthodontics.preparation.items.teethSegmentation',
        route: OrthodonticsOrderedRoutes.TeethSegmentation,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.TeethSegmentation
      },
      {
        title: 'aside.orthodontics.preparation.items.axisAndRoots',
        route: OrthodonticsOrderedRoutes.AxisAndRoots,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.AxisAndRoots
      }
    ]
  },
  {
    title: 'aside.orthodontics.treatment.title',
    items: [
      {
        title: 'aside.orthodontics.treatment.items.treatment',
        route: OrthodonticsOrderedRoutes.Treatment,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.Treatment
      }
    ]
  },
  {
    title: 'aside.orthodontics.production.title',
    items: [
      {
        title: 'aside.orthodontics.production.items.publish',
        route: OrthodonticsOrderedRoutes.Publish,
        defaultState: StepState.Pending,
        key: OrthodonticsOrderedPhasesKeys.Publish
      }
    ]
  }
];
