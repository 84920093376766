import { useCallback } from 'react';
import { CapturePosition, INJECTED_TYPES, container } from '../../common';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { FileCaptureType, IFileService } from '../../shared';
import { useBase64Converter } from '../useBase64Converter';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { useFetchModelFiles } from './useFetchModelFiles';

export function useGetFiles(caseId: string) {
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);
  const dynamicModels = 'DynamicModels';
  const dynamicModelsFullPath = `/Content/${dynamicModels}`;
  const { lowerModel3DId, upperModel3DId } = useCommonBoundStore();
  const { getBase64FromArrayBuffer } = useBase64Converter();
  const { currentVersion } = useOrthBoundStore();
  const { fetchNonCachedModelFile } = useFetchModelFiles();

  const getFileInfo = async (fileKey: string, versionId: string) => {
    const file = await fileService.getFileInfo(caseId, versionId, fileKey);
    return file;
  };

  const getFile = async (key: string, versionId: string) => {
    const fileInfo = await getFileInfo(key, versionId);

    if (!fileInfo || !fileInfo.url) {
      return;
    }

    return await fetchNonCachedModelFile(fileInfo.url);
  };

  const getSTLFileFromFS = useCallback(
    async (teethArch: CapturePosition) => {
      if (!currentVersion) {
        return;
      }

      const modelName =
        teethArch == CapturePosition.UPPER
          ? `${FileCaptureType.UPPER_STL_EDITED}.stl`
          : `${FileCaptureType.LOWER_STL_EDITED}.stl`;

      const fileName = `${modelName}`;
      let fileFSPath = `${dynamicModelsFullPath}/${fileName}`;
      if (!Module.FS.isFile(fileFSPath)) {
        // try with the original non-edited STL
        fileFSPath = `${dynamicModelsFullPath}/${
          teethArch == CapturePosition.UPPER ? `${FileCaptureType.UPPER_STL}.stl` : `${FileCaptureType.LOWER_STL}.stl`
        }`;
        if (!Module.FS.isFile(fileFSPath)) {
          return null;
        }
      }

      const fileInFS = Module.FS.readFile(fileFSPath);
      const base64content = getBase64FromArrayBuffer(fileInFS);

      return base64content;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const getBase64ContentFromFileInBackend = async (url: string) => {
    const resFile = await fetchNonCachedModelFile(url);
    const arrayBufferData = await resFile.arrayBuffer();
    const base64content = getBase64FromArrayBuffer(arrayBufferData);
    return base64content;
  };

  const getSTLFileFromBackend = useCallback(
    async (teethArch: CapturePosition) => {
      if (!currentVersion) {
        return;
      }

      const editedModelKey =
        teethArch == CapturePosition.UPPER ? FileCaptureType.UPPER_STL_EDITED : FileCaptureType.LOWER_STL_EDITED;

      const editedFileInfo = await getFileInfo(editedModelKey, currentVersion.id);
      if (!!editedFileInfo && !!editedFileInfo.url) {
        return getBase64ContentFromFileInBackend(editedFileInfo.url);
      }

      const originalModelKey =
        teethArch == CapturePosition.UPPER ? FileCaptureType.UPPER_STL : FileCaptureType.LOWER_STL;

      const originalFileInfo = await getFileInfo(originalModelKey, currentVersion.id);
      if (!!originalFileInfo && !!originalFileInfo.url) {
        return getBase64ContentFromFileInBackend(originalFileInfo.url);
      }

      return null;
    },
    [upperModel3DId, lowerModel3DId, currentVersion]
  );

  const getSTLFile = useCallback(
    async (teethArch: CapturePosition) => {
      if (teethArch === CapturePosition.UPPER && !upperModel3DId) {
        return null;
      }

      if (teethArch === CapturePosition.LOWER && !lowerModel3DId) {
        return null;
      }

      const fileFromFS = await getSTLFileFromFS(teethArch);
      if (fileFromFS !== null) {
        return fileFromFS;
      }

      const fileFromBackend = await getSTLFileFromBackend(teethArch);
      return fileFromBackend;
    },
    [upperModel3DId, lowerModel3DId, currentVersion, getSTLFileFromBackend, getSTLFileFromFS]
  );

  return {
    getFileInfo,
    getFile,
    getSTLFile
  };
}
