import { useEffect, useState } from 'react';
import { SlideShowBackdrop } from '../../../surgeries/components/captures/photoCaptures/slideShowBackdrop';

interface FileItemProps {
  label: string;
  src: string;
  onClickBackdrop: () => void;
  handleImageError: () => void;
}

export function FileItem(props: FileItemProps) {
  const { label, src, onClickBackdrop, handleImageError } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [imageError, setImageError] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      let headers: HeadersInit = {};
      if (sessionStorage.getItem('authToken')) {
        headers = {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        };
      } else {
        headers = {
          'X-Public-Access-Token': sessionStorage.getItem('publicAccessToken')
        };
      }

      fetch(src, {
        headers: headers
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error loading image');
          }
          return response.blob();
        })
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);
        })
        .catch((error) => {
          setImageError(true);
          handleImageError();
        });
    }
  }, [src, handleImageError]);

  const handleError = () => {
    setImageError(true);
    handleImageError();
  };

  return (
    <>
      <p>{label}</p>
      {!imageError && imageSrc && (
        <div
          className="file-item"
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          onClick={onClickBackdrop}
        >
          <SlideShowBackdrop open={open}>
            <img className="file-item-image" src={imageSrc} alt={label} onError={handleError} />
          </SlideShowBackdrop>
        </div>
      )}
    </>
  );
}
