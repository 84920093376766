import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Vector2 } from '../../evergine';
import './customTooltip.scss';

type CustomTooltipProps = {
  text: string;
  position: Vector2;
  show?: boolean;
};

export const CustomTooltip = ({ position, text, show = true }: CustomTooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState<CSSProperties>({
    visibility: 'hidden',
    position: 'absolute'
  });

  useEffect(() => {
    const calculatePositionAndWidth = () => {
      if (tooltipRef.current) {
        const width = tooltipRef.current.getBoundingClientRect().width;
        const middleWidth = width / 2;
        const calculateY = position.y + 60;
        const calculateX = position.x - middleWidth;

        setStyles({
          bottom: `${calculateY}px`,
          left: `${calculateX}px`,
          position: 'absolute',
          visibility: show ? 'visible' : 'hidden'
        });
      }
    };

    calculatePositionAndWidth();
  }, [show, position]);

  return (
    <div ref={tooltipRef} className="tooltip-custom" style={styles}>
      <span>{text}</span>
    </div>
  );
};
