import { ReactComponent as RotateCenterIcon } from '../../../assets/icons/evergineTools/rotate-center.svg';
import { ReactComponent as GumExtrusionIcon } from '../../../assets/icons/evergineTools/gum-extrusion.svg';
import { ToolbarConfigProps } from '../../../common';
import { debouncedSetState } from '../../../common/utils';
import { useOrthBoundStore } from '../../stores/useStore';

export const TOOLS_STL_ORIENTATION_BUTTON_ID = 'stlOrientationButton';
export const stlOrientationToolbarElement: ToolbarConfigProps = {
  icon: RotateCenterIcon,
  tooltipKey: 'evergineTools.stlPosition',
  labelKey: 'evergineTools.stlPosition',
  customId: TOOLS_STL_ORIENTATION_BUTTON_ID,
  handleClick: () => {
    const isPanelOpened = useOrthBoundStore.getState().showStlOrientationPanel;
    const baseToggleState = { showStlOrientationPanel: !isPanelOpened };
    const toggleState = isPanelOpened ? baseToggleState : { ...baseToggleState, showGumExtrusionPanel: false };

    debouncedSetState(useOrthBoundStore, toggleState);
  }
};

export const TOOLS_GUM_EXTRUSION_BUTTON_ID = 'gumExtrusionButton';
export const gumExtrusionToolbarElement: ToolbarConfigProps = {
  icon: GumExtrusionIcon,
  tooltipKey: 'evergineTools.gumExtrusion',
  labelKey: 'evergineTools.gumExtrusion',
  customId: TOOLS_GUM_EXTRUSION_BUTTON_ID,
  handleClick: () => {
    const isPanelOpened = useOrthBoundStore.getState().showGumExtrusionPanel;
    const baseToggleState = { showGumExtrusionPanel: !isPanelOpened };

    const toggleState = isPanelOpened ? baseToggleState : { ...baseToggleState, showStlOrientationPanel: false };

    debouncedSetState(useOrthBoundStore, toggleState);
  }
};
