import { useTranslation } from 'react-i18next';
import { ClinicDataCard } from '../../../common/components/generalInfo/ClinicDataCard';
import { PatientDataCard } from '../../../common/components/generalInfo/PatientDataCard';
import { DataCardBase } from '../../../surgeries/components';
import { OdontogramMini } from '../../../surgeries/components/odontogramMini';
import './generalInfoTab.scss';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { isSaasApiEnabled } from '../../../shared/settings';

export function GeneralInfoTab() {
  const [t] = useTranslation();
  const patientCase = useBoundStore((state) => state.patientCase);

  return (
    <div className="general-info-tab">
      {patientCase ? (
        <>
          <div className="general-info-tab__content">
            <OdontogramMini
              highlightPieces={
                patientCase.patient.piecesNotToMove ? patientCase.patient.piecesNotToMove.map((p) => p.number) : []
              }
            />
          </div>
          <PatientDataCard noCard={true} patientCase={patientCase} />
          <ClinicDataCard noCard={true} patientCase={patientCase} />
          {!isSaasApiEnabled() ?? (
            <DataCardBase noCard={true} title={t('generalInfo.comments.title')}>
              {patientCase.comments}
            </DataCardBase>
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
}
