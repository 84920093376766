import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowDropdown } from '../../../../assets/icons/dropdown-toggle.svg';

import ToggleCompareTreatments from './ToggleCompareTreatments';
import { useCaseId, useCustomNavigate } from '../../../../hooks';
import { PathLevels } from '../../../../models';
import { OrthodonticsPagesUrl } from '../../../../orthodontics/OrthodonticsRoutes';
import { useOrthBoundStore } from '../../../../orthodontics/stores/useStore';
import { CaseVersion } from '../../../../shared';
import { useBoundStore } from '../../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../../stores/useStore';
import { CaseIdentifierType } from '../../../stores/slices';

import './caseVersionSelector.scss';

export default function CaseVersionSelector() {
  const { versions, patientName, patientSurname, patientCase } = useBoundStore((state) => ({
    versions: state.versions,
    patientName: state.patientCase?.patient?.firstName,
    patientSurname: state.patientCase?.patient?.lastName,
    patientCase: state.patientCase
  }));
  const { isVersionsDisabled, caseIdentifierType } = useCommonBoundStore((state) => ({
    isVersionsDisabled: state.isVersionsDisabled,
    caseIdentifierType: state.caseIdentifierType
  }));
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);

  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { pathname } = useLocation();
  const { getRouteWithNewVersionId } = useCustomNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const shortedPathName = `/${pathname.split('/')[PathLevels.RouteView]}`;
  const isDentalMovementsPage = shortedPathName === OrthodonticsPagesUrl.DentalMovements;
  const isDisabled = !isDentalMovementsPage || isVersionsDisabled;

  const loadSelectedVersion = (version: CaseVersion) => {
    navigate(getRouteWithNewVersionId(location.pathname, version.id));
    window.location.reload();
  };

  const abbreviatedPatientName = (patientName: string, patientSurname: string) => {
    const patientSurnameFormatted = patientSurname ? patientSurname[0]?.toUpperCase() + '.' : '';
    return `${patientName} ${patientSurnameFormatted}`;
  };

  const caseIdentifierToShow = () => {
    const caseIdToShow = patientCase?.externalId ? patientCase.externalId : caseId;
    return caseIdentifierType === CaseIdentifierType.CaseId
      ? `${t('common.case')} ${caseIdToShow}`
      : abbreviatedPatientName(patientName, patientSurname);
  };

  return (
    <div className="cvselector">
      <Dropdown autoClose="outside" align="end" className=" version-dropdown">
        <Dropdown.Toggle variant="select-case" disabled={isDisabled} id="dropdown-basic">
          <div className="navbar-case d-flex align-items-center">
            <span className="navbar-case-text">{caseIdentifierToShow()}</span>
            {isDentalMovementsPage && <ArrowDropdown className="icon ms-3" />}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isDentalMovementsPage && currentVersion && (
            <ToggleCompareTreatments
              caseId={caseId}
              isCompareDisabled={isDisabled}
              versions={versions}
              selectedVersionId={currentVersion?.id}
              setSelectedVersion={(version) => loadSelectedVersion(version)}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
