import { StateCreator } from 'zustand';
import { SaaSUserProfileDTO, UserProfile } from '../../../shared';

export interface UserProfileSlice {
  userProfile: UserProfile | SaaSUserProfileDTO;
  updateProfile: (profile: UserProfile | SaaSUserProfileDTO) => void;
}
export const createProfileSlice: StateCreator<UserProfileSlice, [], [], UserProfileSlice> = (set) => ({
  userProfile: undefined,
  updateProfile: (userProfile) => set(() => ({ userProfile }))
});
