import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import { ReactComponent as IPR } from '../../../../assets/icons/evolutionPanel/info/ipr.svg';
import { ApplyIprState, AttachmentState, DentalMovementGroup } from '../../../../common/evergine';
import { EvolutionAttachment, EvolutionInterdentalDistance, EvolutionStep, EvolutionTooth } from '../EvolutionPanel';
import { ToothInfoBrick } from './ToothInfoBrick';
import { useOrthBoundStore } from '../../../stores/useStore';
import { AbilityAction, AbilityContext, areObjectsEqual, OrthoAbilitySubject, teethFdis } from '../../../../shared';
import {
  addBrickToContainer,
  AdjacentBricks,
  appendBricksToContainer,
  createDragImageContainer,
  DragBlockEventData,
  DraggingBrickToothInfo,
  getAdjacentBricks,
  getAttributeInt,
  getClassNameForIpr,
  getCurrentTargetWithOffset,
  getElementPosition,
  getNewAdjacentBricks,
  getRowBricks,
  getStartEndPiecesForQuadrant,
  isDirectionChange,
  isDragEventValid,
  isDraggingFromOtherRow,
  isElementInArray,
  MovementInfo,
  QuadrantItem,
  QuadrantRowsProps,
  SelectedIprType,
  setDraggingDirection,
  setDragImage,
  sortAdjacentBricks,
  sortBricksByColumn,
  updateFilterBricksRef
} from './QuadrantRows.helper';
import { useStepsManager } from '../../../../hooks';
import { useCommonBoundStore } from '../../../../common/stores/useStore';
import { useMovementTable } from '../../../../hooks/shared/useMovementTable';

export function QuadrantRows({
  applyIPRList,
  isKeyShiftDown,
  steps,
  setIsKeyShiftDown,
  updateIPRList,
  onInit
}: QuadrantRowsProps) {
  const [selectedIpr, setSelectedIpr] = useState<SelectedIprType>();
  const dragImageRefs = useRef([]);
  const quadrantItemRef = useRef<HTMLDivElement>(null);
  const dragImageContainerRef = useRef<HTMLDivElement>(null);
  const dragItemX = useRef(0);
  const dragItemY = useRef(0);
  const iprImageContainerRef = useRef(null);
  const iprDragItemX = useRef(0);
  const iprDragItemY = useRef(0);
  const allBricksRef = useRef<AdjacentBricks>({ leftAdjacentBricks: [], draggedBrick: null, rightAdjacentBricks: [] });
  const startX = useRef(0);
  const offsetX = useRef(0);
  const draggedBrickClassRef = useRef('');
  const isBrickDraggingRef = useRef(false);
  const dragBrickData = useRef<DragBlockEventData>({ adjacentBrickBlocks: [], draggingBrickBlock: null });
  const filterBricksRef = useRef<HTMLElement[]>([]);
  const isDraggingLeftRef = useRef(false);
  const setNumberOfUndo = useOrthBoundStore((state) => state.setNumberOfUndo);
  const setUndoReverse = useOrthBoundStore((state) => state.setUndoReverse);
  const activeStep = useCommonBoundStore((state) => state.activeStep);
  const { goToStep } = useStepsManager();
  const { isOverMovement } = useMovementTable();

  const ability = useContext(AbilityContext);
  const canEditIPR = useMemo(
    () => !!ability && ability.can(AbilityAction.Manage, OrthoAbilitySubject.EditIpr),
    [ability]
  );
  const canDragSteps = useMemo(
    () => !!ability && ability.can(AbilityAction.Manage, OrthoAbilitySubject.DragSteps) && isKeyShiftDown,
    [ability, isKeyShiftDown]
  );

  useEffect(() => {
    onInit();
  }, [quadrantItemRef.current]);

  const cleanUpDragImageRefs = () => {
    // Cleanup function to remove all drag image elements
    dragImageRefs.current.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        document.body.removeChild(element);
      }
    });
    dragImageRefs.current = []; // Reset the array
  };

  const getTeethIPRData = useCallback(
    (rightFdiTeethToFind: number) => applyIPRList.find((i) => i.fdiRight === rightFdiTeethToFind),
    [applyIPRList]
  );

  useEffect(() => {
    return () => {
      cleanUpDragImageRefs();
    };
  }, []);

  const hasTeethIpr = useCallback(
    (rightFdiTeethToFind: number, compareStepIndex?: number) => {
      const teethWithIpr = getTeethIPRData(rightFdiTeethToFind);

      if (compareStepIndex > 0) {
        return teethWithIpr?.applyStepIndex === compareStepIndex;
      }

      return !!teethWithIpr;
    },
    [getTeethIPRData]
  );

  const onDropIprHandler = useCallback(
    (
      event: React.DragEvent<HTMLDivElement>,
      newStepIndex: number,
      newInterdentalDistance: EvolutionInterdentalDistance,
      oldIPRSelected: SelectedIprType
    ) => {
      event.preventDefault();
      // Reset stored refs state
      resetImageContainers(event);
      const oldIPRTeethRight = oldIPRSelected?.stepDistance?.rightToothFdi;
      const oldStepIndex = oldIPRSelected?.stepIndex;

      // Don't allow drop if teeth are the same
      if (oldIPRTeethRight !== newInterdentalDistance.rightToothFdi) {
        return;
      }

      // Don't allow drop if steps are the same
      if (oldStepIndex === newStepIndex) {
        return;
      }

      const newItems = [...applyIPRList];

      const teethIprIndex = newItems.findIndex((i) => i.fdiRight === oldIPRTeethRight);
      if (teethIprIndex < 0) {
        return;
      }

      const IPRItem = newItems[teethIprIndex];

      // Check if no value, in firstContactApplyStepIndex set IPR stepIndex
      // TODO: REVIEW THIS!!: Must not change MaxStepIndex. Why changed here?
      if (IPRItem?.applyStepIndex <= 0) {
        IPRItem.maxStepIndex = oldIPRSelected.stepIndex;
      }

      // Check if new step index is higher than the IPR first contact step, don't allow to drop
      if (IPRItem?.maxStepIndex < newStepIndex) {
        return;
      }

      IPRItem.applyStepIndex = newStepIndex;
      const newSelection = { stepDistance: newInterdentalDistance, stepIndex: newStepIndex };

      newInterdentalDistance.applyIprState = ApplyIprState.ApplyIpr;
      oldIPRSelected.stepDistance.applyIprState =
        oldIPRSelected.stepIndex < newStepIndex ? ApplyIprState.PreIpr : ApplyIprState.PostIpr;

      window.App.webEventsProxy.iprs.moveLabel(
        oldIPRSelected?.stepDistance?.leftToothFdi,
        oldIPRSelected?.stepDistance?.rightToothFdi,
        newStepIndex
      );
      newItems[teethIprIndex] = IPRItem;
      updateIPRList(newItems);
      setSelectedIpr(newSelection);
    },
    [applyIPRList]
  );

  // Click to change IPR it's easier if in the future, client doesn't want to use drag and drop
  const onSelectIpr = (newInterdentalDistance: EvolutionInterdentalDistance, newStepIndex: number) => {
    const newTeethIndex = newInterdentalDistance.rightToothFdi;
    if (!isIpr(newTeethIndex, newStepIndex)) {
      return;
    }

    const newSelection = { stepDistance: newInterdentalDistance, stepIndex: newStepIndex };
    setSelectedIpr(newSelection);
  };

  const onHandleDragOver = (e: any) => {
    e.preventDefault();
  };

  const isIpr = useCallback(
    (rightFdiTeethToCompare: number, stepIndex: number) => hasTeethIpr(rightFdiTeethToCompare, stepIndex),
    [hasTeethIpr]
  );

  const handleIprDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    // Clean previously stored refs
    cleanUpDragImageRefs();

    const iprImageContainer: HTMLElement = (e.currentTarget as HTMLElement).cloneNode(true) as HTMLElement;
    iprImageContainer.id = `iprDragImage_${Date.now()}`; // Generate a unique ID for ref cleanup
    iprImageContainer.style.top = '-9999px';
    iprImageContainer.style.left = '-9999px';
    iprImageContainer.style.zIndex = '10';
    iprImageContainer.style.display = 'flex';
    iprImageContainer.style.justifyContent = 'start';
    iprImageContainer.style.position = 'absolute';

    document.body.appendChild(iprImageContainer);

    iprImageContainerRef.current = iprImageContainer;
    const client = e.currentTarget.getBoundingClientRect();

    iprDragItemX.current = client.width * 0.5 + client.left;
    iprDragItemY.current = client.top;

    dragImageRefs.current.push(iprImageContainer.id);

    e.dataTransfer.setDragImage(new Image(), 0, 0);
    document.addEventListener('dragend', resetImageContainers);
  };

  const handleIprDrag = useCallback((e) => {
    if (e.clientX === 0 && e.clientY === 0) return;
    const element = document.getElementById(iprImageContainerRef.current.id);
    if (element) {
      const newPosX = e.clientX - 0;
      element.style.left = `${newPosX}px`;
      element.style.top = `${iprDragItemY.current}px`;
      element.style.pointerEvents = 'none';
      element.style.opacity = '0.6';
    }
  }, []);

  const resetRefs = () => {
    allBricksRef.current = { leftAdjacentBricks: [], draggedBrick: null, rightAdjacentBricks: [] };
    startX.current = 0;
    draggedBrickClassRef.current = '';
    isBrickDraggingRef.current = false;
    offsetX.current = 0;
    dragBrickData.current = { adjacentBrickBlocks: [], draggingBrickBlock: null };
    cleanUpDragImageRefs();
  };

  const prepareDraggingData = (allBricksRef: AdjacentBricks, adjacentBricks: HTMLElement[]) => {
    const adjacentBricksToDraggingBrick = adjacentBricks.map((brick) => ({
      fdi: getAttributeInt(brick, 'data-row'),
      stepIndex: getAttributeInt(brick, 'data-column')
    }));
    const draggingBrick = {
      fdi: getAttributeInt(allBricksRef.draggedBrick, 'data-row'),
      stepIndex: getAttributeInt(allBricksRef.draggedBrick, 'data-column')
    };
    dragBrickData.current = {
      adjacentBrickBlocks: dragBrickData.current?.adjacentBrickBlocks,
      draggingBrickBlock: { adjacentBricksToDraggingBrick, draggingBrick }
    };
  };

  const handleBrickDragOver = useCallback((e: React.DragEvent<HTMLDivElement>, rowNumber: number) => {
    e.preventDefault();
    const currentStepRow = getAttributeInt(filterBricksRef.current[0], 'data-row');
    // Ignore if is from other row
    if (isDraggingFromOtherRow(currentStepRow, rowNumber)) return;

    const currentStepIndex = getAttributeInt(e.currentTarget, 'data-column');

    if (currentStepIndex === 0) return;

    const isDraggingLeft = isDraggingLeftRef.current;

    const referenceBrick = isDraggingLeft
      ? filterBricksRef.current[filterBricksRef.current.length - 1]
      : filterBricksRef.current[0];
    const stepIndex = getAttributeInt(referenceBrick, 'data-column');

    // Ignore if change of direction
    if (isDirectionChange(isDraggingLeft, currentStepIndex, stepIndex)) return;

    const rowBricks = getRowBricks(rowNumber);
    const currentTargetIndex = rowBricks.indexOf(e.currentTarget);
    const offsetIndex = isDraggingLeft ? -filterBricksRef.current.length : filterBricksRef.current.length;
    const currentTargetWithOffset = getCurrentTargetWithOffset(rowBricks, currentTargetIndex, offsetIndex);

    // Check if the drag over element exist and is already in the filterBricks array
    if (!currentTargetWithOffset || isElementInArray(filterBricksRef.current, currentTargetWithOffset)) return;

    if (currentTargetWithOffset.classList.contains(draggedBrickClassRef.current)) {
      const newAdjacentBricks = getNewAdjacentBricks(currentTargetWithOffset, rowNumber, draggedBrickClassRef.current);

      const newBricks = [
        ...newAdjacentBricks.leftAdjacentBricks,
        newAdjacentBricks.draggedBrick,
        ...newAdjacentBricks.rightAdjacentBricks
      ];

      const sortedAdjacentBricks = sortBricksByColumn(newBricks);

      sortedAdjacentBricks.forEach((_) => {
        const row = _.getAttribute('data-row') || '';
        const column = _.getAttribute('data-column') || '';
        const brickId = `brick_${row}${column}`;

        const brick = document.createElement('div');
        brick.id = brickId;
        brick.classList.add('toothinfobrick');
        brick.classList.add(draggedBrickClassRef.current);

        addBrickToContainer(brick, dragImageContainerRef.current, isDraggingLeft, allBricksRef.current);

        const adjacentBricksToDraggingBrick = sortedAdjacentBricks.map((b) => ({
          fdi: getAttributeInt(b, 'data-row'),
          stepIndex: getAttributeInt(b, 'data-column')
        }));

        const draggingBrick = isDraggingLeft
          ? adjacentBricksToDraggingBrick[adjacentBricksToDraggingBrick.length - 1]
          : adjacentBricksToDraggingBrick[0];

        const data = { adjacentBricksToDraggingBrick, draggingBrick };

        if (!dragBrickData.current?.adjacentBrickBlocks.some((block) => areObjectsEqual(block, data))) {
          dragBrickData.current = {
            adjacentBrickBlocks: [...dragBrickData.current?.adjacentBrickBlocks, data],
            draggingBrickBlock: dragBrickData.current?.draggingBrickBlock
          };
        }
      });
    }
  }, []);

  const handleBrickDragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>, rowNumber: number, className: string) => {
      resetRefs();
      startX.current = e.clientX;
      dragItemY.current = getElementPosition(e.currentTarget).top;

      const draggedBrick = e.currentTarget;
      draggedBrickClassRef.current = Array.from(draggedBrick.classList).find((c) => c !== 'toothinfobrick');

      const allBricks = getAdjacentBricks(draggedBrick, rowNumber, className);
      allBricksRef.current = allBricks;

      const filterBricks = [allBricks.draggedBrick, ...allBricks.rightAdjacentBricks];
      const sortedBricks = sortAdjacentBricks(filterBricks);

      const draggedBrickContainerIndex = sortedBricks.findIndex(
        (brick) => getAttributeInt(brick, 'data-column') === getAttributeInt(draggedBrick, 'data-column')
      );

      const dragImageContainer = createDragImageContainer();
      dragImageRefs.current.push(dragImageContainer.id);

      const brickRect = getElementPosition(draggedBrick);
      const brickWidth = brickRect.width;
      const posX = brickWidth * 0.5 + draggedBrickContainerIndex * brickWidth + 3 * draggedBrickContainerIndex;
      const posY = brickRect.height / 2;

      dragImageContainerRef.current = dragImageContainer;
      dragItemX.current = posX;
      setDragImage(e, posX, posY);

      document.addEventListener('dragend', resetImageContainers);
    },
    []
  );

  const handleBrickDrag = useCallback((e) => {
    if (!isDragEventValid(e)) return;

    if (!isBrickDraggingRef.current) {
      isBrickDraggingRef.current = true;
      isDraggingLeftRef.current = setDraggingDirection(e.clientX, startX.current);

      filterBricksRef.current = updateFilterBricksRef(isDraggingLeftRef.current, allBricksRef.current);
      const sortedAdjacentBricks = sortAdjacentBricks(filterBricksRef.current);
      appendBricksToContainer(sortedAdjacentBricks, dragImageContainerRef.current, draggedBrickClassRef.current);

      prepareDraggingData(allBricksRef.current, sortedAdjacentBricks);
    }

    if (isDraggingLeftRef.current) {
      offsetX.current = dragImageContainerRef.current.getBoundingClientRect().width;
    }

    const element = document.getElementById(dragImageContainerRef.current.id);
    if (element) {
      element.style.left = `${e.clientX - offsetX.current}px`;
      element.style.top = `${dragItemY.current}px`;
      element.style.pointerEvents = 'none';
      element.style.opacity = '0.6';
    }
  }, []);

  const resetImageContainers = useCallback((e) => {
    dragImageRefs.current.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.top = '-9999px';
        element.style.left = '-9999px';
      }
      document.removeEventListener('dragend', resetImageContainers);
    });
  }, []);

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    // HACK: Force shift key pressed state to false as dragging causes some funny behaviours with
    //       capturing key events while doing it. If the user still has the Shift key pressed, it
    //       will be set to true automatically.
    setIsKeyShiftDown(false);
  };

  const resetStepsTranformForTooth = (group: DentalMovementGroup, toothMovementIndex: number): void => {
    const identityTransform = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];

    const stepsLength: number = group.steps.length;
    let tranformToResetTo = identityTransform;

    for (let i = 0; i < stepsLength; i++) {
      const step = group.steps[i];
      const toothMovements = step.toothMovements[toothMovementIndex];

      // If the tooth is moved in the current step, we store its transform to apply to the next reseted teeth
      if (toothMovements.movedInCurrentStep) {
        tranformToResetTo = toothMovements.transform ?? identityTransform;
      } else {
        toothMovements.transform = tranformToResetTo;
      }
    }
  };

  const moveBrickInGroup = (
    group: DentalMovementGroup,
    currentGroup: DentalMovementGroup,
    brick: DraggingBrickToothInfo,
    stepsToMove: number,
    changedIndexes: Set<number>
  ): number => {
    const brickToothId = `tooth-${brick.fdi}`;
    const fromIndex = brick.stepIndex;
    const toIndex = brick.stepIndex + stepsToMove;
    // The brick movements are only allowed within the same tooth
    const brickMovementIndex = group.steps[fromIndex].toothMovements.findIndex((tm) => tm.toothId === brickToothId);
    const brickMovementFrom = currentGroup.steps[fromIndex].toothMovements[brickMovementIndex];
    const brickMovementTo = group.steps[toIndex].toothMovements[brickMovementIndex];

    let toothIndex = -1;
    if (brickMovementFrom && brickMovementTo) {
      brickMovementTo.movedInCurrentStep = true;
      brickMovementTo.transform = brickMovementFrom.transform;

      if (!changedIndexes.has(fromIndex)) {
        brickMovementFrom.movedInCurrentStep = false;
        // Apply previous dental movement or reset to 0 if it's the first step
        const brickMovementFromTransformPrevious = group.steps[fromIndex - 1]?.toothMovements[brickMovementIndex]
          ?.transform ?? [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];

        brickMovementFrom.transform = brickMovementFromTransformPrevious;
        group.steps[fromIndex].toothMovements[brickMovementIndex] = brickMovementFrom;
      }

      group.steps[toIndex].toothMovements[brickMovementIndex] = brickMovementTo;
      changedIndexes.add(toIndex);

      toothIndex = brickMovementIndex;
    }

    return toothIndex;
  };

  const moveBricks = (
    upperMovements: DentalMovementGroup,
    lowerMovements: DentalMovementGroup,
    bricks: DraggingBrickToothInfo[],
    stepsToMove: number
  ): { upperMovements: DentalMovementGroup; lowerMovements: DentalMovementGroup } => {
    const updatedUpperMovements = cloneDeep(upperMovements);
    const updatedLowerMovements = cloneDeep(lowerMovements);
    const changedIndexes: Set<number> = new Set();

    const processBricks = (updatedMovements: DentalMovementGroup, originalMovements: DentalMovementGroup) => {
      let toothIndex = -1;
      bricks.forEach((brick) => {
        toothIndex = moveBrickInGroup(updatedMovements, originalMovements, brick, stepsToMove, changedIndexes);
      });
      if (toothIndex >= 0) {
        resetStepsTranformForTooth(updatedMovements, toothIndex);
      }
    };

    processBricks(updatedUpperMovements, upperMovements);
    processBricks(updatedLowerMovements, lowerMovements);

    return { upperMovements: updatedUpperMovements, lowerMovements: updatedLowerMovements };
  };

  const isTargetStepWithMovements = (
    draggingBrick: DraggingBrickToothInfo,
    stepIndex: number,
    stepsToMove: number,
    upperMovements: DentalMovementGroup,
    lowerMovements: DentalMovementGroup,
    draggingBlockIndexes: number[]
  ) => {
    const movements = teethFdis.upperTeeth.some((fdi) => fdi === draggingBrick.fdi) ? upperMovements : lowerMovements;
    return (
      (movements.steps[stepIndex].toothMovements.find((tm) => tm.toothId === `tooth-${draggingBrick.fdi}`)
        ?.movedInCurrentStep &&
        !draggingBlockIndexes.includes(stepIndex)) ||
      (movements.steps[draggingBrick.stepIndex + stepsToMove].toothMovements.find(
        (tm) => tm.toothId === `tooth-${draggingBrick.fdi}`
      )?.movedInCurrentStep &&
        !draggingBlockIndexes.includes(draggingBrick.stepIndex + stepsToMove))
    );
  };

  const handleBrickDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      resetImageContainers(e);
      // Access Drag data
      let data = null;
      try {
        data = dragBrickData.current;
      } catch (error) {
        return;
      }

      if (!data || !data.draggingBrickBlock) return;

      const rowBricks = document.querySelectorAll(
        `.toothinfobrick[data-row="${data.draggingBrickBlock.draggingBrick.fdi}"]`
      );
      const currentTargetIndex = Array.from(rowBricks).indexOf(e.currentTarget);
      const allBlockLenght =
        data.adjacentBrickBlocks.reduce((acc, block) => acc + block.adjacentBricksToDraggingBrick.length, 0) +
        data.draggingBrickBlock.adjacentBricksToDraggingBrick.length;

      // First dragg adjacent blocks to prevent colisions in movements
      // Check if dragging to allowed block
      const draggingBlockIndexes = [
        ...data.adjacentBrickBlocks.map((block) => {
          return [...block.adjacentBricksToDraggingBrick.map((brick) => brick.stepIndex)];
        }),
        ...data.draggingBrickBlock.adjacentBricksToDraggingBrick.map((brick) => brick.stepIndex)
      ].flat(1);

      // Blocks to move minus first block
      const movement: MovementInfo[] = [];
      let offset = 0;
      data.adjacentBrickBlocks.reverse().forEach((block) => {
        let indexToSum = allBlockLenght - 1 - offset;
        if (isDraggingLeftRef.current) indexToSum = indexToSum * -1;
        const targetBrick = Array.from(rowBricks)[currentTargetIndex + indexToSum];
        offset += block.adjacentBricksToDraggingBrick.length;
        const rs = handleMovement(
          block.adjacentBricksToDraggingBrick,
          block.draggingBrick,
          targetBrick as HTMLDivElement,
          draggingBlockIndexes,
          true,
          e.currentTarget as HTMLDivElement
        );
        movement.push(rs);
      });
      // First block
      movement.push(
        handleMovement(
          data.draggingBrickBlock.adjacentBricksToDraggingBrick,
          data.draggingBrickBlock.draggingBrick,
          e.currentTarget as HTMLDivElement,
          draggingBlockIndexes
        )
      );

      // Set undo movements
      const numberOfMovements = movement.filter((m) => m !== null && m.stepsToMove > 0).length;
      if (numberOfMovements > 0) {
        setUndoReverse(true);
        setNumberOfUndo(numberOfMovements);
      }

      if (!movement.some((m) => m === null)) {
        if (!isDraggingLeftRef.current && currentTargetIndex < data.draggingBrickBlock.draggingBrick.stepIndex) {
          movement.reverse();
        }

        movement.forEach((m) => {
          if (m) {
            doMovement(m.stepsToMove, m.adjacentBricksToDraggingBrick);
          }
        });
      }

      // HACK: sometimes dropping the brick causes the dragEnd event to not be triggered
      handleDragEnd(e);
    },
    [activeStep]
  );

  const handleMovement = (
    adjacentBricksToDraggingBrick: DraggingBrickToothInfo[],
    draggingBrick: DraggingBrickToothInfo,
    draggingTarget: HTMLDivElement,
    draggingBlockIndexes: number[],
    isAdjacentBlock = false,
    draggingTargetReal: HTMLDivElement = draggingTarget
  ): MovementInfo => {
    if (!draggingBrick || !adjacentBricksToDraggingBrick || !draggingTarget) {
      return null;
    }

    const draggingStepIndex = draggingBrick.stepIndex;
    const draggingFdi = draggingBrick.fdi;

    if (Number.isNaN(draggingStepIndex) || Number.isNaN(draggingFdi)) {
      return null;
    }

    const targetStepIndex = parseInt(draggingTarget.getAttribute('data-column') || '');
    const targetFdi = parseInt(draggingTarget.getAttribute('data-row') || '');
    const realTargetStepIndex = parseInt(draggingTargetReal.getAttribute('data-column') || '');

    if (Number.isNaN(targetStepIndex) || Number.isNaN(targetFdi)) {
      return null;
    }

    // Don't allow drop if are different teeth (row)
    if (draggingFdi !== targetFdi) {
      return null;
    }
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

    const firstBrick = adjacentBricksToDraggingBrick[0];
    const lastBrick = adjacentBricksToDraggingBrick[adjacentBricksToDraggingBrick.length - 1];
    let stepsToMove = targetStepIndex - draggingStepIndex;
    if (isAdjacentBlock && !isDraggingLeftRef.current) stepsToMove = targetStepIndex - lastBrick.stepIndex;
    if (isAdjacentBlock && isDraggingLeftRef.current) stepsToMove = targetStepIndex - firstBrick.stepIndex;

    // If not movement needed, return
    if (stepsToMove === 0) {
      return { upperMovements, lowerMovements, stepsToMove, adjacentBricksToDraggingBrick };
    }
    // Don't go out of bounds on the left
    if (firstBrick.stepIndex + stepsToMove <= 0) {
      return null;
    }

    // Don't go out of bounds on the right
    if (lastBrick.stepIndex + stepsToMove > Math.max(upperMovements.steps.length, lowerMovements.steps.length)) {
      return null;
    }

    // Don't allow drop in steps with movements that arent in the group
    if (
      isTargetStepWithMovements(
        stepsToMove > 0 ? lastBrick : firstBrick,
        realTargetStepIndex,
        stepsToMove,
        upperMovements,
        lowerMovements,
        draggingBlockIndexes
      )
    ) {
      return null;
    }

    return { upperMovements, lowerMovements, stepsToMove, adjacentBricksToDraggingBrick };
  };

  const doMovement = (stepsToMove: number, adjacentBricksToDraggingBrick: DraggingBrickToothInfo[]) => {
    if (stepsToMove === 0) {
      return;
    }
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();

    const { upperMovements: updatedUpper, lowerMovements: updatedLower } = moveBricks(
      upperMovements,
      lowerMovements,
      adjacentBricksToDraggingBrick,
      stepsToMove
    );
    window.App.webEventsProxy.movements.updateMovements({
      upperDentalMovements: updatedUpper,
      lowerDentalMovements: updatedLower
    });

    const { upperMovements: otherUpper, lowerMovements: otherLower } =
      window.App.webEventsProxy.movements.getTreatmentMovements();

    if (activeStep) goToStep(activeStep, false);
    useOrthBoundStore.setState({ upperDentalMovements: otherUpper, lowerDentalMovements: otherLower });
  };

  const getBrick = useCallback(
    (
      fdi: number,
      interdentalDistance: EvolutionInterdentalDistance,
      step: EvolutionStep,
      someExistingTooth: boolean,
      attachmentState: AttachmentState
    ) => {
      return (
        <div
          className={`evolutiontable-piece`}
          // className={`evolutiontable-piece ${i === currentActiveStep ? 'is-highlighted' : ''}`}
          key={`piece-evolution-${step.index}-${fdi}`}
        >
          {interdentalDistance && step.index !== 0 && (
            <div
              className={`evolutiontable-piece--distance ${
                !!selectedIpr &&
                selectedIpr?.stepDistance?.rightToothFdi === interdentalDistance.rightToothFdi &&
                selectedIpr?.stepIndex === step.index
                  ? 'is-selected'
                  : ''
              }
          ${canEditIPR && isIpr(interdentalDistance.rightToothFdi, step.index) ? 'can-select' : ''}`}
              tabIndex={0}
              draggable={canEditIPR && isIpr(interdentalDistance?.rightToothFdi, step.index)}
              onDragStart={(e) => handleIprDragStart(e)}
              onDrag={(e) => handleIprDrag(e)}
              onDrop={(e) => onDropIprHandler(e, step.index, interdentalDistance, selectedIpr)}
              onMouseDown={() => canEditIPR && onSelectIpr(interdentalDistance, step.index)}
              id="drop_zone"
            >
              {interdentalDistance.applyIprState === ApplyIprState.ApplyIpr && (
                <div className={`evolutiontable-square evolutiontable-square--ipr`}>
                  <IPR className={`evolutiontable-ipr ${getClassNameForIpr(interdentalDistance.applyIprState)}`} />
                </div>
              )}
              {interdentalDistance.isSpaceVisible && (
                <div className={`evolutiontable-square evolutiontable-square--space`} />
              )}
              <span className="evolutiontable-distance">
                {(interdentalDistance.applyIprState === ApplyIprState.ApplyIpr ||
                  interdentalDistance?.isSpaceVisible) &&
                  getVisibleValue(interdentalDistance.distance)}
              </span>
            </div>
          )}

          <ToothInfoBrick
            evolutionTooth={step?.teeth?.find((tooth: EvolutionTooth) => tooth.fdi === fdi)}
            isMissingTooth={!someExistingTooth}
            attachmentState={attachmentState}
            isOverMovement={isOverMovement(step?.relativeDentalMovement.find((t) => t.fdi == fdi))}
            canDrag={canDragSteps}
            onDragEnd={handleDragEnd}
            onDragStart={handleBrickDragStart}
            onDrop={handleBrickDrop}
            onDrag={handleBrickDrag}
            onDragOver={handleBrickDragOver}
            key={`toothinfobrick-${fdi}-${step.index}`}
            columnIndex={step.index}
          />
        </div>
      );
    },
    [selectedIpr, isIpr, onDropIprHandler, onSelectIpr]
  );

  const getMinFinalTeethSpaceIndex = useCallback(
    (teethId: number, stepsToFilter: EvolutionStep[], lastStepIndex: number) => {
      const stepsCopy: EvolutionStep[] = JSON.parse(JSON.stringify(stepsToFilter));

      const lastStepDistances = stepsCopy.find((s) => s.index === lastStepIndex)?.interdentalDistances;
      const lastStepDistance = lastStepDistances?.find((i) => i.rightToothFdi === teethId);
      const teethStepDistances = stepsCopy
        .filter(
          (s) =>
            (s.interdentalDistances = s.interdentalDistances.filter(
              (i) => i.rightToothFdi === teethId && i.distance > 0 && i.applyIprState === ApplyIprState.None
            ))
        )
        .filter((s) => s.interdentalDistances.find((i) => i.distance === lastStepDistance?.distance));

      let minStep = -1;
      if (teethStepDistances.length > 0) {
        for (let i = teethStepDistances.length - 1; i >= 0; i--) {
          const prevStep = teethStepDistances[i - 1];
          if (prevStep?.index === teethStepDistances[i].index - 1) {
            minStep = prevStep.index;
          } else {
            minStep = teethStepDistances[i].index;
            break;
          }
        }
      }

      return minStep === 0 ? 1 : minStep;
    },
    []
  );

  const getCellPieceEvolutions = useCallback(
    (fdi: number) => {
      if (steps?.length <= 0) {
        return [];
      }
      const lastStepIndex = steps[steps.length - 1].index;
      const teethMinSpaceIndex = getMinFinalTeethSpaceIndex(fdi, steps, lastStepIndex);
      const divs: JSX.Element[] = [];

      const someExistingTooth = steps.some((step: EvolutionStep) => step?.teeth.some((teeth) => teeth.fdi === fdi));

      steps.map((step: EvolutionStep) => {
        const attachmentState = step?.attachments?.find(
          (attachment: EvolutionAttachment) => attachment.toothFdi === fdi
        )?.state;

        const interdentalDistance = step?.interdentalDistances.find(
          (interdentalDistance: EvolutionInterdentalDistance) => interdentalDistance.rightToothFdi === fdi
        );

        if (!!interdentalDistance) {
          interdentalDistance.isSpaceVisible = teethMinSpaceIndex >= 0 && step.index === teethMinSpaceIndex;
        }

        divs.push(getBrick(fdi, interdentalDistance, step, someExistingTooth, attachmentState));
      });

      return divs;
    },
    [steps, selectedIpr, getBrick, getMinFinalTeethSpaceIndex]
  );

  const getRowPieceEvolutions = useCallback(
    (pieceNumber: number) => {
      const divs: JSX.Element[] = [];

      divs.push(
        <div className={`evolutiontable-piece-number`} key={pieceNumber}>
          {pieceNumber}
        </div>
      );

      const data = [divs, ...getCellPieceEvolutions(pieceNumber)];
      return data;
    },
    [getCellPieceEvolutions]
  );

  const getQuadrantPieceEvolution = useCallback(
    (quadrantNumber: number, asc: boolean) => {
      const divs: JSX.Element[] = [];
      const { start, end } = getStartEndPiecesForQuadrant(quadrantNumber);
      const getDirection = (i: number) => (asc ? i <= end : i >= end);
      let counter = start;

      for (counter; getDirection(counter); asc ? counter++ : counter--) {
        divs.push(
          <div className="evolutiontable-row" key={`quadrant-${uuidv4()}`} onDragOver={onHandleDragOver}>
            {getRowPieceEvolutions(counter)}
          </div>
        );
      }

      return divs;
    },
    [getStartEndPiecesForQuadrant, getRowPieceEvolutions]
  );

  const getVisibleValue = (distance: number) => {
    if (distance > 1 || distance < -1) {
      return distance;
    }

    const substringValue = distance < 0 ? 2 : 1;
    const value = Math.round(distance * 10) / 10;

    return distance < 1 ? value.toLocaleString().substring(substringValue) : value;
  };

  const rowsPerQuadrant = useMemo(() => {
    const quadrantsList: QuadrantItem[] = [
      { start: 1, isAsc: false },
      { start: 2, isAsc: true },
      { start: 3, isAsc: false },
      { start: 4, isAsc: true }
    ];

    return quadrantsList.map((quadrant: QuadrantItem) => {
      return (
        <div
          ref={quadrantItemRef}
          className="evolutiontable-quadrant"
          id={`evolutiontable-quadrant-${quadrant.start}`}
          key={`quadrant-${quadrant.start}`}
        >
          {getQuadrantPieceEvolution(quadrant.start, quadrant.isAsc)}
        </div>
      );
    });
  }, [getQuadrantPieceEvolution]);

  return <>{rowsPerQuadrant}</>;
}
