import { useEffect, useState } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useEvergineStore } from 'evergine-react';
import { useCommonBoundStore } from '../../../common/stores/useStore';
import { useUtils } from '../../shared';

export function useTimelineStepsManager() {
  const { evergineReady } = useEvergineStore();
  const lowerDentalMovements = useOrthBoundStore((state) => state.lowerDentalMovements);
  const upperDentalMovements = useOrthBoundStore((state) => state.upperDentalMovements);
  const stageIsLoaded = useOrthBoundStore((state) => state.stageIsLoaded);
  const areMovementsLoaded = useOrthBoundStore((state) => state.areMovementsLoaded);
  const activeStep = useCommonBoundStore((state) => state.activeStep);
  const selectedStepIndexes = useCommonBoundStore((state) => state.selectedStepsIndexes);
  const setActiveStep = useCommonBoundStore((state) => state.setActiveStep);
  const setSelectedStepIndexes = useCommonBoundStore((state) => state.setSelectedStepIndexes);

  const { stepSorter } = useUtils();

  const [canShowTimeline, setCanShowTimeline] = useState<boolean>();
  const [lastStepIndex, setLastStepIndex] = useState<number>(1);

  useEffect(() => {
    if (!stageIsLoaded || !areMovementsLoaded) {
      return;
    }

    setLastStepIndex(Math.max(lowerDentalMovements?.steps?.length, upperDentalMovements?.steps?.length) - 1);
  }, [lowerDentalMovements, upperDentalMovements, stageIsLoaded]);

  useEffect(() => {
    if (!lowerDentalMovements && !upperDentalMovements) {
      return;
    }

    const result =
      (lowerDentalMovements?.steps?.length > 0 || upperDentalMovements?.steps?.length > 0) &&
      stageIsLoaded &&
      evergineReady;

    setCanShowTimeline(result);
  }, [lowerDentalMovements, upperDentalMovements, evergineReady, stageIsLoaded]);

  return {
    activeStep,
    setActiveStep,
    lastStepIndex,
    setLastStepIndex,
    selectedStepIndexes,
    setSelectedStepIndexes,
    canShowTimeline,
    upperTeeth: upperDentalMovements?.teeth,
    lowerTeeth: lowerDentalMovements?.teeth,
    upperAttachments: upperDentalMovements?.attachments,
    lowerAttachments: lowerDentalMovements?.attachments,
    upperIprLabels: upperDentalMovements?.iprLabels,
    lowerIprLabels: lowerDentalMovements?.iprLabels,
    upperSteps: upperDentalMovements?.steps?.sort(stepSorter),
    lowerSteps: lowerDentalMovements?.steps?.sort(stepSorter)
  };
}
