import { useEffect } from 'react';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useCaseId, useRenderModels } from '../../../hooks';
import { PagesWithTools, Stage } from '../../../common';
import { OrthContextualMenu } from '../contextMenus';
import { GeneralPanels } from '../../layout';
import { useOrthBoundStore } from '../../stores/useStore';

export function StlEdition() {
  const setMessageInfo = useBoundStore((state) => state.setMessageInfo);
  const stageIsLoaded = useOrthBoundStore((state) => state.stageIsLoaded);

  const [caseId] = useCaseId();
  useRenderModels(caseId, Stage.STLEdition);

  useEffect(() => {
    if (!!setMessageInfo && stageIsLoaded) {
      setMessageInfo('pageInfoMessages.stlEdition.initial');
    }
  }, [stageIsLoaded, setMessageInfo]);

  return (
    <>
      <GeneralPanels pageWithTools={PagesWithTools.StlEdition}>
        <>
          <OrthContextualMenu />
        </>
      </GeneralPanels>
    </>
  );
}
