import { inject, injectable } from 'inversify';
import { container } from '../../common';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { IAuthService } from '../auth';
import type { IHttpService } from '../http';
import { IUserProfileService } from './iprofile.service';
import {
  CLIENT_ROLE_ON_BACKEND,
  PROFILES_TYPE,
  SAAS_PROFILES_TYPE,
  SaaSUserProfileDTO,
  SaaSUserProfileType,
  UserProfile,
  UserProfileDTO
} from './userProfile';
import { isSaasApiEnabled } from '../settings';
import { DesignOptionsEnum, ICasesService, ManufacturingOptionsEnum } from '../cases';

@injectable()
export class UserProfileService implements IUserProfileService {
  private readonly apiEndpoint = '/api/auth/check';
  private readonly saasApiEndpoint = '/saas-api/users/me';
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;

  public async getProfile(treatmentId: string): Promise<UserProfile | SaaSUserProfileDTO> {
    const authService = container.get<IAuthService>(INJECTED_TYPES.IAuthService);
    const token = authService.getAccessToken();

    let response = null;
    let userType = null;
    if (isSaasApiEnabled()) {
      response = await this.getProfileSaaS(treatmentId);
      userType = response.type;
    } else {
      response = (await this._httpService.get<UserProfileDTO>(`${this.apiEndpoint}`)).data.data;
    }

    const userTypeByTreatment = await this.getUserType(token, treatmentId, userType);
    response.type = userTypeByTreatment;

    return response;
  }

  public getUserType = async (token: string, treatmentId: string, type: SaaSUserProfileType) => {
    return !isSaasApiEnabled() ? this.getUserTypeLegacy(token) : await this.getUserTypeSaaS(treatmentId, type);
  };

  private getProfileSaaS = async (treatmentId: string) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
    let profile = (await this._httpService.get<SaaSUserProfileDTO>(`${this.saasApiEndpoint}`)).data;

    profile.firstName = profile.name.split(' ')[0];
    profile.lastNames = profile.name.split(' ').slice(1).join(' ');
    profile.canPublish = true;

    const versionId = await this.getVersionId(treatmentId);
    const treatment = await casesService.getById(treatmentId, versionId);
    if (profile.type == SAAS_PROFILES_TYPE.client) {
      profile.canPublish = false;
      profile.canDownloadSTL = treatment.manufacturingOption == ManufacturingOptionsEnum.InHouse;
    } else {
      profile.canPublish = treatment.designOption == DesignOptionsEnum.Dentvisign;
      profile.canDownloadSTL = true;
    }
    profile = this.getTreatmentPublicTokenProfileIfExists(profile);
    return profile;
  };

  private getUserTypeSaaS = async (treatmentId: string, type: SaaSUserProfileType) => {
    const params = new URLSearchParams(window.location.search);

    // TODO: If usertype is in the URL, it means that the user is a client (for development purposes) remove it when the backend is ready
    const userTypeFromParam = params.get('usertype') || params.get('userType');

    if (userTypeFromParam == PROFILES_TYPE.client) {
      return PROFILES_TYPE.client;
    }

    // using public access token flow
    const treatmentPublicToken = sessionStorage.getItem('publicAccessToken');
    if (treatmentPublicToken) {
      const publicTokenMetada = this.getUserDataFromPublicTokenMetadata(treatmentPublicToken);
      if (publicTokenMetada.CanDesign) {
        return PROFILES_TYPE.employee;
      } else {
        return PROFILES_TYPE.client;
      }
    }

    // using authToken flow
    const versionId = await this.getVersionId(treatmentId);
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);

    if (type == SAAS_PROFILES_TYPE.administrator) {
      return PROFILES_TYPE.employee;
    }

    const treatment = await casesService.getById(treatmentId, versionId);

    if (treatment.designOption == DesignOptionsEnum.InHouse) {
      return PROFILES_TYPE.employee;
    }

    return PROFILES_TYPE.client;
  };

  private getTreatmentPublicTokenProfileIfExists(profile: SaaSUserProfileDTO) {
    const treatmentPublicToken = sessionStorage.getItem('publicAccessToken');
    if (treatmentPublicToken) {
      const publicTokenMetada = this.getUserDataFromPublicTokenMetadata(treatmentPublicToken);
      profile.canPublish = publicTokenMetada.CanDesign ?? false;
      profile.canDownloadSTL = publicTokenMetada.CanDesign ?? false;
    }
    return profile;
  }

  public getUserDataFromPublicTokenMetadata = (token: string) => {
    if (!token) {
      return null;
    }
    const tokenParts = token.split('.');
    if (tokenParts.length !== 2) {
      return null;
    }
    const encodedPayload = tokenParts[1];
    try {
      const base64Corrected = encodedPayload.replace('-', '+').replace('_', '/');
      const decodedPayload = atob(base64Corrected);
      return JSON.parse(decodedPayload);
    } catch (error) {
      console.error('Error parsing token metadata:', error);
      return null;
    }
  };

  private getUserTypeLegacy = (token: string) => {
    const params = new URLSearchParams(window.location.search);

    // TODO: If usertype is in the URL, it means that the user is a client (for development purposes) remove it when the backend is ready
    const userTypeFromParam = params.get('usertype') || params.get('userType');

    if (userTypeFromParam == PROFILES_TYPE.client) {
      return PROFILES_TYPE.client;
    }

    const payloadObject = this.getUserDataFromToken(token);

    if (payloadObject === null) {
      return PROFILES_TYPE.employee;
    }

    const roles = payloadObject?.roles || [];

    // Is a client if they have only one role, and this role is ROLE_USER
    const isClient = roles[0] == CLIENT_ROLE_ON_BACKEND && roles.length == 1;

    return isClient ? PROFILES_TYPE.client : PROFILES_TYPE.employee;
  };

  public getUserDataFromToken = (token: string) => {
    if (!token) {
      return null;
    }

    const tokenParts = token.split('.');

    if (tokenParts.length !== 3) {
      return null;
    }

    const encodedPayload = tokenParts[1];
    const decodedPayload = atob(encodedPayload);

    try {
      return JSON.parse(decodedPayload);
    } catch (error) {
      console.error('Error parsing JWT payload:', error);

      return null;
    }
  };

  private getVersionId = async (treatmentId: string) => {
    const casesService = container.get<ICasesService>(INJECTED_TYPES.ICasesService);
    const currentLocation = window.location;
    const searchParams = new URLSearchParams(currentLocation.search);
    if (searchParams.has('versionId')) {
      return searchParams.get('versionId');
    } else {
      const versions = await casesService.getVersionsByCaseId(treatmentId);
      return versions[versions.length - 1].id;
    }
  };
}
