import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Warning } from '../../../../../assets/icons/modal/warning.svg';
import { useCaseChanges, useCustomNavigate, useModalSaveChanges } from '../../../../../hooks/shared';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

type ModalPreviousStepTypes = {
  previousRoute?: string;
};

export enum TypesActions {
  Previous = 'previous',
  Next = 'next'
}
export function ModalPreviousStep({ previousRoute }: ModalPreviousStepTypes) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { isCaseModified, onChangeRoute, toggleModalIsOpened, updateRouteToGo } = useCaseChanges();
  const { getPhaseSaveChange } = useModalSaveChanges(TypesActions.Previous);
  const { saveChanges } = getPhaseSaveChange();
  const { currentVersion } = useOrthBoundStore();
  const { getRouteWithVersionId } = useCustomNavigate();

  const onPreviousButtonClick = useCallback(async () => {
    if (currentVersion === null) {
      return;
    }
    toggleModalIsOpened('');

    if (isCaseModified) {
      updateRouteToGo(previousRoute);
      onChangeRoute();
      return;
    }

    // TODO: es necesario guardar al retroceder?
    const result = await saveChanges(false);
    if (!result) {
      return;
    }

    navigate(getRouteWithVersionId(previousRoute));
  }, [previousRoute, onChangeRoute, isCaseModified, currentVersion]);

  const onCloseButtonClick = () => {
    toggleModalIsOpened('');
  };

  return (
    <div className="modal-content" data-testid={'modal-previousStep'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Warning className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.previousStep.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.previousStep.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          onClick={onCloseButtonClick}
          type="button"
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.actions.cancel`)}
        </button>
        <button onClick={onPreviousButtonClick} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.actions.modify`)}
        </button>
      </div>
    </div>
  );
}
