import JSZip from 'jszip';
import { useCallback } from 'react';
import { CapturePosition, container, INJECTED_TYPES } from '../../common';
import { blobToBase64, IFileService } from '../../shared';
import { isSaasApiEnabled } from '../../shared/settings';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

export function useSaveRealRoots(caseId: string) {
  const fileService = container.get<IFileService>(INJECTED_TYPES.IFileService);
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);

  const saveAxisAndRootsZip = useCallback(
    async (zipFileRoute: string, teethArch: CapturePosition) => {
      if (isSaasApiEnabled()) {
        return;
      }
      const zip = new JSZip();
      const blob = Module.FS.readFile(zipFileRoute);
      // Get zip file name
      const parts = zipFileRoute.split('/');
      const lastSegment = parts.pop() || parts.pop();

      const fileKey = teethArch == CapturePosition.UPPER ? 'processed-real-roots-upper' : 'processed-real-roots-lower';

      // Generate blob from zip
      zip.file(lastSegment, blob);
      const zipFile = await zip.generateAsync({ type: 'blob' });
      if (blob && caseId) {
        const base64content = (await blobToBase64(zipFile)).replace('data:application/zip;base64,', '');
        await fileService.uploadFile(caseId, currentVersion?.id, fileKey, {
          key: lastSegment.replace('.zip', ''),
          originalName: lastSegment,
          totalSize: blob.size,
          mimeType: 'application/octet-stream',
          file: base64content
        });
      }
    },
    [caseId]
  );

  return { saveAxisAndRootsZip };
}
