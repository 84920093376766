import { useEvergineStore } from 'evergine-react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  CapturePosition,
  CaptureType,
  Model,
  Model3dType,
  PagesWithTools,
  Split,
  Stage
} from '../../../common/evergine/types';
import {
  useBaseCaptureActionManager,
  useCHeckValidExtension,
  useCaseId,
  useCaseStatus,
  useCaseType,
  useDownloadFiles,
  useGeneralPanels,
  useGetFiles,
  useModelHelper,
  useRemoveFile,
  useTeetharchAffectation
} from '../../../hooks';
import { CaseType, TeethArchPosition } from '../../../models';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { ArchsToTreat, FileCaptureType, isStringVoid } from '../../../shared';
import { DownloadFile, ImageHeader, RemoveFile, UploadFile } from '../../../surgeries/components/layout';
import { ModalInformation } from '../../../surgeries/components/layout/modal/modalTypes/ModalInformation';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useCommonBoundStore } from '../../stores/useStore';
import { VerticalDivider } from '../layout/vertical-divider';
import { LateralPanel } from '../lateralPanel';
import { useDentalMovements } from '../../../hooks/orthodontics';

import './basecaptures.scss';
import { useFetchModelFiles } from '../../../hooks/shared/useFetchModelFiles';
import { isSaasApiEnabled } from '../../../shared/settings';
import { useSaveAndNavigateStep } from '../../../hooks/shared/useSaveAndNavigateStep';

interface BaseCapturesProps {
  type: CaptureType;
  allowedFileExtension: string;
  pageWithTools?: PagesWithTools;
}

const dynamicModels = 'DynamicModels';
const dynamicModelsFullPath = `/Content/${dynamicModels}`;
const UPPER = 'UPPER';
const LOWER = 'LOWER';
const MODEL3D = 'MODEL3D';
const DICOM = 'DICOM';

export function BaseCaptures(props: BaseCapturesProps) {
  const { type, allowedFileExtension } = props;
  const stringType = type === 0 ? 'DICOM' : 'MODEL3D';

  const setCanBeConfirmed = useBoundStore((state) => state.setCanBeConfirmed);
  const isConfirmed = useBoundStore((state) => state.isConfirmed);
  const setIsConfirmed = useBoundStore((state) => state.setIsConfirmed);
  const splitMode = useBoundStore((state) => state.splitMode);
  const patientCase = useBoundStore((state) => state.patientCase);
  const setWebBusy = useBoundStore((state) => state.setWebBusy);
  const setWebBusyMessage = useBoundStore((state) => state.setWebBusyMessage);

  const setHasArchModelsLoaded = useCommonBoundStore((state) => state.setHasArchModelsLoaded);
  const hasArchModelsLoaded = useCommonBoundStore((state) => state.hasArchModelsLoaded);
  const setLowerModel3DId = useCommonBoundStore((state) => state.setLowerModel3DId);
  const setUpperModel3DId = useCommonBoundStore((state) => state.setUpperModel3DId);
  const lowerModel3DId = useCommonBoundStore((state) => state.lowerModel3DId);
  const upperModel3DId = useCommonBoundStore((state) => state.upperModel3DId);
  const setArchsToBeTreated = useCommonBoundStore((state) => state.setArchsToBeTreated);
  const lowerFilesToUpload = useCommonBoundStore((state) => state.lowerFilesToUpload);
  const upperFilesToUpload = useCommonBoundStore((state) => state.upperFilesToUpload);
  const setLowerFilesToUpload = useCommonBoundStore((state) => state.setLowerFilesToUpload);
  const setUpperFilesToUpload = useCommonBoundStore((state) => state.setUpperFilesToUpload);
  const setSomeCapture = useCommonBoundStore((state) => state.setSomeCapture);
  const setOnlyOneCapture = useCommonBoundStore((state) => state.setOnlyOneCapture);
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);

  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const setHasLoadedExtrudedGums = useOrthBoundStore((state) => state.setHasLoadedExtrudedGums);
  const setCanUndo = useOrthBoundStore((state) => state.setCanUndo);
  const isNavigationToNextPhase = useOrthBoundStore((state) => state.isNavigationToNextPhase);
  const navigationRoute = useOrthBoundStore((state) => state.navigationRoute);

  const { evergineReady } = useEvergineStore();

  const [t] = useTranslation();
  const [caseId] = useCaseId();
  const { fetchNonCachedModelFile } = useFetchModelFiles();
  const { affectations } = useTeetharchAffectation();
  const [checkValidExtension] = useCHeckValidExtension();
  const { showLateralPanelIfAllowed } = useGeneralPanels();
  const { showInfoPanel, handleInfoPanelToggle } = useDentalMovements(caseId);
  const [downloadCaptureFile, isDownloadable] = useDownloadFiles(caseId);
  const { removeFileUpper, removeFileLower } = useRemoveFile(caseId, stringType);
  const caseType = useCaseType();
  const { loadCapture, createDirectory } = useModelHelper();

  const [modelsId, setModelsId] = useState<Record<string, Record<string, string>>>({
    [UPPER]: { [DICOM]: null, [MODEL3D]: upperModel3DId },
    [LOWER]: { [DICOM]: null, [MODEL3D]: lowerModel3DId }
  });
  const { showLowerCapture, showUpperCapture } = useBaseCaptureActionManager(modelsId, stringType, caseType);
  const [areModelsLoaded, setAreModelsLoaded] = useState<boolean>(hasArchModelsLoaded);
  const [firstLoadFinished, setFirstLoadFinished] = useState<boolean>(false);
  const { getFileInfo } = useGetFiles(caseId);
  const { isActualPhaseCompleted } = useCaseStatus(caseId);
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: true });

  const [showModalInfo, setShowModalInfo] = useState<boolean>(false);

  const isThereCapture = useCallback(
    (position: TeethArchPosition) => {
      return !isStringVoid(modelsId[position][stringType]);
    },
    [modelsId]
  );

  const onlyOneCapture = useMemo(() => {
    if (
      (isThereCapture(TeethArchPosition.UPPER) && !isThereCapture(TeethArchPosition.LOWER)) ||
      (!isThereCapture(TeethArchPosition.UPPER) && isThereCapture(TeethArchPosition.LOWER))
    ) {
      return true;
    }
    return false;
  }, [isThereCapture(TeethArchPosition.UPPER), isThereCapture(TeethArchPosition.LOWER), modelsId]);

  // Save in store the onlyOneCapture value
  useEffect(() => {
    setOnlyOneCapture(onlyOneCapture);
  }, [onlyOneCapture, setOnlyOneCapture]);

  // Start onChange block
  const handleFileChange = async (event: ChangeEvent<Element>, modelId: string, teethArch: CapturePosition) => {
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget.files && eventTarget.files[0];

    if (!file) {
      return;
    }

    const isValidExtension = checkValidExtension(type, file, allowedFileExtension);
    if (!isValidExtension) {
      return;
    }

    eventTarget.value = null;

    createDirectory();

    await loadEvergineFiles(file, modelId, teethArch);
  };

  const fileOnClick = async (event: React.MouseEvent) => {
    if (isActualPhaseCompleted() && isCaseReseting === false) {
      event.preventDefault();
      setCanUndo(true);
    }
  };

  const setFileToUpload = useCallback(
    (teethArch: CapturePosition, file: File, path: string, modelId: string) => {
      const isUpper = teethArch === CapturePosition.UPPER;
      const setterFilesToUpload = isUpper ? setUpperFilesToUpload : setLowerFilesToUpload;
      const extension = type === CaptureType.MODEL3D ? 'wepmd' : 'zip';

      if (type === CaptureType.DICOM) {
        setterFilesToUpload([
          {
            name: `${modelId}.${extension}`,
            modelId: modelId,
            path: path,
            size: file.size,
            type: isUpper ? FileCaptureType.UPPER_DICOM : FileCaptureType.LOWER_DICOM
          }
        ]);

        return;
      }

      setterFilesToUpload([
        {
          name: `${isUpper ? FileCaptureType.UPPER_STL : FileCaptureType.LOWER_STL}.stl`,
          modelId: modelId,
          path: path,
          size: file.size,
          type: isUpper ? FileCaptureType.UPPER_STL : FileCaptureType.LOWER_STL
        },
        {
          name: `${isUpper ? FileCaptureType.UPPER_MODEL3D : FileCaptureType.LOWER_MODEL3D}.wepmd`,
          modelId: modelId,
          path: path.replace('.stl', '.wepmd'),
          size: file.size,
          type: isUpper ? FileCaptureType.UPPER_MODEL3D : FileCaptureType.LOWER_MODEL3D
        }
      ]);
    },
    [lowerFilesToUpload, upperFilesToUpload, setLowerFilesToUpload, setUpperFilesToUpload]
  );

  const loadEvergineFiles = useCallback(
    (file, modelId, teethArch) => {
      return new Promise((resolve) => {
        let fileName = modelId;
        if (type === CaptureType.MODEL3D) {
          fileName = `${
            teethArch === CapturePosition.UPPER ? FileCaptureType.UPPER_MODEL3D : FileCaptureType.LOWER_MODEL3D
          }`;
        }

        const filepath = `${dynamicModels}/${fileName}.${allowedFileExtension}`;
        const fileFullPath = `${dynamicModelsFullPath}/${fileName}.${allowedFileExtension}`;

        const reader = new FileReader();

        reader.onload = async () => {
          let stream: ArrayBuffer = reader.result as ArrayBuffer;
          let data = new Int8Array(stream);

          setFileToUpload(teethArch, file, fileFullPath, modelId);

          if (type === CaptureType.MODEL3D) {
            // upload extra file for stl (we have setted above the wepmd)
            setFileToUpload(teethArch, file, fileFullPath.replace(allowedFileExtension, 'stl'), modelId);
          }

          Module.FS.writeFile(fileFullPath, data);

          window.App.webEventsProxy.common.simplifyModel(fileFullPath, fileFullPath);

          const modelIdToUnload = modelsId[teethArch === CapturePosition.UPPER ? UPPER : LOWER][MODEL3D];
          window.App.webEventsProxy.common.unloadModels([modelIdToUnload]);
          await window.App.webEventsProxy.common.loadModels([
            {
              id: modelId,
              uri: filepath,
              teethArch: teethArch,
              model3dType: type === CaptureType.DICOM ? null : Model3dType.Scan
            }
          ]);

          setAreModelsLoaded(true);
          setHasArchModelsLoaded(true);

          if (teethArch === CapturePosition.UPPER) {
            const modelsIdUpdated = {
              ...modelsId,
              [UPPER]: {
                ...modelsId[UPPER],
                [stringType]: modelId && modelId ? modelId : null
              },
              [LOWER]: {
                ...modelsId[LOWER]
              }
            };
            setModelsId(modelsIdUpdated);
            setUpperModel3DId(modelId);
          }

          if (teethArch === CapturePosition.LOWER) {
            const modelsIdUpdated = {
              ...modelsId,
              [LOWER]: {
                ...modelsId[LOWER],
                [stringType]: modelId && modelId ? modelId : null
              },
              [UPPER]: {
                ...modelsId[UPPER]
              }
            };

            setModelsId(modelsIdUpdated);
            setLowerModel3DId(modelId);
          }

          data = null;
          stream = null;
          resolve(true);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [patientCase, modelsId]
  );
  // End onChange block

  // Start onLoad block
  useEffect(() => {
    setWebBusy(true); // this will happen only once
    setWebBusyMessage(t('common.loaderActions.loading3D'));

    if (evergineReady) {
      setTimeout(() => {
        window.App.webEventsProxy.common.setStage(Stage.LoadSTL);
      }, 0);
    }
  }, [evergineReady]);

  useEffect(() => {
    setUpperModel3DId(modelsId[UPPER][MODEL3D]);
    setLowerModel3DId(modelsId[LOWER][MODEL3D]);
  }, [modelsId]);

  useEffect(() => {
    if (areModelsLoaded) {
      setWebBusy(false);
      return;
    }

    if (currentVersion === null) {
      return;
    }

    if (evergineReady) {
      const loadFiles = async () => {
        let models: Model[] = [];
        createDirectory();

        setWebBusyMessage(t('common.loaderActions.getUpperArchWepmd'));
        // TODO: cuando se recupere el proyecto Cirugías, recoger los DICOMs de una manera similar a los wepmd-stl
        let upperCaseFile = await getFileInfo(FileCaptureType.UPPER_MODEL3D_EDITED, currentVersion.id);
        if (!upperCaseFile || !upperCaseFile.key) {
          upperCaseFile = await getFileInfo(FileCaptureType.UPPER_MODEL3D, currentVersion.id);
        }
        const upperCapture = {
          key: FileCaptureType.UPPER_MODEL3D,
          name: upperCaseFile.name,
          url: upperCaseFile.url
        };

        setWebBusyMessage(t('common.loaderActions.getLowerArchWepmd'));
        let lowerCaseFile = await getFileInfo(FileCaptureType.LOWER_MODEL3D_EDITED, currentVersion.id);
        if (!lowerCaseFile || !lowerCaseFile.key) {
          lowerCaseFile = await getFileInfo(FileCaptureType.LOWER_MODEL3D, currentVersion.id);
        }

        const lowerCapture = {
          key: FileCaptureType.LOWER_MODEL3D,
          name: lowerCaseFile.name,
          url: lowerCaseFile.url
        };

        let upperModel: Model | undefined;

        if (!!upperCapture.url) {
          setWebBusyMessage(t('common.loaderActions.loading3DUpperArch'));
          upperModel = await loadCapture(CapturePosition.UPPER, upperCapture.name, upperCapture.url);
          if (upperModel) {
            upperModel.model3dType = CaptureType.DICOM ? null : Model3dType.Scan;
            models = [...models, upperModel];
          }
        }

        let lowerModel: Model | undefined;
        if (!!lowerCapture.url) {
          setWebBusyMessage(t('common.loaderActions.loading3DLowerArch'));
          lowerModel = await loadCapture(CapturePosition.LOWER, lowerCapture.name, lowerCapture.url);

          if (lowerModel) {
            lowerModel.model3dType = CaptureType.DICOM ? null : Model3dType.Scan;
            models = [...models, lowerModel];
          }
        }

        setModelsId({
          ...modelsId,
          [UPPER]: {
            ...modelsId[UPPER],
            [stringType]: upperModel && upperModel.id ? upperModel.id : null
          },
          [LOWER]: {
            ...modelsId[LOWER],
            [stringType]: lowerModel && lowerModel.id ? lowerModel.id : null
          }
        });

        await window.App.webEventsProxy.common.loadModels(models);
        setAreModelsLoaded(true);
        setHasArchModelsLoaded(true);

        if (!isSaasApiEnabled()) {
          setWebBusy(false);
        }
        setFirstLoadFinished(true);
      };

      loadFiles();
    }
  }, [evergineReady, areModelsLoaded, currentVersion]);
  // End onLoad block

  useEffect(() => {
    if (firstLoadFinished === false) {
      return;
    }
    async function loadSTLFilesFromAPI() {
      if (areModelsLoaded == true && !modelsId[UPPER][MODEL3D] && !modelsId[LOWER][MODEL3D]) {
        createDirectory();

        let lowerModel = null;
        setWebBusyMessage(t('common.loaderActions.getLowerArchSTL'));
        let lowerSTL = await getFileInfo(FileCaptureType.LOWER_STL_EDITED, currentVersion.id);

        if (!lowerSTL || !lowerSTL.url) {
          lowerSTL = await getFileInfo(FileCaptureType.LOWER_STL, currentVersion.id);
        }

        if (lowerSTL && lowerSTL.url) {
          lowerModel = await fetchNonCachedModelFile(lowerSTL.url);
          if (!!lowerModel) {
            setWebBusyMessage(t('common.loaderActions.transformingSTLtoWepmdLowerArch'));
            await loadEvergineFiles(lowerModel, lowerSTL.key, CapturePosition.LOWER);
            setWebBusyMessage(t('common.loaderActions.loading3DLowerArch'));
          }
        }

        let upperModel = null;
        setWebBusyMessage(t('common.loaderActions.getUpperArchSTL'));
        let upperSTL = await getFileInfo(FileCaptureType.UPPER_STL_EDITED, currentVersion.id);

        if (!upperSTL || !upperSTL.url) {
          upperSTL = await getFileInfo(FileCaptureType.UPPER_STL, currentVersion.id);
        }

        if (upperSTL && upperSTL.url) {
          upperModel = await fetchNonCachedModelFile(upperSTL.url);
          if (!!upperModel) {
            setWebBusyMessage(t('common.loaderActions.transformingSTLtoWepmdUpperArch'));
            await loadEvergineFiles(upperModel, upperSTL.key, CapturePosition.UPPER);
            setWebBusyMessage(t('common.loaderActions.loading3DUpperArch'));
          }
        }

        const modelsIdUpdated = {
          ...modelsId,
          [UPPER]: {
            ...modelsId[UPPER],
            [stringType]: upperModel ? upperSTL.key : null
          },
          [LOWER]: {
            ...modelsId[LOWER],
            [stringType]: lowerModel ? lowerSTL.key : null
          }
        };

        setModelsId(modelsIdUpdated);

        setWebBusy(false);
      } else {
        setWebBusy(false);
      }
    }
    if (isSaasApiEnabled()) {
      loadSTLFilesFromAPI();
    }
  }, [firstLoadFinished]);

  // Activate or not save header icon files

  useEffect(() => {
    if (caseType === CaseType.Orthodontics) {
      setCanBeConfirmed(!!modelsId[UPPER][stringType] || !!modelsId[LOWER][stringType]);
      return;
    }

    if (affectations.hasUpperAffectation && !affectations.hasLowerAffectation) {
      setCanBeConfirmed(!!modelsId[UPPER][stringType]);
      return;
    }

    if (affectations.hasLowerAffectation && !affectations.hasUpperAffectation) {
      setCanBeConfirmed(!!modelsId[LOWER][stringType]);
      return;
    }

    if (affectations.hasLowerAffectation && affectations.hasUpperAffectation) {
      setCanBeConfirmed(!!modelsId[UPPER][stringType] || !!modelsId[LOWER][stringType]);
    }
  }, [modelsId, hasArchModelsLoaded]);

  // Trigger confirmation to false in base captures page

  useEffect(() => {
    if (!isConfirmed) {
      return;
    }

    if (currentVersion === null) {
      return;
    }
    if (onlyOneCapture && !showModalInfo) {
      if (!isSaasApiEnabled) {
        setWebBusy(false);
      }
      setShowModalInfo(true);
    } else {
      setArchsWillBeTreated();
      const isExtruded = window.App.webEventsProxy.modelTransformation.isModelExtruded();
      setHasLoadedExtrudedGums(isExtruded);
      saveAndNavigateStep(navigationRoute, isNavigationToNextPhase, false, false, false);
    }

    setIsConfirmed(false);
  }, [isConfirmed, upperFilesToUpload, lowerFilesToUpload, areModelsLoaded]);

  useEffect(() => {
    if (isThereCapture(TeethArchPosition.UPPER) || isThereCapture(TeethArchPosition.LOWER)) {
      return setSomeCapture(true);
    }
    return setSomeCapture(false);
  }, [isThereCapture(TeethArchPosition.UPPER), isThereCapture(TeethArchPosition.LOWER)]);

  const saveBaseCaptures = async () => {
    setShowModalInfo(false);
    setArchsWillBeTreated();
    const isExtruded = window.App.webEventsProxy.modelTransformation.isModelExtruded();
    setHasLoadedExtrudedGums(isExtruded);
    saveAndNavigateStep(navigationRoute, isNavigationToNextPhase, false, false, false);
  };

  const setArchsWillBeTreated = () => {
    const isThereUpper = isThereCapture(TeethArchPosition.UPPER);
    const isThereLower = isThereCapture(TeethArchPosition.LOWER);
    if (isThereUpper && isThereLower) {
      setArchsToBeTreated(ArchsToTreat.Both);
    } else if (isThereUpper) {
      setArchsToBeTreated(ArchsToTreat.Upper);
    } else if (isThereLower) {
      setArchsToBeTreated(ArchsToTreat.Lower);
    }
  };

  return (
    <>
      <div className="captures container-fluid g-0 d-flex">
        {showLateralPanelIfAllowed && (
          <div className="load-screen">
            <LateralPanel toggleShow={showInfoPanel} handleToggle={handleInfoPanelToggle} />
          </div>
        )}
        <div className={`flex-fill captures-item captures-item-border`}>
          <div className="d-flex">
            {showUpperCapture && (
              <ImageHeader title={type === CaptureType.DICOM ? t(`dicomCaptures.topTitle`) : t(`stlCaptures.topTitle`)}>
                <UploadFile
                  fileId={`${type}_${CapturePosition.UPPER}_${uuidv4()}`}
                  onChange={(event, modelId) => handleFileChange(event, modelId, CapturePosition.UPPER)}
                  onClick={(event) => fileOnClick(event)}
                  typesAllowed={`.${allowedFileExtension}`}
                />
                {isThereCapture(TeethArchPosition.UPPER) && (
                  <RemoveFile onClick={() => removeFileUpper(type, modelsId, setModelsId)} />
                )}

                {isDownloadable(CapturePosition.UPPER, type) && (
                  <DownloadFile onClick={() => downloadCaptureFile(CapturePosition.UPPER, type, modelsId)} />
                )}
              </ImageHeader>
            )}
            {showLowerCapture && (
              <ImageHeader
                title={type === CaptureType.DICOM ? t(`dicomCaptures.lowerTitle`) : t(`stlCaptures.lowerTitle`)}
              >
                <UploadFile
                  fileId={`${type}_${CapturePosition.LOWER}_${uuidv4()}`}
                  onChange={(event, modelId) => handleFileChange(event, modelId, CapturePosition.LOWER)}
                  onClick={(event) => fileOnClick(event)}
                  typesAllowed={`.${allowedFileExtension}`}
                />
                {isThereCapture(TeethArchPosition.LOWER) && (
                  <RemoveFile onClick={() => removeFileLower(type, modelsId, setModelsId)} />
                )}
                {isDownloadable(CapturePosition.LOWER, type) && (
                  <DownloadFile onClick={() => downloadCaptureFile(CapturePosition.LOWER, type, modelsId)} />
                )}
              </ImageHeader>
            )}
          </div>
        </div>
        {splitMode !== Split.Single && <VerticalDivider />}
      </div>
      {showModalInfo && (
        <ModalInformation cancelAction={() => setShowModalInfo(false)} continueAction={saveBaseCaptures} />
      )}
    </>
  );
}
