import { useEffect } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { useUtils } from '../../shared';
import { useDentalMovementsSnapshot } from './useDentalMovementsSnapshot';
import { AttachesInputDTO, ICalculationAttachesService } from '../../../shared';
import { container, INJECTED_TYPES } from '../../../common';

export function useAttachesCalculation() {
  const setAreAttachesSet = useOrthBoundStore((state) => state.setAreAttachesSet);
  const setShowAttachesPanel = useOrthBoundStore((state) => state.setShowAttachesPanel);
  const { setWebBusy } = useBoundStore((state) => ({
    setMessageInfo: state.setMessageInfo,
    setWebBusy: state.setWebBusy
  }));

  const { updateMovements } = useUtils();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();

  const calculateAttachesService = container.get<ICalculationAttachesService>(
    INJECTED_TYPES.ICalculationAttachesService
  );

  const calculateAttaches = async () => {
    try {
      setWebBusy(true);

      const resultInputAttach: AttachesInputDTO = await window.App.webEventsProxy.attachments.getInputAttachments();
      const responseIA = await calculateAttachesService.getAIAutomaticAttaches(resultInputAttach);
      await window.App.webEventsProxy.attachments.updateAttachmentsFromAI(responseIA);
      updateMovements();
      setAreAttachesSet(true);
      updateDentalMovementsSnapshot();
      setShowAttachesPanel(false);
      window.App.webEventsProxy.layers.showAttachments(true);
    } catch (error) {
      console.error(error);
    } finally {
      setWebBusy(false);
    }
  };

  return {
    calculateAttaches
  };
}
