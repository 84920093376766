import { inject, injectable } from 'inversify';
import { DentalMovements, SourceOfMovement, Stage } from '../../common';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaseVersion, CaseVersionState } from '../cases';
import type { IHttpService } from '../http';
import type { FileToUploadDTO, IFileService } from '../file';
import {
  DentalMovementDTO,
  DentalMovementDataDTO,
  MovementsValueSelector,
  TREATMENT_PLANIFICATION_FILE_KEY,
  TreatmentPlanificationMovementsUpdateDTO
} from './dentalMovements';
import { IDentalMovementsService } from './identalMovements.service';
import { blobToBase64 } from '../functionalities.helper';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { SEGMENTATION_FILE_KEY } from '../teethSegmentation';
import { isSaasApiEnabled } from '../settings';

@injectable()
export class DentalMovementsService implements IDentalMovementsService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  @inject(INJECTED_TYPES.IFileService) private _fileService: IFileService;

  private readonly apiEndpoint = '/api/product-cases';
  private readonly saasApiEndpoint = '/saas-api/tools/treatments';

  public async getMovementsTable(
    caseId: string,
    versionId: string,
    source: SourceOfMovement,
    selector: MovementsValueSelector
  ): Promise<DentalMovementDTO[]> {
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}/dental-movements?source=${source}&selector=${selector}`;

    try {
      const dentalMovementsDTO = (await this._httpService.get<DentalMovementDataDTO>(url)).data.data;
      return dentalMovementsDTO;
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async getTreatmentPlanificationMovements(caseId: string, versionId: string): Promise<DentalMovements> {
    try {
      const fileMovementsInfo = await this._fileService.getFileInfo(
        caseId,
        versionId,
        TREATMENT_PLANIFICATION_FILE_KEY
      );
      if (!fileMovementsInfo) {
        return Promise.reject();
      }

      const dentalMovements = (await this._httpService.get(`${fileMovementsInfo.url}?t=${new Date().getTime()}`))
        .data as DentalMovements;

      return dentalMovements;
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async updateTreatmentPlanificationMovements(
    caseId: string,
    version: CaseVersion,
    treatmentPlanificationMovements: TreatmentPlanificationMovementsUpdateDTO
  ): Promise<void> {
    const versionId = version.id.toString();

    let url = '';
    if (!isSaasApiEnabled()) {
      url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}`;
    } else {
      url = `${this.saasApiEndpoint}/${caseId}/versions/${versionId}`;
      version.status = version.state;
    }

    try {
      await this._httpService.patch<CaseVersion>(url, version);
      await this.uploadTreatmentPlanFile(caseId, versionId, treatmentPlanificationMovements);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  public async updateTreatmentState(
    caseId: string,
    version: CaseVersion,
    state: CaseVersionState,
    stage: Stage,
    isResetingPlan = false
  ): Promise<void> {
    const versionId = version.id.toString();

    let url = '';
    if (!isSaasApiEnabled()) {
      url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}`;
    } else {
      url = `${this.saasApiEndpoint}/${caseId}/versions/${versionId}`;
      version.status = state;
    }

    try {
      if (isResetingPlan) {
        delete version.plan;
        await this._fileService.removeFileIfExists(caseId, versionId, TREATMENT_PLANIFICATION_FILE_KEY);
        if (stage <= Stage.TeethSegmentation) {
          delete version.segmentation;
          await this._fileService.removeFileIfExists(caseId, versionId, SEGMENTATION_FILE_KEY);
        }
      }

      version.state = state;
      useOrthBoundStore.setState({ currentVersion: version });
      await this._httpService.patch<CaseVersion>(url, version);
    } catch (error) {
      console.error(error);
      return Promise.reject();
    }
  }

  private async uploadTreatmentPlanFile(
    caseId: string,
    versionId: string,
    treatmentPlanificationMovements: TreatmentPlanificationMovementsUpdateDTO
  ) {
    const json = JSON.stringify(treatmentPlanificationMovements);
    const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
    const base64 = await blobToBase64(blob);
    const fileDto: FileToUploadDTO = {
      key: TREATMENT_PLANIFICATION_FILE_KEY,
      mimeType: 'text/plain;charset=utf-8',
      originalName: `${TREATMENT_PLANIFICATION_FILE_KEY}.json`,
      file: base64,
      totalSize: 0
    };
    await this._fileService.uploadFile(caseId, versionId, TREATMENT_PLANIFICATION_FILE_KEY, fileDto);
  }
}
