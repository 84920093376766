import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as AxisIcon } from '../../../../../assets/icons/axis.svg';
import { ReactComponent as GroupIcon } from '../../../../../assets/icons/combined-group.svg';
import { ReactComponent as GridIcon } from '../../../../../assets/icons/evergineTools/grid.svg';
import { ReactComponent as DropdownToggleIcon } from '../../../../../assets/icons/arrow-dropdown.svg';
import { useBoundStore } from '../../../../../surgeries/stores/useStore';
import { PathLevels } from '../../../../../models';
import { OrthodonticsPagesUrl } from '../../../../OrthodonticsRoutes';

import './groupedActions.scss';

export default () => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const [showAxis, setShowAxis] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const splitMode = useBoundStore((state) => state.splitMode);
  const mustCallShowReference = useMemo(() => {
    return `/${pathname.split('/')[PathLevels.RouteView]}` === OrthodonticsPagesUrl.StlEdition;
  }, [pathname]);

  const toggleAxis = useCallback(() => {
    window.App.webEventsProxy.layers.showAxis(!showAxis);
    if (mustCallShowReference) {
      window.App.webEventsProxy.layers && window.App.webEventsProxy.layers.showReference(!showAxis);
    }
    setShowAxis(!showAxis);
  }, [showAxis]);

  const toggleGrid = useCallback(() => {
    const newValue = !showGrid;

    window.App.webEventsProxy.layers.showGrid(newValue);

    setShowGrid(newValue);
  }, [showGrid, splitMode]);

  const buttonId = 'grouped';

  const dropdownButtonProps: any = {
    'data-for': buttonId,
    'data-tip': true
  };
  return (
    <>
      <Dropdown className="grouped-actions-dropdown" autoClose="outside">
        <Dropdown.Toggle variant="language" {...dropdownButtonProps}>
          <GroupIcon />
          <DropdownToggleIcon className="icon rotate-90 ms-3" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="button" className="grouped-actions-dropdown_item" onClick={toggleAxis}>
            <AxisIcon />
            {t(`navbar.actions.axis`)}
          </Dropdown.Item>
          <Dropdown.Item as="button" className="grouped-actions-dropdown_item" onClick={toggleGrid}>
            <GridIcon />
            {t(`navbar.actions.grid`)}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <ReactTooltip id={buttonId} place="bottom" effect="solid" className="tooltip" type="dark">
        {t(`navbar.actions.groupActions`)}
      </ReactTooltip>
    </>
  );
};
