import {
  downloadCaseToolbarElement,
  layersToolbarElement,
  panelsToolbarElement,
  publishCaseToolbarElement
} from './commonToolbarElements';
import { teethMovementsToolbarElement } from './dentalMovementsToolbarElements';
import { attachesToolbarElement, tadsToolbarElement } from './designerToolbarElements';
import { calcAIIntermediateStepsToolbarElement } from './intermediateStepsToolbarElements';
import { iprMovementsToolbarElement } from './iprMovementsToolbarElements';
import {
  autofixToolbarElement,
  deleteToolbarElement,
  expandToolbarElement,
  extrusionToolbarElement,
  flatSliceToolbarElement,
  gapToolbarElement,
  inspectorToolbarElement,
  softToolbarElement
} from './meshCorrectionToolbarElements';

import { calcAIAxisRootsToolbarElement, manualFixToolbarElement } from './rootsToolbarElements';
import { calcAISegmentationToolbarElement, paintToolbarElement } from './segmentationToolbarElements';
import { labelsToolbarElement } from './labelsToolbarElements';
import { gumExtrusionToolbarElement, stlOrientationToolbarElement } from './stlEditionToolbarElements';
import { testToolbarElement } from './testElement';
import { ToolbarConfigProps } from '../../../common/components/evergineToolbar';
import { PagesWithTools } from '../../../common/evergine/types';

export const evergineOrthoToolbarConfig: { [key: number]: ToolbarConfigProps[] } = {
  [PagesWithTools.MeshCorrection]: [
    testToolbarElement,
    autofixToolbarElement,
    inspectorToolbarElement,
    softToolbarElement,
    expandToolbarElement,
    deleteToolbarElement,
    extrusionToolbarElement,
    gapToolbarElement,
    flatSliceToolbarElement
  ],
  [PagesWithTools.DentalMovements]: [
    layersToolbarElement,
    panelsToolbarElement,
    teethMovementsToolbarElement,
    labelsToolbarElement,
    publishCaseToolbarElement,
    downloadCaseToolbarElement
  ],
  [PagesWithTools.StlEdition]: [stlOrientationToolbarElement, gumExtrusionToolbarElement],
  [PagesWithTools.Roots]: [manualFixToolbarElement, calcAIAxisRootsToolbarElement],
  [PagesWithTools.Segmentation]: [paintToolbarElement, calcAISegmentationToolbarElement],
  [PagesWithTools.Treatment]: [
    layersToolbarElement,
    panelsToolbarElement,
    teethMovementsToolbarElement,
    calcAIIntermediateStepsToolbarElement,
    iprMovementsToolbarElement,
    attachesToolbarElement,
    tadsToolbarElement
  ]
};
