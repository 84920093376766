import { StateCreator } from 'zustand';

export interface OrthNavigationSlice {
  isNavigationToNextPhase: boolean;
  setIsNavigationToNextPhase: (isNavigationToNextPhase: boolean) => void;
  navigationRoute: string;
  setNavigationRoute: (navigationRoute: string) => void;
}

// eslint-disable-next-line prettier/prettier
export const createOrthNavigationSlice: StateCreator<OrthNavigationSlice, [], [], OrthNavigationSlice> = (set) => ({
  isNavigationToNextPhase: true,
  setIsNavigationToNextPhase: (isNavigationToNextPhase: boolean) => set((_) => ({ isNavigationToNextPhase })),
  navigationRoute: null,
  setNavigationRoute: (navigationRoute: string) => set((_) => ({ navigationRoute }))
});
