import { StateCreator } from 'zustand';

export interface OrthLabelsSlice {
  labelsToolIdSelected: boolean;
  setLabelsToolIdSelected: (labelIdSelected: boolean) => void;
  labelsToolStepSelected: boolean;
  setLabelsToolStepSelected: (labelStepSelected: boolean) => void;
  selectedLabelId: number[];
  setSelectedLabelId: (newValue: number[]) => void;
}

export const createOrthLabelsSlice: StateCreator<OrthLabelsSlice, [], [], OrthLabelsSlice> = (set) => ({
  labelsToolIdSelected: false,
  setLabelsToolIdSelected: (labelsToolIdSelected: boolean) => set((_) => ({ labelsToolIdSelected })),
  labelsToolStepSelected: true,
  setLabelsToolStepSelected: (labelsToolStepSelected: boolean) => set((_) => ({ labelsToolStepSelected })),
  selectedLabelId: [],
  setSelectedLabelId: (selectedLabelId: number[]) => set((_) => ({ selectedLabelId }))
});
