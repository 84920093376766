import { Container } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import CacheBuster from 'react-cache-buster';
import { AppRouter } from './AppRouter';
import { container } from './common/ioc/ioc.config';
import { INJECTED_TYPES } from './common/ioc/ioc.types';
import { useCommonBoundStore } from './common/stores/useStore';
import {
  AbilityContext,
  AppAbility,
  IAuthService,
  ICommandInvoker,
  IErrorHandlerService,
  IUserProfileService,
  PROFILES_TYPE,
  SAAS_PROFILES_TYPE,
  SaaSUserProfileType,
  UserProfileType,
  defineAbilitiesFor,
  useAuthConfig
} from './shared';
import EvergineCommand from './shared/commands/evergineCommand';
import { isSaasApiEnabled, loadSettings } from './shared/settings';
import { useBoundStore } from './surgeries/stores/useStore';
import { version } from '../package.json';

import './styles/app.scss';
import { PathLevels } from './models';
import { useOrthBoundStore } from './orthodontics/stores/useStore';

export default function App() {
  const [t] = useTranslation();
  const { updateProfile } = useBoundStore((state) => ({
    updateProfile: state.updateProfile
  }));
  const { setClientCanPublish, setClientCanDownloadSTL } = useOrthBoundStore((state) => ({
    setClientCanPublish: state.setClientCanPublish,
    setClientCanDownloadSTL: state.setClientCanDownloadSTL
  }));
  const { setIsTouchDevice, isTouchDevice, setSettings, setVersion } = useCommonBoundStore((state) => ({
    setIsTouchDevice: state.setIsTouchDevice,
    isTouchDevice: state.isTouchDevice,
    setSettings: state.setSettings,
    setVersion: state.setVersion
  }));

  const { isLogged } = useAuthConfig();
  const [userIsLogged, setUserIsLogged] = useState<boolean>();
  const [ability, setAbility] = useState<AppAbility>();
  const newActionCommand = useBoundStore((state) => state.newActionCommand);
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);
  const errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);

  const isNotLocal = window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1';

  useEffect(() => {
    async function initSettings() {
      const settings = await loadSettings();
      setSettings(settings);
      if (settings.testE2E === true) {
        window.testE2E = true;
      }

      if (settings.dsnGlitchTip && settings.environmentGlitchTip) {
        Sentry.init({ dsn: settings.dsnGlitchTip, environment: settings.environmentGlitchTip });
      }

      setVersion(version);
    }
    initSettings();
  }, []);

  useEffect(() => {
    setUserIsLogged(isLogged);

    if (isLogged === false) {
      errorHandlerService.showError(t('errors.authError'));
    }
  }, [isLogged]);

  useEffect(() => {
    if (isTouchDevice !== null) {
      return;
    }

    const checkIsTouchDevice = () => {
      return 'ontouchstart' in (window || document.documentElement) || navigator.maxTouchPoints > 0;
    };

    setIsTouchDevice(checkIsTouchDevice());
  }, []);

  useEffect(() => {
    const getUser = async () => {
      if (isLogged) {
        await refreshProfileAndAbilities();
      }
    };

    getUser();
  }, [isLogged]);

  useEffect(() => {
    if (!newActionCommand) {
      return;
    }
    const command = new EvergineCommand(newActionCommand.id);
    commandInvokerService.addEvergineCommand(command);
  }, [newActionCommand]);

  const refreshProfileAndAbilities = async () => {
    const authService = container.get<IAuthService>(INJECTED_TYPES.IAuthService);
    const token = authService.getAccessToken();

    await getUserTypeAndSetAbilities(token);
  };

  const getUserTypeAndSetAbilities = async (token: string) => {
    const pathname = window.location.pathname;
    const caseIdFromRoute = pathname.split('/')[PathLevels.CaseId];
    const profileService = container.get<IUserProfileService>(INJECTED_TYPES.IUserProfileService);
    const userProfile = await profileService.getProfile(caseIdFromRoute);

    if (isSaasApiEnabled()) {
      setClientCanPublish(userProfile.canPublish);
      setClientCanDownloadSTL(userProfile.canDownloadSTL);
    }

    let saasUserType = null;
    if (userProfile.type == PROFILES_TYPE.employee) {
      saasUserType = SAAS_PROFILES_TYPE.administrator;
    }

    if (userProfile.type == PROFILES_TYPE.client) {
      saasUserType = SAAS_PROFILES_TYPE.client;
    }

    const userType = await profileService.getUserType(token, caseIdFromRoute, saasUserType as SaaSUserProfileType);

    updateProfile(userProfile);
    setAbility(defineAbilitiesFor(userType as UserProfileType));
  };

  return (
    <>
      {userIsLogged && ability && (
        <CacheBuster currentVersion={version} isEnabled={isNotLocal} isVerboseMode={false}>
          <AbilityContext.Provider value={ability}>
            <BrowserRouter>
              <Container id="app" disableGutters={true} maxWidth={false}>
                <AppRouter />
              </Container>
            </BrowserRouter>
          </AbilityContext.Provider>
        </CacheBuster>
      )}
    </>
  );
}
