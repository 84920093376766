import { useOrthBoundStore } from '../../orthodontics/stores/useStore';

export function useNavigateAction() {
  const resetOpacityLayers = () => {
    window.App.webEventsProxy.layers.setGumsOpacity(100);
    window.App.webEventsProxy.layers.setRootsOpacity(100);
    window.App.webEventsProxy.layers.setTeethOpacity(100);
    window.App.webEventsProxy.layers.showRoots(false);
    window.App.webEventsProxy.layers.showTeeth(true);
    window.App.webEventsProxy.layers.showGums(true);
  };

  const {
    setShowBoltonPanel,
    setShowEvolutionPanel,
    setShowMovementsTable,
    setShowAttachesPanel,
    setShowTadsPanel,
    setShowMiniOcclusogram,
    setShowIprPanel,
    setShowLabelsPanel
  } = useOrthBoundStore((state) => ({
    setShowBoltonPanel: state.setShowBoltonPanel,
    setShowEvolutionPanel: state.setShowEvolutionPanel,
    setShowMovementsTable: state.setShowMovementsTable,
    setShowAttachesPanel: state.setShowAttachesPanel,
    setShowTadsPanel: state.setShowTadsPanel,
    setShowMiniOcclusogram: state.setShowMiniOcclusogram,
    setShowIprPanel: state.setShowIprPanel,
    setShowLabelsPanel: state.setShowLabelsPanel
  }));

  const hidePanelsEvergineTools = () => {
    setShowBoltonPanel(false);
    setShowEvolutionPanel(false);
    setShowMovementsTable(false);
    setShowAttachesPanel(false);
    setShowTadsPanel(false);
    setShowIprPanel(false);
    setShowLabelsPanel(false);
  };

  const hideOcclusogram = () => {
    setShowMiniOcclusogram(false);
    window.App.webEventsProxy.layers.showOcclusogram(false);
  };

  return {
    resetOpacityLayers,
    hidePanelsEvergineTools,
    hideOcclusogram
  };
}
