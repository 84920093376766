import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ClientPlanValidationDTO } from './clientPlanValidation';
import { IClientPlanValidationService } from './iclientPlanValidation.service';
import { isSaasApiEnabled } from '../settings';

@injectable()
export class ClientPlanValidationService implements IClientPlanValidationService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  private readonly apiEndpoint = '/api/product-cases';
  private readonly saasApiEndpoint = '/saas-api/tools/treatments';

  public async clientPlanValidation(
    caseId: string,
    versionId: string,
    clientPlanValidation: ClientPlanValidationDTO
  ): Promise<void> {
    return !isSaasApiEnabled()
      ? await this.clientPlanValidationLegacy(caseId, versionId, clientPlanValidation)
      : await this.clientPlanValidationSaaS(caseId, versionId, clientPlanValidation);
  }

  private async clientPlanValidationLegacy(
    caseId: string,
    versionId: string,
    clientPlanValidation: ClientPlanValidationDTO
  ): Promise<void> {
    clientPlanValidation.clientId = clientPlanValidation.clientId.toString();
    const url = `${this.apiEndpoint}/${caseId}/treatment-plans/${versionId}/acceptance`;
    await this._httpService.post<ClientPlanValidationDTO>(url, clientPlanValidation);
  }

  private async clientPlanValidationSaaS(
    caseId: string,
    versionId: string,
    clientPlanValidation: ClientPlanValidationDTO
  ): Promise<void> {
    if (!clientPlanValidation.requestedChangesText) {
      clientPlanValidation.requestedChangesText = '';
    }
    const url = `${this.saasApiEndpoint}/${caseId}/versions/${versionId}/validations`;
    await this._httpService.post<ClientPlanValidationDTO>(url, clientPlanValidation);
  }
}
