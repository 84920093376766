import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import type { IHttpService } from '../http';
import { ChatMessage, ChatMessageDTO } from './chat';
import { IChatService } from './ichat.service';
import { isSaasApiEnabled } from '../settings';

@injectable()
export class ChatService implements IChatService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  // private readonly apiEndpoint = '/saas-api/chat';
  private readonly apiEndpoint = '/api/product-cases';
  private readonly saasApiEndpoint = '/saas-api/chat';

  private buildURL = (caseId: string) => `${this.apiEndpoint}/${caseId}/internal-chat`;
  private saasbuildURL = (caseId: string) => `${this.saasApiEndpoint}/${caseId}`;

  public async getByCaseId(caseId: string): Promise<ChatMessage[]> {
    return !isSaasApiEnabled() ? await this.getByCaseIdLegacy(caseId) : await this.getByCaseIdSaaS(caseId);
  }

  private async getByCaseIdLegacy(caseId: string): Promise<ChatMessage[]> {
    const chatMessageList: ChatMessage[] = (await this._httpService.get<ChatMessageDTO>(this.buildURL(caseId))).data
      .data;
    return chatMessageList;
  }

  private async getByCaseIdSaaS(caseId: string): Promise<ChatMessage[]> {
    const chatMessageList: ChatMessage[] = (await this._httpService.get<ChatMessage[]>(this.saasbuildURL(caseId))).data;
    return chatMessageList;
  }

  public async addMessage(caseId: string, chatMessage: ChatMessage): Promise<boolean> {
    return !isSaasApiEnabled()
      ? await this.addMessageLegacy(caseId, chatMessage)
      : await this.addMessageSaaS(caseId, chatMessage);
  }

  private async addMessageLegacy(caseId: string, chatMessage: ChatMessage): Promise<boolean> {
    const result = await this._httpService.post<boolean>(this.buildURL(caseId), chatMessage);
    return result.data;
  }

  private async addMessageSaaS(caseId: string, chatMessage: ChatMessage): Promise<boolean> {
    delete chatMessage.userId;
    const result = await this._httpService.post<boolean>(this.saasbuildURL(caseId), chatMessage);
    return result.data;
  }

  public async markMessageAsRead(caseId: string, messageId: string): Promise<void> {
    await this._httpService.patch<boolean>(`${this.buildURL(caseId)}/${messageId}/mark-as-read`);
  }
}
