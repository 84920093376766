import { DentalMovementPair } from '../../../common';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { cloneDeep, isEqual, omit } from 'lodash';

export function useDentalMovementsSnapshot() {
  const { dentalMovementsSnapshot, setDentalMovementsSnapshot } = useOrthBoundStore((state) => ({
    dentalMovementsSnapshot: state.dentalMovementsSnapshot,
    setDentalMovementsSnapshot: state.setDentalMovementsSnapshot
  }));

  const getDentalMovementsFromEvergine = () => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    return {
      lowerDentalMovements: lowerMovements,
      upperDentalMovements: upperMovements
    } as DentalMovementPair;
  };

  const updateDentalMovementsSnapshot = () => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    setDentalMovementsSnapshot({
      lowerDentalMovements: lowerMovements,
      upperDentalMovements: upperMovements
    });
  };

  const haveBeenMadeChangesInMovements = () => {
    if (dentalMovementsSnapshot === null) {
      return false;
    }
    const movementsFromEvergine = getDentalMovementsFromEvergine();
    const clonedMovementsFromEvergine = cloneDeep(movementsFromEvergine);
    const clonedDentalMovementsSnapshot = cloneDeep(dentalMovementsSnapshot);

    clonedMovementsFromEvergine.lowerDentalMovements?.steps.forEach((step) => {
      delete step.realTimeInterdentalDistances;
    });

    clonedMovementsFromEvergine.upperDentalMovements?.steps.forEach((step) => {
      delete step.realTimeInterdentalDistances;
    });

    clonedDentalMovementsSnapshot.lowerDentalMovements?.steps.forEach((step) => {
      delete step.realTimeInterdentalDistances;
    });

    clonedDentalMovementsSnapshot.upperDentalMovements?.steps.forEach((step) => {
      delete step.realTimeInterdentalDistances;
    });

    return !isEqual(clonedMovementsFromEvergine, clonedDentalMovementsSnapshot);
  };

  return {
    updateDentalMovementsSnapshot,
    haveBeenMadeChangesInMovements
  };
}
