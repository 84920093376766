import { CaseType } from '../../models';
import { Clinic } from '../clinic';
import { FileCaptureType, PhotoCaptureType } from '../file';
import { PatientCase, PatientCaseDTOData, YesNoMeasureUpEnum } from './case';

// TODO: Endpoint not returning this information
/*
function getOtherPhotographs(patientCaseDTO: PatientCaseDTO) {
  const otherPhotographs = [];
  for (let i = 0; i < 10; i++) {
    const photo = {
      key: `${PhotoCaptureType.PHOTOGRAPHS_OTHERS}-${i}`,
      name: patientCaseDTO.files[`${PhotoCaptureType.PHOTOGRAPHS_OTHERS}-${i}`]?.originalName,
      url: patientCaseDTO.files[`${PhotoCaptureType.PHOTOGRAPHS_OTHERS}-${i}`]?.url
    };

    otherPhotographs.push(photo);
  }

  return otherPhotographs;
}*/
export class CaseBuilder {
  private _case: PatientCase = {
    id: undefined,
    externalId: undefined,
    anamnesis: undefined,
    clinic: undefined,
    comments: undefined,
    patient: undefined,
    captures3D: undefined,
    photographs: undefined,
    scannings: undefined,
    meta: undefined,
    type: undefined,
    specificInfo: undefined
  };

  public withGeneralData(patientCaseDTO: PatientCaseDTOData, clinic: Clinic, type?: CaseType) {
    this._case.id = patientCaseDTO?.id;
    this._case.externalId = patientCaseDTO?.externalId;
    const patient = patientCaseDTO?.generalInfo?.patient;
    this._case.patient = {
      name: patient?.name,
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      dateOfBirth: patient?.birthdate,
      gender: patient?.gender,
      //pieces: getFieldValue(patientCaseDTO, 'patient-pieces') as PieceData[], // TODO: Is for surgery case, endpoint not returning this information
      piecesNotToMove: patient?.teethNotToMove,
      archToTreat: patient?.archTreat
    };
    this._case.anamnesis = [];
    this._case.comments = patientCaseDTO?.generalInfo.comments;
    this._case.meta = patientCaseDTO?.meta?.length > 0 ? patientCaseDTO?.meta[0] : '';
    this._case.clinic = {
      address: clinic?.address,
      name: clinic?.name
    };
    this._case.type = type;
    this._case.designOption = patientCaseDTO?.designOption;
    this._case.manufacturingOption = patientCaseDTO?.manufacturingOption;

    return this;
  }

  public withSpecificInfo(patientCaseDTO: PatientCaseDTOData) {
    const specificInfo = patientCaseDTO?.specificInfo;
    this._case.specificInfo = {
      treatmentPrecut: specificInfo?.details.cutType as string,
      secretRetainers: specificInfo?.details.secretRetainers,
      passiveAligners: (specificInfo?.details.passiveAligners === true ? 'yes' : 'no') as YesNoMeasureUpEnum,
      useElastics: (specificInfo?.details.useElastics === true ? 'yes' : 'no') as YesNoMeasureUpEnum,
      distalize: (specificInfo?.details.distalize === true ? 'yes' : 'no') as YesNoMeasureUpEnum,
      extractThirdMolar: (specificInfo?.details.thirdMolarExtraction === true ? 'yes' : 'no') as YesNoMeasureUpEnum,
      treatmentTarget: specificInfo?.treatmentTarget.target,
      indications: specificInfo?.indications,
      patientProblems: specificInfo?.treatmentTarget?.patientProblems?.map((p) => p.problem)
    };

    return this;
  }

  public withCapturesData(patientCaseDTO: PatientCaseDTOData) {
    this._case.captures3D = {
      upperDICOM: {
        key: FileCaptureType.UPPER_DICOM,
        name: '',
        url: ''
      },
      lowerDICOM: {
        key: FileCaptureType.LOWER_DICOM,
        name: '',
        url: ''
      }
    };

    this._case.scannings = {
      guide: {
        key: FileCaptureType.GUIDE_MODEL3D,
        name: '',
        url: ''
      },
      upperModel3D: {
        key: FileCaptureType.UPPER_MODEL3D,
        name: '',
        url: ''
      },
      lowerModel3D: {
        key: FileCaptureType.LOWER_MODEL3D,
        name: '',
        url: ''
      }
    };
    // TODO: Endpoint not returning this information
    /*
      this._case.captures3D = {
      upperDICOM: {
        key: FileCaptureType.UPPER_DICOM,
        name: patientCaseDTO.files[FileCaptureType.UPPER_DICOM]?.fileOriginalName,
        url: patientCaseDTO.files[FileCaptureType.UPPER_DICOM]?.url
      },
      lowerDICOM: {
        key: FileCaptureType.LOWER_DICOM,
        name: patientCaseDTO.files[FileCaptureType.LOWER_DICOM]?.fileOriginalName,
        url: patientCaseDTO.files[FileCaptureType.LOWER_DICOM]?.url
      }
    };

    this._case.scannings = {
      upperModel3D: {
        key: FileCaptureType.UPPER_MODEL3D,
        name: patientCaseDTO.files[FileCaptureType.UPPER_MODEL3D]?.fileOriginalName,
        url: patientCaseDTO.files[FileCaptureType.UPPER_MODEL3D]?.url
      },
      lowerModel3D: {
        key: FileCaptureType.LOWER_MODEL3D,
        name: patientCaseDTO.files[FileCaptureType.LOWER_MODEL3D]?.fileOriginalName,
        url: patientCaseDTO.files[FileCaptureType.LOWER_MODEL3D]?.url
      }
    };
    */

    return this;
  }

  public withPhotographsData(patientCaseDTO: PatientCaseDTOData) {
    const frontSmile = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE);
    const leftOcclusion = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT);
    const rightOcclusion = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT);
    const upperOcclusion = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER);
    const lowerOcclusion = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER);
    const orthopantomography = this.getFileValueByIdentifier(
      patientCaseDTO,
      PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY
    );
    const teleradiography = this.getFileValueByIdentifier(patientCaseDTO, PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY);

    this._case.photographs = {
      frontSmile: {
        key: PhotoCaptureType.PHOTOGRAPHS_FRONT_SMILE,
        name: frontSmile?.fileOriginalName,
        url: frontSmile?.url
      },
      leftOcclusion: {
        key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LEFT,
        name: leftOcclusion?.fileOriginalName,
        url: leftOcclusion?.url
      },
      rightOcclusion: {
        key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_RIGHT,
        name: rightOcclusion?.fileOriginalName,
        url: rightOcclusion?.url
      },
      upperOcclusion: {
        key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_UPPER,
        name: upperOcclusion?.fileOriginalName,
        url: upperOcclusion?.url
      },
      lowerOcclusion: {
        key: PhotoCaptureType.PHOTOGRAPHS_OCLUSSION_LOWER,
        name: lowerOcclusion?.fileOriginalName,
        url: lowerOcclusion?.url
      },
      orthopantomography: {
        key: PhotoCaptureType.RADIOGRAPHY_ORTHOPANTOMOGRAPHY,
        name: orthopantomography?.fileOriginalName,
        url: orthopantomography?.url
      },
      teleradiography: {
        key: PhotoCaptureType.RADIOGRAPHY_TELERADIOGRAPHY,
        name: teleradiography?.fileOriginalName,
        url: teleradiography?.url
      }
      //others: getOtherPhotographs(patientCaseDTO)
    };

    return this;
  }

  private getFileValueByIdentifier(patientCaseDTO: PatientCaseDTOData, identifier: string) {
    return patientCaseDTO?.files?.find((file: any) => file.identifier === identifier);
  }

  public build() {
    return this._case;
  }
}
