import { useTranslation } from 'react-i18next';
import { container } from '../../common/ioc/ioc.config';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { ClientPlanValidationEnum } from '../../shared';
import { ChatMessage } from '../../shared/chat';
import { IChatService } from '../../shared/chat/ichat.service';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { useUserProfileData } from '../shared';
import { isSaasApiEnabled } from '../../shared/settings';

export function useChat(caseId: string) {
  const [userProfile] = useUserProfileData();
  const [t] = useTranslation();
  const { messages, setMessages } = useBoundStore();

  const getMessages = async () => {
    const chatService = container.get<IChatService>(INJECTED_TYPES.IChatService);
    const fetchedChatMessageList: ChatMessage[] = await chatService.getByCaseId(caseId);
    return fetchedChatMessageList;
  };

  const addMessage = async (chatMessage: ChatMessage) => {
    const chatService = container.get<IChatService>(INJECTED_TYPES.IChatService);
    const result: boolean = await chatService.addMessage(caseId, chatMessage);
    return result;
  };

  const markMessageAsRead = async (messageId: string) => {
    const chatService = container.get<IChatService>(INJECTED_TYPES.IChatService);
    await chatService.markMessageAsRead(caseId, messageId);
  };

  const sendChatMessage = (type: ClientPlanValidationEnum, text?: string, hasText?: boolean) => {
    switch (type) {
      case ClientPlanValidationEnum.Approved:
        text = t('modal.clientValidation.validate.accepted');
        break;
      case ClientPlanValidationEnum.Rejected:
        text = t('modal.clientValidation.reject.rejected');
        break;
      case ClientPlanValidationEnum.RequestedChanges:
        text = `${t('modal.clientValidation.edit.title')} - ${
          hasText ? text : t('modal.clientValidation.edit.noObservations')
        }`;
        break;
    }

    const message = {
      senderName: `${userProfile?.firstName} ${userProfile?.lastNames}`,
      userId: userProfile?.id,
      author: t('chat.me'),
      body: text,
      timestamp: new Date(),
      isMe: true,
      caseId: caseId
    } as ChatMessage;

    const treatmentPublicToken = sessionStorage.getItem('publicAccessToken');
    if (isSaasApiEnabled() && treatmentPublicToken) {
      message.externalUserId = userProfile.stringId;
      message.externalUserName = message.senderName;
    }

    addMessage(message);
    setMessages([...messages, message]);
  };

  return { getMessages, addMessage, markMessageAsRead, sendChatMessage } as const;
}
